import { useRef, useEffect } from 'react'
import { Typography } from '@mui/material'

import ProgressBar from '../../../../../../components/ProgressBar'
import { formatNumber } from '../../../../../../utils/utils'
import { counter } from '../../../../../../utils/animations'

const RewardProgressItem = ({ current, final, isPig, disabled }) => {
  const formattedFinal = formatNumber(final)

  const valueRef = useRef(null)

  useEffect(() => {
    if (valueRef.current) {
      const value = +valueRef.current.textContent.replace(/,/g, '')
      counter(valueRef.current, value, current)
    }
  }, [current, valueRef])

  return (
    <div className="gameDetailsSpecialOfferRewardProgressItem">
      <div className="gameDetailsSpecialOfferRewardProgressItemContent">
        <ProgressBar completed={(current * 100) / final} fillerType={isPig ? 'pink' : 'gold'} />
        <Typography variant="body2" sx={styles.text(disabled)}>
          <strong ref={valueRef}>0</strong> out of <strong>{formattedFinal}</strong> earned
        </Typography>
      </div>
      <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} />
    </div>
  )
}

const styles = {
  text: (disabled) => ({
    fontFamily: 'var(--poppins-font)',
    color: disabled ? '#94A3B8' : '#0F172A',
  }),
}

export default RewardProgressItem
