import React, { useEffect, useState } from 'react'

import './FinishChest.css'

const FinishChest = ({ isVisible }) => {
  const [isPigVisible, setPigVisible] = useState(false)
  const [isShineVisible, setShineVisible] = useState(false)
  const [height, setHeight] = useState('unset')

  useEffect(() => {
    if (isVisible) {
      setPigVisible(true)

      setHeight(document.getElementsByClassName('gamesListPiggyBankChestProgress')[0].offsetHeight)

      setTimeout(() => {
        setShineVisible(true)
      }, 500)
    }
  }, [isVisible])

  return (
    <div
      className={`gamesListPiggyBankChestFinishDivWrap ${isVisible ? 'visible' : ''}`}
      style={{ height }}
    >
      <div className="gamesListPiggyBankChestFinishDiv">
        <div className={`gamesListPiggyBankChestShine ${isShineVisible ? 'visible' : ''}`}>
          <img src="/images/pigChestShine.png" />
        </div>
        <div className="gamesListPiggyBankChestFinishDivChest">
          <img src="/images/pigChestOpenback.png" className="gamesListPiggyBankChestOpenback" />
          <img
            src="/images/pigChestPig.png"
            className={`gamesListPiggyBankChestPig ${isPigVisible ? 'visible' : ''}`}
          />
          <img src="/images/pigChestOpenBottom.png" className="gamesListPiggyBankChestOpenBottom" />
        </div>
      </div>
    </div>
  )
}

export default FinishChest
