import { createTheme } from '@mui/material'

export const MAIN_THEME = createTheme({
  typography: {
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 600,
      img: {
        height: '40px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '24px',
        lineHeight: '32px',
        img: {
          height: '32px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '20px',
        lineHeight: '26px',
        img: {
          height: '26px',
        },
      },
    },
    title: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: 600,
      img: {
        height: '48px',
        display: 'inline-block',
        verticalAlign: 'baseline',
      },
      '@media (max-width: 640px)': {
        fontSize: '32px',
        lineHeight: '40px',
        img: {
          height: '40px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '24px',
        lineHeight: '32px',
        img: {
          height: '32px',
        },
      },
    },
    h2: {
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 600,
      a: {
        fontSize: '20px',
        lineHeight: '26px',
      },
      '@media (max-width: 640px)': {
        fontSize: '16px',
        lineHeight: '20px',
        a: {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '14px',
        lineHeight: '18px',
        a: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
    },
    h3: {
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 600,
      img: {
        height: '24px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      svg: {
        height: '24px',
        width: '24px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '16px',
        lineHeight: '20px',
        img: {
          height: '16px',
        },
        svg: {
          height: '20px',
          width: '20px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
          height: '14px',
        },
        svg: {
          height: '18px',
          width: '18px',
        },
      },
    },
    h4: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
      svg: {
        height: '22px',
        width: '22px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      a: {
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 400,
      },
      li: {
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 600,
      },
      img: {
        height: '22px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
          height: '18px',
        },
        svg: {
          height: '18px',
          width: '18px',
        },
        a: {
          fontSize: '14px',
          lineHeight: '18px',
        },
        li: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '12px',
        lineHeight: '16px',
        img: {
          height: '16px',
        },
        svg: {
          height: '16px',
          width: '16px',
        },
        a: {
          fontSize: '12px',
          lineHeight: '16px',
        },
        li: {
          fontSize: '12px',
          lineHeight: '16px',
        },
      },
    },
    inherit: {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 600,
      color: '#27272A',
      img: {
        height: '30px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '20px',
        lineHeight: '28px',
        img: {
          height: '28px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '16px',
        lineHeight: '20px',
        img: {
          height: '20px',
        },
      },
    },
    body1: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
      img: {
        height: '32px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '16px',
        lineHeight: '20px',
        img: {
          height: '20px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
          height: '18px',
        },
      },
      strong: {
        fontWeight: 700,
      },
    },
    body2: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 400,
      '&.MuiTypography-body2 img': {
        height: '20px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      svg: {
        height: '20px',
        width: '20px',
        display: 'inline-block',
        verticalAlign: 'sub',
      },
      '@media (max-width: 640px)': {
        fontSize: '12px',
        lineHeight: '16px',
        '&.MuiTypography-body2 img': {
          height: '16px',
        },
        svg: {
          height: '16px',
          width: '16px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '10px',
        lineHeight: '14px',
        '&.MuiTypography-body2 img': {
          height: '14px',
        },
        svg: {
          height: '14px',
          width: '14px',
        },
      },
      strong: {
        fontWeight: 700,
      },
      a: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        '@media (max-width: 640px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '@media (max-width: 380px)': {
          fontSize: '10px',
          lineHeight: '14px',
        },
      },
    },
    small: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '12px',
      img: {
        height: '12px',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      svg: {
        height: '11px',
        width: '11px',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      '@media (max-width: 640px)': {
        fontSize: '10px',
        lineHeight: '10px',
        img: {
          height: '9px',
        },
        svg: {
          height: '9px',
          width: '9px',
        },
      },
      '@media (max-width: 380px)': {
        fontSize: '8px',
        lineHeight: '8px',
        img: {
          height: '7px',
        },
        svg: {
          height: '7px',
          width: '7px',
        },
      },
    },
    allVariants: {
      fontFamily: `"Poppins", sans-serif`,
      letterSpacing: 0,
      color: '#27272A',
    },
    button: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
      color: '#292D38',
      '@media (max-width: 640px)': {
        fontSize: '14px',
        lineHeight: '21px',
      },
      '@media (max-width: 380px)': {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
})

export const REFERRAL_LANDING_THEME = createTheme({
  typography: {
    title: {
      fontSize: '48px',
      lineHeight: '56px',
      fontWeight: 400,
      color: '#000000',
      strong: {
        fontWeight: 700,
      },
      '@media (max-width: 640px)': {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
    body1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
      '@media (max-width: 640px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    body2: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      '@media (max-width: 640px)': {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    h3: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 400,
      '@media (max-width: 640px)': {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    button: {
      fontSize: '16px',
      lineHeight: '34px',
      fontWeight: 700,
    },
    footer: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 700,
      color: '#fff',
      fontFamily: 'var(--open-sans-font)',
    },
    allVariants: {
      fontFamily: `"Poppins", sans-serif`,
      letterSpacing: 0,
      color: '#000000',
    },
  },
})
