import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LOCAL_STORAGE } from '../constants'
import HistoryContext from '../context/HistoryContext'
import useLocalStorage from '../hooks/useLocalStorage'

const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([])
  const [previousPage, setPreviousPage] = useState('')
  const [currentPage, setCurrentPage] = useState('')

  const { setItem } = useLocalStorage()

  const location = useLocation()

  const push = (location) => setHistory([...history, location])

  useLayoutEffect(() => {
    if (document.referrer) {
      setItem(LOCAL_STORAGE.TUTORIAL_BEGIN, true)
    }

    const startHistory = history

    startHistory.push(location.pathname)

    window.scroll({ top: 0, left: 0 })
    setHistory(startHistory)

    setPreviousPage(startHistory[startHistory.length - 2])
    setCurrentPage(startHistory[startHistory.length - 1])
  }, [location])

  return (
    <HistoryContext.Provider value={{ history, push, previousPage, currentPage }}>
      {children}
    </HistoryContext.Provider>
  )
}

export default HistoryProvider
