import React, { useContext, useEffect } from 'react'

import ErrorLayout from '../components/layout/Error'
import LoaderContext from '../context/LoaderContext'

const GlobalError = () => {
  const { hideLoader } = useContext(LoaderContext)

  useEffect(hideLoader, [])

  return (
    <ErrorLayout
      subtitle="Oops! Something went wrong."
      text="An unexpected error has occurred. Please try reloading the page. If the issue persists, you can return to the home page."
    />
  )
}

export default GlobalError
