import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material'

import BottomButton from '../../common/BottomButton'

import './index.css'

const ModalButtons = ({
  hideModal,
  button: { handleClick, text },
  closeButtonText = 'Nevermind',
}) => {
  return (
    <div className="modalButtons">
      <BottomButton handleClick={hideModal}>{closeButtonText}</BottomButton>
      <Button sx={styles.button} onClick={handleClick}>
        <Typography variant="h4" sx={styles.buttonText}>
          {text}
        </Typography>
      </Button>
    </div>
  )
}

const styles = {
  button: {
    padding: '12px 24px',
    width: '100%',
    margin: '0 auto',
    background: 'none',
    textAlign: 'center',
    color: '#71717a',
    textDecoration: 'underline',
    textDecorationColor: '#DC3545',
    textUnderlinePosition: 'under',
    textTransform: 'none',
    borderRadius: '100px',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#DC3545',
    },
  },
  buttonText: {
    fontWeight: 400,
    color: '#DC3545',
    fontFamily: 'var(--inter-font)',
  },
}

ModalButtons.propTypes = {
  hideModal: PropTypes.func.isRequired,
  button: PropTypes.shape({
    handleClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
}

export default ModalButtons
