import axiosWebInstance from 'axiosWebInstance'

//types
import { TwentyFourMetricsRequest } from 'types'

export const twentyFourMetricsImpression = async (data: TwentyFourMetricsRequest) => {
  return axiosWebInstance.post('/api/v1/twenty-four-metrics/impression', data)
}
export const twentyFourMetricsClick = async (data: TwentyFourMetricsRequest) => {
  return axiosWebInstance.post('/api/v1/twenty-four-metrics/click', data)
}
