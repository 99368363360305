import { Typography } from '@mui/material'
import { useState } from 'react'

import { QUESTS_LIST_ITEM_CONTENT, QUEST_TYPE } from '../../../../constants'
import useTimer from '../../../../hooks/useTimer'
import Item from './Item'

import './index.css'

const QuestsListItems = ({ type, quests }) => {
  const [hidden, setHidden] = useState(false)

  const { title } = QUESTS_LIST_ITEM_CONTENT[type]
  const refreshTimestamp = type === QUEST_TYPE.WEEKLY ? quests[0]?.expiredAt : null

  const timer = useTimer(refreshTimestamp, ['userQuests'], true)

  if (!quests || !quests.length) {
    return null
  }

  return (
    <div className={`questsListsItems ${hidden ? 'alertSwipe hidden' : ''}`}>
      <div className="questsListsItemsHeader">
        <Typography variant="h3">{title}</Typography>
        {refreshTimestamp && (
          <Typography variant="small" sx={styles.timer}>
            Quests Refresh: {timer}
          </Typography>
        )}
      </div>
      {quests?.map((quest) => (
        <Item
          key={quest.id}
          quest={quest}
          type={type}
          onClaim={quests.length === 1 ? () => setHidden(true) : null}
        />
      ))}
    </div>
  )
}

const styles = {
  timer: {
    color: '#636874',
    fontWeight: 500,
  },
}

export default QuestsListItems
