import React from 'react'
import { Button } from '@mui/material'

import './index.css'

const BottomButton = ({
  className = '',
  handleClick,
  children,
  style = {},
  disabled = false,
  type = '',
}) => {
  return (
    <Button
      className={className}
      variant="contained"
      sx={{ ...styles.button(disabled), ...style }}
      onClick={handleClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </Button>
  )
}

const styles = {
  button: (disabled) => ({
    width: '100%',
    padding: '16px',
    borderRadius: '72px',
    textTransform: 'none',
    color: '#292D38',
    boxShadow: 'none',
    border: '1px solid transparent',
    ...(disabled
      ? {
          background: '#D5D5D5',
          '&:hover': { background: '#D5D5D5' },
        }
      : {
          background:
            'linear-gradient(168.92deg, #FFE079 6.85%, #FFAE16 90.71%) padding-box, linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) border-box',
          boxShadow: 'none',
          '&:hover': {
            background:
              'linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) padding-box, linear-gradient(93.2deg, #FFDA6D 16.28%, #FFCB68 92.47%) border-box',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
  }),
}

export default BottomButton
