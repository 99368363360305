import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

import BottomButton from '../common/BottomButton'

import './index.css'

const Modal = ({
  showModal,
  hideModal,
  actionButtons = <></>,
  closeButton = false,
  imageUrl = '',
  img = '',
  title = '',
  titleVariant = 'h1',
  text = '' || {},
  isCustomText = false,
  textVariant = 'body2',
  textStyles = {},
}) => {
  return (
    <Dialog open={showModal} onClose={hideModal} sx={styles.modal} maxWidth="xs">
      {closeButton && (
        <div className="modalCloseButton">
          <img src="/images/modalCloseButton.png" onClick={hideModal} />
        </div>
      )}
      {imageUrl && <img className="modalImage" src={imageUrl} />}
      {img}
      {title && (
        <DialogTitle variant={titleVariant || 'h1'} sx={styles.title}>
          {title}
        </DialogTitle>
      )}
      {text &&
        (isCustomText ? (
          text
        ) : (
          <DialogContent>
            <Typography variant={textVariant || 'body2'} sx={{ ...styles.text, ...textStyles }}>
              {text}
            </Typography>
          </DialogContent>
        ))}
      <DialogActions sx={styles.actions}>
        {actionButtons ?? (
          <BottomButton handleClick={hideModal} style={styles.closeButton}>
            Close
          </BottomButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  cross: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  text: {
    textAlign: 'left',
    strong: {
      fontWeight: 600,
    },
  },
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    backgroundColor: '#ffffff',
    color: '#000000',
    maxWidth: '50%',
  },
  modal: { '& .MuiDialog-paper': { borderRadius: '16px', margin: '21px' } },
  actions: {
    padding: '0 20px 20px',
  },
  title: {
    textAlign: 'center',
  },
}

export default Modal
