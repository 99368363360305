import React, { useContext } from 'react'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'

//components
import PriceText from 'components/giftCards/PriceText'
import DiscountTag from '../DiscountTag'
//constants
import { CURRENCY_SYMBOL, PIGGY_BANKS_MULTIPLIER, POINTS_MULTIPLIER } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//types
import { AuthContextType, GiftCardProps } from 'types'
//utils
import { formatNumber } from 'utils/utils'

import './index.css'

const GiftCard = ({ giftCard, denomination, discount }: GiftCardProps) => {
  const { user } = useContext(AuthContext) as AuthContextType

  const pointsValue = POINTS_MULTIPLIER(denomination)
  const piggyBanksValue = PIGGY_BANKS_MULTIPLIER(denomination)

  let discountedPointsValue, discountedPiggyBanksValue
  if (discount) {
    discountedPointsValue = pointsValue * (1 - discount / 100)
    discountedPiggyBanksValue = piggyBanksValue * (1 - discount / 100)
  }

  return (
    <div className="giftCardListCardInner">
      <Card className="giftCardListCard" sx={styles.card} data-denomination={denomination}>
        <Box sx={styles.cardContainer}>
          <CardMedia component="img" sx={styles.cardMedia} src={giftCard.image} />
          {discount && (
            <Box sx={styles.discountOverlay}>
              <DiscountTag discount={discount} />
            </Box>
          )}
        </Box>
        <CardContent sx={styles.cardContent}>
          <Typography variant="h3" sx={styles.currency}>
            {CURRENCY_SYMBOL[giftCard?.currency]}
            {denomination} {giftCard?.currency}
          </Typography>
          <div className="giftCardListCardCurrency">
            <PriceText
              userBalance={user?.piggyBanks}
              giftCardValue={piggyBanksValue}
              value={formatNumber(piggyBanksValue)}
              {...(discount && { discountedGiftCardValue: discountedPiggyBanksValue })}
              {...(discount && { discountedValue: formatNumber(discountedPiggyBanksValue) })}
              isPig={true}
            />
            <PriceText
              userBalance={user?.points}
              giftCardValue={pointsValue}
              value={formatNumber(pointsValue)}
              {...(discount && { discountedGiftCardValue: discountedPointsValue })}
              {...(discount && { discountedValue: formatNumber(discountedPointsValue) })}
              isPig={false}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

const styles = {
  card: {
    width: '216px',
    borderRadius: '12px',
    transition: 'none',
    boxShadow: '0px 12px 16px -4px #0014311F',
    cursor: 'pointer',
  },
  cardContainer: {
    position: 'relative',
  },
  discountOverlay: {
    position: 'absolute',
    bottom: 5,
    left: 3,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'start',
  },
  cardMedia: {
    height: '136px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    backgroundColor: '#fff',
    '&:last-child': {
      paddingBottom: '8px',
    },
    height: '80px',
  },
  currency: {
    color: '#18181B',
  },
}

export default GiftCard
