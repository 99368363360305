import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@mui/material'
import { SelectArrow } from '../../utils/icons'

const Select = ({ currentItem, options, onChange, optionKey }) => {
  const [showMenu, setShowMenu] = useState(false)
  const searchRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    if (showMenu && searchRef.current) {
      searchRef.current.focus()
    }
  }, [showMenu])

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false)
      }
    }

    window.addEventListener('click', handler)
    return () => {
      window.removeEventListener('click', handler)
    }
  })

  const getOption = (option) => (optionKey ? option[optionKey] : option)

  return (
    <TextField
      value={currentItem}
      select
      sx={styles.textField}
      onChange={onChange}
      SelectProps={{ IconComponent: SelectArrow }}
    >
      {options.map((option) => (
        <MenuItem key={getOption(option)} value={getOption(option)}>
          {getOption(option)}
        </MenuItem>
      ))}
    </TextField>
  )
}

const styles = {
  textField: {
    textAlign: 'left',
    width: '100%',
    '& .MuiSelect-select.MuiOutlinedInput-input': {
      paddingRight: '10px',
    },
  },
}

Select.propTypes = {
  currentItem: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  itemsToShow: PropTypes.number,
}

export default Select
