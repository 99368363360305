import { Box, Card, CardContent, Slide, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_REWARDS_SLIDE_TIMEOUT } from '../../../../constants'
import OfferCard from '../../../../components/OfferCard'
import { formatNumber } from '../../../../utils/utils'

import './index.css'

const GameCard = ({ game }) => {
  const navigate = useNavigate()

  const formattedPiggies = formatNumber(game.gameHistory.earnedPiggy)
  const formattedCoins = formatNumber(game.gameHistory.earnedCoins)

  const handleClick = () => {
    const offerId = game.specialOffer?.id

    const offerParams = offerId ? `?offerId=${offerId}` : ''

    navigate(`/games/${game.unifiedAppId}${offerParams}`)
  }

  const content = (
    <div>
      <Card sx={styles.card} onClick={handleClick}>
        <CardContent sx={styles.cardContent}>
          <div className="favoritesGameCardImageWrap">
            <img className="favoritesGameCardImage" src={game.gameDetails.icon_url} />
          </div>
          <Box sx={styles.contentText}>
            <Typography variant="h2">{game.gameDetails.name}</Typography>
            <Box sx={styles.contentEarned}>
              <Typography variant="h4">Earned:</Typography>
              <div>
                <Typography variant="h4" className="priceText pig">
                  <strong>{formattedPiggies}</strong> <img src="/images/pig.png" />
                </Typography>
              </div>
              <div>
                <Typography variant="h4" className="priceText coin">
                  <strong>{formattedCoins}</strong> <img src="/images/coin.png" />
                </Typography>
              </div>
            </Box>
          </Box>
        </CardContent>

        {game.specialOffer && (
          <OfferCard
            game={game}
            isLibrary={true}
            specialOffer={game.specialOffer}
            activatedOffer={game.activatedOffer}
          />
        )}
      </Card>
    </div>
  )

  if (game.isSwiping) {
    return (
      <Slide
        direction="right"
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={DEFAULT_REWARDS_SLIDE_TIMEOUT}
      >
        {content}
      </Slide>
    )
  }

  return content
}

const styles = {
  card: {
    borderRadius: '12px',
    boxShadow: '0px 4px 4px 0px #00000040',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    gap: '12px',
    boxShadow: 'none',
    padding: '12px 16px',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  contentEarned: {
    display: 'flex',
    gap: '8px',
  },
}

export default GameCard
