import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import { BUTTON_TYPE, PLATFORMS_ARRAY } from '../../../constants'
import Select from '../../../components/select'
import { updateUser } from '../../../services/userService'
import AuthContext from '../../../context/AuthContext'
import BottomButton from '../../../components/common/BottomButton'
import { handleError } from '../../../utils/utils'

const SelectModal = ({ showLoader, hideLoader, showModal, hideModal, currentItem }) => {
  const { updateUserData } = useContext(AuthContext)

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { platform: currentItem },
    mode: 'onChange',
  })

  useEffect(() => {
    reset({ platform: currentItem })
  }, [currentItem])

  const queryClient = useQueryClient()

  const onSubmit = (data) => {
    showLoader()
    hideModal()
    updateUser(data)
      .then(updateUserData)
      .catch(handleError)
      .finally(() => {
        hideLoader()
        queryClient.invalidateQueries({ queryKey: ['offers'], exact: true })
      })
  }

  return (
    <Dialog open={showModal} onClose={hideModal} sx={styles.modal} maxWidth="xs">
      <DialogTitle variant="h1" sx={styles.title}>
        Select App Store
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            control={control}
            name="platform"
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={PLATFORMS_ARRAY}
                currentItem={value}
                onChange={onChange}
                itemsToShow={5}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <BottomButton handleClick={hideModal} style={styles.closeButton}>
            Close
          </BottomButton>
          <BottomButton type={BUTTON_TYPE.SUBMIT}>Save</BottomButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const styles = {
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    background: '#ffffff',
    color: '#000000',
    '&:hover': {
      background: '#f0f0f0',
      boxShadow: 'none',
    },
  },
  modal: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      margin: '21px',
      overflowY: 'inherit',
      width: '100%',
      maxWidth: '400px',
    },
  },
  title: {
    textAlign: 'center',
    paddingBottom: 0,
  },
  actions: {
    padding: '0 20px 20px',
  },
}

SelectModal.propTypes = {
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  currentItem: PropTypes.string.isRequired,
}

export default SelectModal
