import { useMemo } from 'react'
import { useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { captureEvent } from '@sentry/react'

//constants
import { ERRORS, IP_COUNTRY_DETECTION_API, LOCAL_STORAGE } from 'constants/index'
import QueryKeys from 'constants/queryKeys'
//hooks
import useLocalStorage from './useLocalStorage'
//types
import { GeoData } from 'types'

const useQueryGeoData = () => {
  const { getItem } = useLocalStorage()
  const geoDataApi = useMemo(
    () =>
      IP_COUNTRY_DETECTION_API +
      (getItem(LOCAL_STORAGE.DISABLE_GEO_DATA_API) === 'true' ? 'geo-data-fail' : ''),
    []
  )

  const resp = useQuery<AxiosResponse<GeoData, Error>>(
    QueryKeys.GET_GEO_DATA,
    () => axios.get(geoDataApi),
    {
      onError: (error) => {
        captureEvent({
          message: ERRORS.IP_COUNTRY_DETECTION_RQ,
          level: 'error',
          extra: { err: error },
        })
      },
    }
  )
  return {
    geoData: resp.data?.data,
    isLoading: resp.isLoading || resp.isFetching,
    getGeoData: resp.refetch,
  }
}

export default useQueryGeoData
