import React from 'react'
import { Typography } from '@mui/material'

import './index.css'

const OnboardingPointer = ({ step, style }) => {
  return (
    <div className="onboardingPointer" style={style}>
      {step.spotlight.pointer?.text && (
        <Typography variant="h1" sx={styles.text}>
          {step.spotlight.pointer.text}
        </Typography>
      )}
      <img src="/images/arrowDown.png" className="onboardingPointerArrow" />
    </div>
  )
}

const styles = {
  text: {
    color: '#fafafa',
  },
}

export default OnboardingPointer
