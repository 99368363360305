export class EnvAdapter {
  static get REACT_APP_SENTRY_TRACES_SAMPLE_RATE() {
    return parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
  }
  static get REACT_APP_SENTRY_PROFILES_SAMPLE_RATE() {
    return parseFloat(process.env.REACT_APP_SENTRY_PROFILES_SAMPLE_RATE)
  }
  static get REACT_APP_SENTRY_REPLAYS_SAMPLE_RATE() {
    return parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SAMPLE_RATE)
  }
}
