import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BackArrow as ArrowIcon } from '../../../../utils/icons'
import AuthContext from '../../../../context/AuthContext'
import { ONBOARDING_STEP } from '../../../../constants'

import './index.css'

const BackArrow = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { onboardingStep } = useContext(AuthContext)

  const isDisabled = onboardingStep?.name === ONBOARDING_STEP.ACTIVATE_OFFER

  const handleBackArrowClick = () => {
    if (isDisabled) {
      return
    }

    navigate(location.key !== 'default' ? -1 : '/games')
  }

  return (
    <div className={`gameDetailsBackArrowInner ${isDisabled ? 'disabled' : ''}`}>
      <div className="gameDetailsBackArrow" onClick={handleBackArrowClick}>
        <ArrowIcon />
      </div>
    </div>
  )
}

export default BackArrow
