import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material'

import { GoogleIcon } from '../../../utils/icons'

import './index.css'

function GoogleAuthButton({ text = 'Sign up with Google', handleClick, style }) {
  return (
    <Button sx={styles.button} style={style} onClick={handleClick}>
      <div className="googleAuthButtonIconWrap">
        <GoogleIcon customClassName="googleAuthButtonIcon" />
      </div>
      <Typography variant="body1" sx={styles.text}>
        {text}
      </Typography>
    </Button>
  )
}

const styles = {
  button: {
    cursor: 'pointer',
    maxWidth: 'max-content',
    borderRadius: '2px',
    margin: '0 auto',
    padding: '1px 10.5px 1px 1.5px',
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    backgroundColor: '#346EF1',
    '&:hover': {
      backgroundColor: '#346EF1',
      opacity: 0.9,
    },
  },
  text: {
    fontFamily: 'var(--roboto-font)',
    color: 'white',
    textTransform: 'none',
    fontWeight: 700,
  },
}

GoogleAuthButton.propTypes = {
  isLogin: PropTypes.bool,
}

export default GoogleAuthButton
