import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'

export const getOrderById = async (orderId) => {
  return axiosBackendInstance.get(`/api/v1/orders/${orderId}`)
}

export const createOrder = async (data) => {
  return axiosBackendInstance.post('/api/v1/orders', data)
}

export const getAllUserOrders = async (userId) => {
  return axiosWebInstance.get(`/api/v1/orders/user/${userId}`)
}
