import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import useTimer from '../../hooks/useTimer'
import { Clock } from '../../utils/icons'

import './index.css'

function TimerInput({ children, expirationTimestamp }) {
  const navigate = useNavigate()

  const handleExpiring = () => navigate(0)

  const timer = useTimer(expirationTimestamp, [], false, handleExpiring)

  return (
    <div className="timerInput">
      <Clock />
      <Typography variant="h4">{children || timer}</Typography>
    </div>
  )
}

export default TimerInput
