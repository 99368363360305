import React from 'react'

import ErrorLayout from '../components/layout/Error'

const NotAvailable = () => {
  return (
    <ErrorLayout
      title={<img src="/images/notAvailable.png" />}
      subtitle="Website currently not available!"
      text="This may be because of a technical error that we're working to get fixed. Try to login later!"
    />
  )
}

export default NotAvailable
