import './index.css'

const ProgressBar = ({ fillerType, completed, style = {} }) => {
  return (
    <div className="progressBarContainer" style={style}>
      <div className={`progressBarFiller ${fillerType}`} style={{ width: `${completed}%` }} />
    </div>
  )
}

export default ProgressBar
