import { useState } from 'react'

import { ReactNode } from 'react'
import { UserGeoDataContext } from './UserGeoDataContext'
import { UserGeoInfo } from 'types'

interface UserGeoDataProviderProps {
  children: ReactNode
}

export const UserGeoDataProvider = ({ children }: UserGeoDataProviderProps) => {
  const [userGeoInfo, setUserGeoInfo] = useState<UserGeoInfo | null>(null)

  return (
    <UserGeoDataContext.Provider value={{ userGeoInfo, setUserGeoInfo }}>
      {children}
    </UserGeoDataContext.Provider>
  )
}
