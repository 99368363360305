import { Alert, Typography } from '@mui/material'

//components
import BottomButton from 'components/common/BottomButton'
import ValueFrame from 'components/valueFrame'
//utils
import { CheckMark } from 'utils/icons'
//styles
import './index.css'
//types
import { OfferAlertProps } from 'types'

const OfferAlert = ({
  title,
  children,
  handleClick,
  icon,
  buttonText,
  customClassName,
  piggies,
  coinsFly,
  coins,
  completed,
}: OfferAlertProps) => {
  const action = (
    <div className="actionContainer">
      <div className="rewardsAndButtonConatiner">
        {piggies > 0 && (
          <ValueFrame value={piggies} isPig={true} isModal={false} coinsFly={coinsFly} />
        )}
        {coins > 0 && (
          <ValueFrame value={coins} isPig={false} isModal={false} coinsFly={coinsFly} />
        )}
        {buttonText && (
          <BottomButton className="actionButton" handleClick={handleClick}>
            {buttonText}
          </BottomButton>
        )}
      </div>
    </div>
  )

  return (
    <Alert className={`offerAlert ${customClassName}`} severity="info" icon={icon} action={action}>
      <div className="offerAlertContent">
        <Typography variant="h4" className="title">
          {title}
        </Typography>

        {children && (
          <Typography variant="body2" className="children">
            {children}
          </Typography>
        )}
        {completed && (
          <Typography variant="body2" className="complete">
            <CheckMark />
            Completed
          </Typography>
        )}
      </div>
    </Alert>
  )
}

export default OfferAlert
