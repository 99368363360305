import React from 'react'
import { Box, Container, Typography } from '@mui/material'

import Header from './Header'
const AuthLayout = ({ children, title = '', content = null }) => {
  return (
    <Container maxWidth="sm" style={styles.container}>
      <Box style={styles.box}>
        <div>
          <Header />
          {title && <Typography variant="h1">{title}</Typography>}
          {content}
        </div>
        {children}
      </Box>
    </Container>
  )
}

const styles = {
  container: {
    textAlign: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100svh',
    height: '100%',
  },
}

export default AuthLayout
