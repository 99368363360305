import { Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import useLocalStorage from '../../../../hooks/useLocalStorage'
import OfferCard from '../../../../components/OfferCard'
import {
  ANALYTICS_EVENT,
  ANALYTICS_FEATURED_CARD_TRACKING_TYPE,
  LOCAL_STORAGE,
  CLICK_CARD_ONBOARDING_STEPS,
  GAME_CARD_OFFER_TYPE,
  USER_PLATFORM,
} from '../../../../constants'
import AuthContext from '../../../../context/AuthContext'
import { getUserPlatform, sendGA4Event } from '../../../../utils/utils'

import './index.css'

const CARD_STYLES = {
  [GAME_CARD_OFFER_TYPE.FEATURED]: {
    height: '175px',
  },
  [GAME_CARD_OFFER_TYPE.ACTIVATED]: {
    height: '224px',
  },
  [GAME_CARD_OFFER_TYPE.DEFAULT]: {
    height: '144px',
    width: '245px',
    '@media (min-width: 640px)': {
      width: '298px',
      height: '175px',
    },
  },
}

const GameCard = ({
  observer,
  offer,
  index,
  isAutoSwiping,
  type = GAME_CARD_OFFER_TYPE.DEFAULT,
  category,
  categoryRowNumber,
}) => {
  const { user, changeStep } = useContext(AuthContext)
  const [offerTileViewGUID] = useState(uuidv4())
  const [isDisabled, setDisabled] = useState(false)

  const navigate = useNavigate()

  const { setItem } = useLocalStorage()

  const cardRef = useRef(null)

  useEffect(() => {
    if (cardRef.current) {
      observer.observe(cardRef.current)
    }
  }, [cardRef])

  const handleNavigate = async () => {
    setDisabled(true)
    sendGA4Event(ANALYTICS_EVENT.SELECT_ITEM.OFFER, {
      item_id: offer.id,
      item_brand: user.platform === 'iOS' ? 'iTunes' : 'Google Play',
      index,
      is_activated: !!offer.activatedOffer,
      offer_campaign_id:
        getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
          ? offer.campaignIdAndroid
          : offer.campaignIdIOS,
    })

    sendGA4Event(ANALYTICS_EVENT.OFFER_TILE_CLICK, {
      offerId: offer.id,
      is_ua_offer: offer.isUAOffer,
      offer_tile_view_guid: offerTileViewGUID,
      offer_campaign_id:
        getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
          ? offer.campaignIdAndroid
          : offer.campaignIdIOS,
    })

    const openMMPLink = sessionStorage.getItem(LOCAL_STORAGE.OPEN_MMP_LINK)

    if (type === GAME_CARD_OFFER_TYPE.FEATURED) {
      const eventType = openMMPLink ? 'tap' : 'view'

      sendGA4Event(ANALYTICS_EVENT.FEATURED_CARD_TRACKING, {
        event_type: eventType,
        featured_card_type: offer.activatedOffer
          ? ANALYTICS_FEATURED_CARD_TRACKING_TYPE.EXISTING_SPENDER_OFFER
          : ANALYTICS_FEATURED_CARD_TRACKING_TYPE.NEW_GAME_OFFER,
        offer_id: offer.id,
        unified_app_id: offer.unifiedAppId,
        placement_number: index + 1,
        offer_campaign_id:
          getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
            ? offer.campaignIdAndroid
            : offer.campaignIdIOS,
      })
    }

    if (CLICK_CARD_ONBOARDING_STEPS.indexOf(user.onboardingStep) > -1) {
      setItem(LOCAL_STORAGE.OFFER_REWARDS_EXPLAINER_GAME, offer?.gameName)
      await changeStep(true, openMMPLink ? 2 : 1)
    }

    navigate(`/games/${offer.unifiedAppId}?offerId=${offer.id}`)

    setDisabled(false)
  }

  const offerData = {
    id: offer.id,
    index,
    activatedOffer: offer.activatedOffer,
    gameName: offer.gameName,
    unifiedAppId: offer.unifiedAppId,
    isUAOffer: offer.isUAOffer,
    offerCampaignId: offer.offerCampaignId,
    isFeatured: type === GAME_CARD_OFFER_TYPE.FEATURED,
    category,
    categoryRowNumber,
    offerTileViewGUID: offerTileViewGUID,
    campaignIdAndroid: offer.campaignIdAndroid,
    campaignIdIOS: offer.campaignIdIOS,
  }

  return (
    <div className={type !== GAME_CARD_OFFER_TYPE.DEFAULT ? 'gamesListFeaturedGameCardInner' : ''}>
      <Card
        ref={cardRef}
        className={`gamesListCard ${isDisabled ? 'disabled' : ''}`}
        sx={styles.card(type)}
        onClick={handleNavigate}
        data-offer={JSON.stringify(offerData)}
      >
        <OfferCard
          game={offer}
          activatedOffer={offer.activatedOffer}
          specialOffer={offer.specialOffer}
          type={type}
          isAutoSwiping={isAutoSwiping}
        />
      </Card>
    </div>
  )
}

const styles = {
  card: (type) => ({
    borderRadius: '12px',
    cursor: 'pointer',
    position: 'relative',
    boxShadow: '0px 12px 16px -4px #0014311F',
    ...CARD_STYLES[type],
  }),
}

export default GameCard
