/* eslint-disable import/order */
import React, { useEffect, useRef, useState } from 'react'
import { CardContent, Typography } from '@mui/material'

//components
import CardFooter from './CardFooter'
import Image from './Image'
import ImageCrop from '../ImageCrop'
import OfferCardTimer from '../timer'
import Rating from '../rating'
import Slider from './Slider'
//constants
import { OFFER_QUALITY, TIMER_EXPIRED, GAME_CARD_OFFER_TYPE } from '../../constants'
//hooks
import useOfferInstallValidation from '../../hooks/useOfferInstallValidation'
import useTimer from '../../hooks/useTimer'
//styles
import './index.css'
//utils
import { checkOfferValidating } from '../../utils/utils'
import {
  CARD_HEIGHT,
  determineActiveTask,
  IMAGE_WIDTH,
  isActivatedOfferIsExpired,
} from '../../utils/offerCard'

const OfferCard = ({
  game,
  isLibrary = false,
  activatedOffer,
  specialOffer,
  type = GAME_CARD_OFFER_TYPE.DEFAULT,
  isAutoSwiping,
}) => {
  const [activeTask, setActiveTask] = useState(null)
  const [isValidating, setValidating] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const expirationTimestamp =
    activatedOffer?.expirationTimestamp || specialOffer?.endTimestamp || game?.endTimestamp

  const quality =
    type === GAME_CARD_OFFER_TYPE.FEATURED
      ? OFFER_QUALITY.FEATURED.name
      : game?.offerQuality || specialOffer?.offerQuality || activatedOffer?.offerQuality

  const cardRef = useRef(null)

  const timer = useTimer(expirationTimestamp, ['featuredGameOffers'], true)

  useOfferInstallValidation(isValidating, activatedOffer, [], () => setValidating(false))

  useEffect(() => {
    if (!activatedOffer) {
      return
    }
    const isValidating = checkOfferValidating(activatedOffer, game)
    setValidating(isValidating)

    if (isValidating || isActivatedOfferIsExpired(activatedOffer)) {
      return
    }

    const activeTask = determineActiveTask(activatedOffer, timer)
    setActiveTask(activeTask)
  }, [activatedOffer, isValidating])

  return (
    <>
      <ImageCrop
        url={specialOffer?.featuredGraphic || game?.featuredGraphic}
        width={IMAGE_WIDTH[type]}
        setImageUrl={setImageUrl}
      />
      <CardContent
        sx={styles.content(isLibrary, type)}
        ref={cardRef}
        className={`offerCardInner ${type}`}
      >
        {!activatedOffer && 'isUAOffer' in game && !game.isUAOffer && (
          <div className="offerCardUAOfferTag">
            <Typography variant="body2" sx={styles.uaOfferText}>
              Existing user offer!
            </Typography>
          </div>
        )}
        {(expirationTimestamp || game.timer) && (
          <OfferCardTimer
            isActive={activatedOffer || game.activatedOffer}
            expired={timer === TIMER_EXPIRED}
            time={timer || game.timer}
            showExpiration={game?.showExpiration}
            customClassName="offerCardTimer myGamesOffer"
          />
        )}
        {type === GAME_CARD_OFFER_TYPE.FEATURED ? (
          <Slider game={game} isAutoSwiping={isAutoSwiping} storageKey={`video-${game.id}`} />
        ) : (
          <Image featuredGraphic={imageUrl} isSmall={type === GAME_CARD_OFFER_TYPE.DEFAULT} />
        )}
        <div className="offerCardContent">
          {isLibrary ? (
            <Typography variant="h2" sx={styles.text}>
              Special Offer
            </Typography>
          ) : (
            <div className="offerCardFeaturedName">
              <Typography variant="h2" sx={styles.text}>
                {game.gameName}
              </Typography>{' '}
              {type === GAME_CARD_OFFER_TYPE.FEATURED && <Rating offer={game} isSmall={true} />}
            </div>
          )}
          <div className="offerCardFooter">
            <CardFooter
              activeTask={activeTask}
              isValidating={isValidating}
              type={type}
              quality={quality}
              game={game}
              activatedOffer={activatedOffer}
            />
          </div>
        </div>
      </CardContent>
    </>
  )
}

const styles = {
  content: (isLibrary, type) => ({
    borderTop: isLibrary ? '1px solid #F59E0B' : 'none',
    height: CARD_HEIGHT[type],
    position: 'relative',
    boxShadow: '0px 4px 4px -4px #00000080 inset',
    padding: '0',
    '&:last-child': {
      paddingBottom: 0,
    },
    ...(type === GAME_CARD_OFFER_TYPE.DEFAULT && {
      '@media (min-width: 640px)': {
        height: '175px',
      },
    }),
  }),
  text: {
    color: '#ffffff',
    margin: 'auto 0',
  },
  uaOfferText: {
    color: '#ffffff',
    fontWeight: 600,
  },
}

export default OfferCard
