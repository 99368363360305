import { useEffect, useState } from 'react'

import { ONBOARDING_POSITION, USER_PLATFORM } from '../../../constants'
import { getUserPlatform } from '../../../utils/utils'
import Tooltip from './Tooltip'
import OnboardingSpotlight from './Spotlight'

import './index.css'

const OnboardingLayout = ({ step }) => {
  const [targetHovered, setTargetHovered] = useState(false)
  const [spotlightStyle, setSpotlightStyle] = useState(null)
  const [pointerStyle, setPointerStyle] = useState({
    bottom: 0,
    right: 0,
    alignItems: 'flex-end',
  })
  const [isStepVisible, setStepVisible] = useState(true)
  const [tooltipStyle, setTooltipStyle] = useState({})

  const handleChangeStyles = (previousCoordinates = null) => {
    const target = document.querySelectorAll(step.spotlight.target)[0]
    const targetCoordinates = target?.getBoundingClientRect()

    if (!target) {
      return setTimeout(handleChangeStyles, 200)
    }

    if (
      !previousCoordinates ||
      previousCoordinates.top !== targetCoordinates.top ||
      previousCoordinates.left !== targetCoordinates.left
    ) {
      return setTimeout(() => handleChangeStyles(targetCoordinates), 200)
    }

    if (step.spotlight.pointer) {
      setPointerStyle({
        top: targetCoordinates.top - 20,
        left: targetCoordinates.left + targetCoordinates.width / 2,
        alignItems: !step.spotlight.pointer.left ? 'flex-end' : 'flex-start',
        transform: `translate(${step.spotlight.pointer.left ? '-50%' : '-92%'}, -100%)`,
      })
    }

    if (step.style) {
      const style = Object.assign({}, step.style)

      const defaultStyleKeys = ['top', 'deviation', 'position']

      defaultStyleKeys.map((key) => delete style[key])

      Object.assign(style, {
        top: step.style.top
          ? step.style.top
          : step.style?.position === ONBOARDING_POSITION.TOP
          ? targetCoordinates.top -
            (step.style?.deviation || 0) -
            (step.spotlight.padding?.top || 0)
          : targetCoordinates.top +
            targetCoordinates.height +
            (step.style?.deviation || 0) +
            (step.spotlight.padding?.top || 0) +
            (step.spotlight.padding?.bottom || 0),
        transform: `translate(-50%, ${
          step.style?.position === ONBOARDING_POSITION.TOP ? '-100' : '0'
        }%)`,
      })

      setTooltipStyle(style)
    }

    if (step.spotlight.focus) {
      if (step.spotlight.focus?.top) {
        window.scrollTo({ top: step.spotlight.focus.top })
      } else {
        target.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
      }
    }

    if (!step.scrollable) {
      setSpotlightStyle({
        height:
          targetCoordinates.height +
          (step.spotlight.padding?.top || 0) +
          (step.spotlight.padding?.bottom || 0),
        width:
          targetCoordinates.width +
          (step.spotlight.padding?.left || 0) +
          (step.spotlight.padding?.right || 0),
        top: targetCoordinates.top - (step.spotlight.padding?.top || 0),
        left: step.spotlight.center
          ? window.innerWidth / 2 -
            (targetCoordinates.width +
              (step.spotlight.padding?.left || 0) +
              (step.spotlight.padding?.right || 0)) /
              2
          : targetCoordinates.left - (step.spotlight.padding?.left || 0),
      })

      return setStepVisible(true)
    }

    const headerCoordinates = document
      .getElementsByClassName('gamesHeader')[0]
      .firstChild?.getBoundingClientRect()
    const footerCoordinates = document
      .getElementsByClassName('gamesFooter')[0]
      .firstChild?.getBoundingClientRect()

    setSpotlightStyle({
      top: headerCoordinates.height + 100,
      left: 0,
      width: '100svw',
      height: `calc(100svh - ${headerCoordinates.height + footerCoordinates.height + 110}px)`,
    })

    setStepVisible(true)
  }

  const checkHoverPlatform = () => {
    return getUserPlatform(USER_PLATFORM.DESKTOP) === USER_PLATFORM.DESKTOP
  }

  const hoverHandler = (e) => {
    const spotlightCoordinates = document
      .getElementsByClassName('onboardingSpotlight')[0]
      ?.getBoundingClientRect()

    if (!spotlightCoordinates) {
      return setTimeout(() => hoverHandler(e), 100)
    }

    const coordinates = checkHoverPlatform() ? e : e.changedTouches[0]

    setTargetHovered(
      coordinates.clientY >= spotlightCoordinates.top &&
        coordinates.clientY <= spotlightCoordinates.top + spotlightCoordinates.height &&
        coordinates.clientX >= spotlightCoordinates.left &&
        coordinates.clientX <= spotlightCoordinates.left + spotlightCoordinates.width
    )
  }

  useEffect(() => {
    if (step.spotlight && step.spotlight?.target) {
      setStepVisible(false)
      handleChangeStyles()

      window.addEventListener('scroll', handleChangeStyles)
      window.addEventListener('resize', handleChangeStyles)

      if (step.spotlight?.clickable) {
        document.addEventListener(checkHoverPlatform() ? 'mousemove' : 'touchstart', hoverHandler)
      }

      return () => {
        window.removeEventListener('scroll', handleChangeStyles)
        window.removeEventListener('resize', handleChangeStyles)
        if (step.spotlight?.clickable) {
          document.addEventListener(checkHoverPlatform() ? 'mousemove' : 'touchstart', hoverHandler)
        }
      }
    }

    setTargetHovered(false)
  }, [step])

  if (!isStepVisible) {
    return <div className="onboardingLayout" />
  }

  return (
    <>
      <div className="onboardingLayout" style={{ pointerEvents: targetHovered ? 'none' : 'auto' }}>
        {step.spotlight && <OnboardingSpotlight spotlightStyle={spotlightStyle} />}
      </div>
      {(step.spotlight && spotlightStyle) || !step.spotlight ? (
        <Tooltip
          step={step}
          targetHovered={targetHovered}
          pointerStyle={pointerStyle}
          style={tooltipStyle}
        />
      ) : null}
    </>
  )
}

export default OnboardingLayout
