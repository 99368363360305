import { axiosBackendInstance } from '../axios'

export const generateBaseAuthLink = async (userOpts, signupAttribution = null) => {
  return axiosBackendInstance.post('/auth/v2/generate-link/base', {
    userOpts,
    state: signupAttribution,
  })
}

export const generateBaseUrlForSecondaryEmail = async () => {
  return axiosBackendInstance.get('/auth/v2/generate-link/secondary')
}

export const googleAuth = async (data) => {
  return axiosBackendInstance.post('/auth', data)
}

export const getUserProfileFromToken = async (accessToken) => {
  return axiosBackendInstance.get(`/auth/profile?token=${accessToken}`)
}

export const generateAuthLink = async (redirectUri, scopes, userId = null) => {
  const params = new URLSearchParams({ scopes: scopes.join(',') })
  const userIdParam = userId ? `userId=${userId}` : ''
  const url = `/auth/generate-link?redirectUri=${redirectUri}&${params}&${userIdParam}`

  return axiosBackendInstance.get(url)
}

export const generateGoogleOauth = async (redirectUri, scopes, userId = null) => {
  const params = new URLSearchParams({ scopes: scopes.join(',') })
  const userIdParam = userId ? `userId=${userId}` : ''
  const url = `/auth/generate-google-oauth?redirectUri=${redirectUri}&${params}&${userIdParam}`

  return axiosBackendInstance.get(url)
}

export const generateAuthLinkPostClientRedirect = async (
  redirectUri,
  scopes,
  signupAttribution,
  userId = null
) => {
  const url = `/auth/generate-link-client-redirect`

  return axiosBackendInstance.post(url, {
    redirectUri,
    scopes,
    state: signupAttribution,
    userId,
  })
}

export const generateAuthLinkPost = async (
  redirectUri,
  scopes,
  signupAttribution,
  userId = null
) => {
  const url = `/auth/generate-link`

  return axiosBackendInstance.post(url, {
    redirectUri,
    scopes,
    state: signupAttribution,
    userId,
  })
}

export const getTokens = async (authRequest, provider) => {
  const url = `/auth/token`

  return axiosBackendInstance
    .post(url, {
      authRequest,
      provider,
    })
    .catch((err) => {
      throw err
    })
}

export const getTokensConnectGmail = async (authRequest, provider) => {
  const url = `/auth/two-step-create-account`

  return axiosBackendInstance
    .post(url, {
      authRequest,
      provider,
    })
    .catch((err) => {
      throw err
    })
}

export const handleLogin = async (data) => {
  const url = `/auth/login`

  return axiosBackendInstance.post(url, data).catch((err) => {
    throw err
  })
}

export const handleLogoutBackend = async () => {
  const url = `/auth/logout`

  return axiosBackendInstance.post(url).catch((err) => {
    throw err
  })
}
export const updateRefreshTokenState = async (userId) => {
  return axiosBackendInstance.put(`/auth/refresh-token/${userId}`)
}

export const connectSecondEmail = async () => {
  return axiosBackendInstance.get('/auth/gmail/second-email')
}

export const updateUserCountry = async (body) => {
  return axiosBackendInstance.put('/auth/select-country', body)
}

export const getAuthLinkWithScopes = async (email, signupAttr) => {
  return axiosBackendInstance.get(`/auth/v2/link/gmail?email=${email}&signupAttr=${signupAttr}`)
}

export const updateUserLastLoginData = async (data) => {
  return axiosBackendInstance.patch(`/auth/login-data`, data)
}
