import { Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

import useLocalStorage from '../../../../hooks/useLocalStorage'
import { LOCAL_STORAGE, ONBOARDING_REPLACEMENT_TEXT_NAME } from '../../../../constants'
import BottomButton from '../../../common/BottomButton'
import AuthContext from '../../../../context/AuthContext'
import OnboardingPointer from '../Pointer'

import './index.css'

const TEXT_BRACKETS_EXPRESSION = /[^{}]*(?=\})/g

const OnboardingTooltip = ({ step, targetHovered, pointerStyle, style }) => {
  const [disabled, setDisabled] = useState(false)
  const [content, setContent] = useState('')

  const { changeStep } = useContext(AuthContext)

  const { getItem } = useLocalStorage()

  const handleChangeStep = () => {
    setDisabled(true)
    changeStep(!step.button?.back).finally(() => setDisabled(false))
  }

  const getGameName = () => getItem(LOCAL_STORAGE.OFFER_REWARDS_EXPLAINER_GAME)

  const GET_REPLACEMENT_TEXT = {
    [ONBOARDING_REPLACEMENT_TEXT_NAME.OFFER_REWARDS_EXPLAINER_GAME]: getGameName,
  }

  const nodeToString = (node) => {
    const div = document.createElement('div')
    const root = createRoot(div)
    flushSync(() => root.render(node))
    return div.innerHTML
  }

  useEffect(() => {
    if (step?.content) {
      window.setTimeout(() => {
        let content = typeof step.content !== 'string' ? nodeToString(step.content) : step.content
        const matches = content.match(TEXT_BRACKETS_EXPRESSION)

        if (matches) {
          matches.map((match) => {
            const getReplacementText = GET_REPLACEMENT_TEXT[match]

            if (getReplacementText) {
              content = content.replace(`{${match}}`, getReplacementText() || '')
            }
          })
        }

        setContent(matches ? content : step.content)
      })
    }
  }, [step])

  return (
    <div
      className="onboardingTooltipInner"
      style={{ pointerEvents: targetHovered ? 'none' : 'auto' }}
    >
      <div className="onboardingTooltip" style={style}>
        {step.title}
        <Typography variant="h1" sx={styles.text}>
          {typeof content === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            content
          )}
        </Typography>
        {step.button && (
          <BottomButton handleClick={handleChangeStep} disabled={disabled} style={styles.button}>
            <Typography variant="h1" sx={styles.buttonText}>
              {step.button?.text}
            </Typography>
          </BottomButton>
        )}
      </div>
      {step.spotlight?.pointer && <OnboardingPointer step={step} style={pointerStyle} />}
    </div>
  )
}

const styles = {
  text: {
    color: '#fafafa',
  },
  button: {
    background:
      'linear-gradient(168.92deg, #FB923C 6.85%, #FB923C 90.71%) padding-box, linear-gradient(93.2deg, #FB923C 16.28%, #FB923C 92.47%) border-box',
    '&:hover': {
      background:
        'linear-gradient(168.92deg, #FB923C 6.85%, #FB923C 90.71%) padding-box, linear-gradient(93.2deg, #FB923C 16.28%, #FB923C 92.47%) border-box',
    },
  },
  buttonText: {
    color: '#fff',
  },
}

export default OnboardingTooltip
