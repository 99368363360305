import React from 'react'
import { Typography } from '@mui/material'

//types
import { DiscountTagProps } from 'types'

import './index.css'

const DiscountTag = ({ discount }: DiscountTagProps) => {
  return (
    <div className={`discountTag`}>
      <Typography variant="body2">Save {discount}%</Typography>
    </div>
  )
}

export default DiscountTag
