import React from 'react'
import { Box } from '@mui/system'

import './OpenButton.css'
import { Typography } from '@mui/material'
import { formatNumber } from '../../../../../utils/utils'

const PiggybankChestModalOpenButton = ({ onClick, chestConfig, disabled = false }) => {
  const formattedCoins = formatNumber(chestConfig?.config?.price.coins)
  return (
    <Box
      className={`gamesListPiggyBankChestOpenButton ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
    >
      <Box className="gamesListPiggyBankChestOpenButtonHead">
        <img src="/images/pigChestClosed.png" />
        <Typography variant="h4" sx={styles.text}>
          OPEN ONE!
        </Typography>
      </Box>
      <Box className="gamesListPiggyBankChestOpenButtonFoot">
        <Typography variant="h4" sx={styles.coins}>
          Cost: {formattedCoins}
        </Typography>{' '}
        <img src="/images/coin.png" />
      </Box>
    </Box>
  )
}

const styles = {
  text: {
    fontFeatureSettings: "'clig' off, 'liga' off",
    color: '#fff',
    textTransform: 'capitalize',
    background: 'var(--pink-to-gold-gradient)',
    WebkitBackgroundClip: 'text',
    WebkitTextStroke: '1px transparent',
    fontWeight: 700,
    padding: '0 2px',
    textAlign: 'center',
  },
  coins: {
    fontWeight: 700,
  },
}

export default PiggybankChestModalOpenButton
