import React, { useContext, useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'

import { MAIN_PAGE, QUEST_TYPE } from '../../../../../constants'
import AuthContext from '../../../../../context/AuthContext'
import { createVirtualCurrencyImagesArrays } from '../../../../../utils/utils'
import { handleHeaderCounter, setHeaderCount } from '../../../../../utils/animations'
import { claimQuestReward } from '../../../../../services/questsService'
import { getUserById } from '../../../../../services/userService'
import { getProgressText } from '../../../../../utils/quest'
import ProgressBar from '../../../../../components/ProgressBar'
import { getTimeDifference } from '../../../../../utils/genericOffer'
import Timer from '../../../../../assets/offers/timer.png'
import BottomButton from '../../../../../components/common/BottomButton'
import { CheckMark } from '../../../../../utils/icons'
import ValueFrame from '../../../../../components/valueFrame'

import './index.css'

const QuestAlert = ({ quest, type }) => {
  const { user, updateUserData } = useContext(AuthContext)

  const [time, setTime] = useState(null)
  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const navigate = useNavigate()
  const queryCLient = useQueryClient()

  const isCompleted = useMemo(() => type === QUEST_TYPE.COMPLETED, [type])
  const isQuestAlertCTAShown = useMemo(
    () => quest.isNotificationCTAShown && quest.CTA && quest.CTA.link !== MAIN_PAGE,
    [quest.isNotificationCTAShown, quest.CTA]
  )
  const progressBar = useMemo(() => getProgressText(quest), [quest])

  const buttonText = useMemo(() => {
    if (isCompleted) {
      return 'Claim!'
    } else if (isQuestAlertCTAShown) {
      return quest.CTA.text
    }
  }, [isCompleted, quest.isNotificationCTAShown, quest.CTA])

  useEffect(() => {
    if (isCompleted || !quest.expiredAt) {
      return
    }

    const luxonQuestEndTime = DateTime.fromJSDate(new Date(quest.expiredAt._seconds * 1000))
    const luxonCurrentTime = DateTime.now()
    const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)
    setTime(diff)
    const interval = setInterval(() => {
      const luxonCurrentTime = DateTime.now()
      const diff = getTimeDifference(luxonQuestEndTime, luxonCurrentTime)

      if (diff.valueOf() <= 0) {
        clearInterval(interval)
        return queryCLient.invalidateQueries('quests')
      }
      setTime(diff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleClaim = (sourceDiv) => {
    createVirtualCurrencyImagesArrays(
      sourceDiv,
      quest.coinsReward,
      quest.piggyReward,
      setCoinsArray,
      setPigsArray,
      0.5
    )

    handleHeaderCounter(false, quest.coinsReward)
    handleHeaderCounter(true, quest.piggyReward)

    claimQuestReward(quest.id)
      .then(({ updatedUser }) => updateUserData(updatedUser))
      .catch((error) => {
        if (
          error.response.data.message ===
          'Error with quest claim transaction: QUEST_ERROR: Reward for this quest is already claimed!'
        ) {
          toast.error('Reward for this quest is already claimed!')
        }
        getUserById(user.id)
          .then((updatedUser) => {
            updateUserData(updatedUser)
            setHeaderCount(updatedUser.piggyBanks, updatedUser.points)
          })
          .catch(() => toast.error('Unable to fetch user details.'))
      })

    setTimeout(() => {
      setSwiping(true)
      setTimeout(() => setHidden(true), 1000)
    }, 1000)
  }

  const handleAlertClick = (event) => {
    if (isCompleted) {
      handleClaim(event.currentTarget)
    } else if (isQuestAlertCTAShown) {
      navigate(quest.CTA.link)
    }
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>

      <div className="gameDetailsOfferParentContainer">
        {!isCompleted && time && (
          <div className="offer-limited-time">
            <img src={Timer} />
            Limited time: <strong>{time?.toFormat('hh:mm:ss')}</strong>
          </div>
        )}
        <Alert
          severity="info"
          icon={false}
          className={`offerAlert 
          ${isCompleted ? 'claimable' : ''} 
          ${swiping ? 'alertSwipe' : ''} 
          ${hidden ? 'hidden' : ''} 
          ${!isCompleted && time && 'showTime'}`}
        >
          <div className="offerAlertContent">
            <div className="questContainer">
              <div className="questImageTextContainer">
                <img className="questImage" src={quest.imageUrl} />
                <div className="textBlock">
                  <Typography variant="h4" className="title">
                    {quest.title}
                  </Typography>
                  <Typography variant="body2" className="children">
                    {quest.subtitle}
                  </Typography>
                  {isCompleted && (
                    <Typography variant="body2" className="complete">
                      <CheckMark />
                      Completed
                    </Typography>
                  )}
                </div>
              </div>
              <div className="questAlertReward">
                {quest.piggyReward > 0 && (
                  <ValueFrame value={quest.piggyReward} isPig={true} isModal={false} />
                )}
                {quest.coinsReward > 0 && (
                  <ValueFrame value={quest.coinsReward} isPig={false} isModal={false} />
                )}
                {buttonText && isCompleted && (
                  <BottomButton className="actionButton" handleClick={handleAlertClick}>
                    {buttonText}
                  </BottomButton>
                )}
              </div>
            </div>

            {quest.progressMeter &&
              (!isCompleted || new Date(quest.completedAt._seconds * 1000) > new Date()) && (
                <>
                  <div className="questProgress">
                    <ProgressBar
                      fillerType="gold"
                      completed={progressBar.progress}
                      style={styles.progress}
                    />
                    <Typography variant="body2" sx={styles.progressValue}>
                      {progressBar.text}
                    </Typography>
                  </div>
                  {buttonText && (
                    <BottomButton className="actionButton" handleClick={handleAlertClick}>
                      {buttonText}
                    </BottomButton>
                  )}
                </>
              )}
          </div>
        </Alert>
      </div>
    </>
  )
}

const styles = {
  progress: {
    border: '1px solid #FFC11F66',
    boxShadow: '2px 4px 12px 0px #FF962B1F',
    padding: '1px',
  },
  progressValue: {
    fontWeight: 400,
    color: '#3C4249',
  },
}

export default QuestAlert
