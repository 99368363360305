import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'

export const getAllGiftCards = async () => {
  return axiosBackendInstance.get('/api/v1/gift-cards/')
}

export const getGiftCardById = async (giftCardId) => {
  return axiosWebInstance.get(`/api/v1/gift-cards/${giftCardId}`)
}
