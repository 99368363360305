import React, { useState } from 'react'
import FullPageLoader from '../components/pageLoader'

const useLoader = (isLoading = false) => {
  const [loading, setLoading] = useState(isLoading)

  const loader = loading ? <FullPageLoader isVisible={true} /> : null

  const showLoader = () => setLoading(true)
  const hideLoader = () => setLoading(false)

  return { loader, showLoader, hideLoader }
}

export default useLoader
