import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

//constants
import QueryKeys from 'constants/queryKeys'
import { PIGGY_BANKS_MULTIPLIER, POINTS_MULTIPLIER } from 'constants/index'
//contexts
import LoaderContext from 'context/LoaderContext'
//services
import { getGiftCardById } from 'services/giftCardsService'
//types
import { GiftCard } from 'types'
//utils
import { handleError } from 'utils/utils'

const useQueryGetGiftCardById = (
  giftCardId: string | undefined,
  denomination: string | undefined,
  setValidDenomination: React.Dispatch<React.SetStateAction<boolean>>,
  setPointsValue: React.Dispatch<React.SetStateAction<number>>,
  setPiggyBanksValue: React.Dispatch<React.SetStateAction<number>>
) => {
  const { hideLoader } = useContext(LoaderContext)

  const { data, isFetching } = useQuery<AxiosResponse<GiftCard, Error>>(
    [QueryKeys.GET_GIFT_CARD, giftCardId],
    () => getGiftCardById(giftCardId),
    {
      enabled: Boolean(giftCardId) && Boolean(denomination),
      onSuccess: (response) => {
        const giftCard = response?.data
        const giftCardDenomination = giftCard?.denomination.find(
          ({ value }) => value === Number(denomination)
        )
        if (!giftCardDenomination) {
          setValidDenomination(false)
          return
        }

        const discount = giftCardDenomination?.discount

        const pointsValue = POINTS_MULTIPLIER(denomination)
        const piggyBanksValue = PIGGY_BANKS_MULTIPLIER(denomination)

        setPointsValue(discount ? pointsValue * (1 - discount / 100) : pointsValue)
        setPiggyBanksValue(discount ? piggyBanksValue * (1 - discount / 100) : piggyBanksValue)

        hideLoader()
      },
      onError: (err) => {
        hideLoader()
        handleError(err)
      },
    }
  )

  return { giftCard: data?.data, isFetching }
}

export default useQueryGetGiftCardById
