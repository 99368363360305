import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { Slide } from '@mui/material'

import { AUTHORIZATION_STATE, SEARCH_PARAMS } from '../../constants'
import { DontHaveAccountForm } from './components/DontHaveAccountForm'
import Content from './Content'

import './index.css'

const CreateAccount = () => {
  const [state, setState] = useState(AUTHORIZATION_STATE.LOADED)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAMS.DONT_HAVE)) {
      setState(AUTHORIZATION_STATE.DONT_HAVE)
    }

    if (searchParams.has('already_connected')) {
      toast.error(
        `This email is connected to another Playback account. Please log in with the primary email for that account: ${searchParams.get(
          'already_connected'
        )}`
      )
      searchParams.delete('already_connected')
      setSearchParams(searchParams)
    }
  }, [])

  if (state === AUTHORIZATION_STATE.DONT_HAVE) {
    return <DontHaveAccountForm state={state} setState={setState} />
  }

  const content = (
    <div>
      <Content setState={setState} />
    </div>
  )

  return (
    <>
      <Toaster />
      {state !== AUTHORIZATION_STATE.LOADED ? (
        <Slide
          direction="right"
          in={state === AUTHORIZATION_STATE.CREATE_ACCOUNT}
          mountOnEnter
          unmountOnExit
        >
          {content}
        </Slide>
      ) : (
        content
      )}
    </>
  )
}

export default CreateAccount
