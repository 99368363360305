import { captureEvent } from '@sentry/react'
import { useEffect, useState } from 'react'
import { ERRORS } from '../constants'

const DEFAULT_WIDTH = 400

const ImageCrop = ({ url, width = DEFAULT_WIDTH, setImageUrl }) => {
  const [croppedUrl, setCroppedUrl] = useState('')

  const getImageData = (url, key, isPNG = true) => {
    const regexes = [
      isPNG ? /(\d+)x(\d+)bb\.png/ : /(\d+)x(\d+)bb\.jpg/,
      isPNG ? /(\d+)x(\d+)sr\.png$/ : /(\d+)x(\d+)sr\.jpg$/,
    ]

    const regex = regexes[key]

    const match = url.match(regex)

    if (!match || match.length !== 3) {
      return false
    }

    return {
      ratio: match[1] / match[2],
      isPNG,
    }
  }

  const IMAGE_TYPES = [
    {
      pattern: /play-lh\.googleusercontent\.com/i,
      getData: (url, width) => {
        return url + `=w${width}-rw`
      },
    },
    {
      pattern: /is1-ssl\.mzstatic\.com/i,
      getData: (url, width) => {
        const image = getImageData(url, 0) || getImageData(url, 0, false)

        if (image) {
          const replacementRegex = image.isPNG ? /\.png\/.*$/ : /\.jpg\/.*$/

          width = image.ratio > 1 ? width : width * 2

          const dimensionString = `${image.isPNG ? '.png' : '.jpg'}/${width}x${Math.round(
            width * image.ratio
          )}.jpg`

          return url.replace(replacementRegex, dimensionString)
        }

        return url
      },
    },
    {
      pattern: /is2-ssl\.mzstatic\.com/i,
      getData: (url, width) => {
        const image = getImageData(url, 1) || getImageData(url, 1, false)

        if (image) {
          const replacementRegex = image.isPNG ? /(\d+)x(\d+)sr\.png$/ : /(\d+)x(\d+)sr\.jpg$/

          width = image.ratio > 1 ? width : width * 2

          const dimensionString = `${Math.round(width * image.ratio)}x${width}sr.jpg`

          return url.replace(replacementRegex, dimensionString)
        }
      },
    },
  ]

  const getImageUrl = (url, width) => {
    if (!url) {
      return
    }

    const getUrl = IMAGE_TYPES.find(({ pattern }) => pattern.test(url))?.getData

    if (getUrl) {
      return getUrl(url, width)
    }

    return url
  }

  const getCroppedImage = async (url) => {
    const croppedImageUrl = getImageUrl(url, width)

    setCroppedUrl(croppedImageUrl)
  }

  useEffect(() => {
    if (url) {
      getCroppedImage(url)
    }
  }, [url])

  if (!croppedUrl) {
    return null
  }

  return (
    <img
      src={croppedUrl}
      onError={(err) => {
        setImageUrl(url)

        captureEvent({
          message: ERRORS.IMAGE_FETCH,
          level: 'error',
          extra: { err: err.message },
        })
      }}
      onLoad={() => setImageUrl(croppedUrl)}
      style={{ display: 'none' }}
    />
  )
}

export default ImageCrop
