import Footer from './Footer'
import Header from './Header'

import './index.css'

const Layout = ({ isLoading = false, children }) => {
  return (
    <div
      className={`gamesLayoutInner ${isLoading ? 'blured' : ''}`}
      style={{ backgroundImage: `url('/images/background.png')` }}
    >
      <Header />
      <div className="gamesLayoutContent">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
