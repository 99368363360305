import { Typography } from '@mui/material'

//utils
import { formatNumber } from 'utils/utils'
//styles
import './index.css'

const RewardPoints = ({
  value,
  isPig,
  isLocked,
}: {
  value: number
  isPig: boolean
  isLocked?: boolean
}) => {
  const formattedValue = formatNumber(value)

  return (
    <div className={`reward-points ${isLocked && 'locked'}`}>
      <Typography variant="body2">{formattedValue}</Typography>
      <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} />
    </div>
  )
}

export default RewardPoints
