import { Button, Slide } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import BottomButton from '../../../../components/common/BottomButton'
import AuthLayout from '../../../../components/layout/Auth'
import Modal from '../../../../components/modal'
import { AUTHORIZATION_STATE, WHITELIST_MESSAGES } from '../../../../constants'
import { addEmailToWhitelist } from '../../../../services/whitelistService'
import { handleError } from '../../../../utils/utils'
import Content from './Content'

import './index.css'

export const DontHaveAccountForm = ({ state, setState }) => {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', text: '' })

  const hideModal = () => setModal(false)
  const showModal = () => setModal(true)

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })

  const handleModalContent = (modalContent, showModal) => {
    return () => {
      setModalContent(modalContent)
      showModal()
    }
  }

  const onSubmit = async (values) => {
    await addEmailToWhitelist(
      values,
      handleModalContent(WHITELIST_MESSAGES.SUCCESS, showModal),
      handleModalContent(WHITELIST_MESSAGES.ERROR, showModal),
      true
    )
      .catch(handleError)
      .finally(() => setValue('email', ''))
  }

  return (
    <>
      <Modal showModal={modal} hideModal={hideModal} {...modalContent} />
      <Slide
        direction="left"
        in={state === AUTHORIZATION_STATE.DONT_HAVE}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <AuthLayout title="Sign Up with Email" content={<Content control={control} />}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="dontHaveAccountButtons">
                <Button
                  variant="contained"
                  sx={styles.backButton}
                  style={{ backgroundColor: '#F3F4F6' }}
                  onClick={() => setState(AUTHORIZATION_STATE.CREATE_ACCOUNT)}
                >
                  Back
                </Button>
                <BottomButton handleClick={handleSubmit(onSubmit)}>Join a Waitlist</BottomButton>
              </div>
            </form>
          </AuthLayout>
        </div>
      </Slide>
    </>
  )
}

const styles = {
  backButton: {
    width: '100%',
    padding: '12px 10px',
    borderRadius: '100px',
    boxShadow: 'none',
    color: '#27272A',
    textTransform: 'none',
  },
}
