import { useRef } from 'react'
import { Typography } from '@mui/material'

import { formatNumber } from '../../utils/utils'

const OfferTaskValue = ({ value, isPig }) => {
  const imageRef = useRef(null)

  const formattedValue = formatNumber(value)

  return (
    <div className="valueGradientWrap gameDetailsOfferTaskValue">
      <Typography variant="body2">
        {formattedValue} <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} ref={imageRef} />{' '}
      </Typography>
    </div>
  )
}

export default OfferTaskValue
