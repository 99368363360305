import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BottomButton from '../../components/common/BottomButton'
import Layout from '../../components/layout/Details'
import PageInner from '../../components/layout/PageInner'
import TimerInput from '../../components/timerInput'
import { BigCoin } from '../../utils/icons'

import './index.css'

const HowItWorks = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => navigate(location.key !== 'default' ? -1 : '/verification')

  return (
    <PageInner>
      <Container maxWidth="sm" sx={styles.container}>
        <Layout customClassName="howItWorksInner" headContainerStyles={styles.headStyles}>
          <div className="howItWorksHeader">
            <BigCoin customClassName="howItWorksHeaderCoin" />
            <BigCoin customClassName="howItWorksHeaderCoin" />
            <Typography variant="h1" sx={styles.title}>
              How Rewards Work?
            </Typography>
            <img src="/images/howItWorksPhone.png" className="howItWorksHeaderImage" />
          </div>
          <div className="howItWorksEarn">
            <Typography variant="h1">Earn coins to buy gift cards.</Typography>
            <Box sx={styles.earnInputs}>
              <div className="howItWorksEarnInputInner pig">
                <div className="howItWorksEarnInput pig">
                  <Typography variant="h4" sx={styles.earnValue} className="priceText pig">
                    240
                  </Typography>
                  <img src="/images/pig.png" className="howItWorksEarnInputImage" />
                </div>
              </div>
              <div className="howItWorksEarnInputInner coin">
                <div className="howItWorksEarnInput coin">
                  <Typography variant="h4" sx={styles.earnValue} className="priceText coin">
                    26,500
                  </Typography>
                  <img src="/images/coin.png" className="howItWorksEarnInputImage" />
                </div>
              </div>
            </Box>
            <Typography variant="h4" className="howItWorksText">
              Earn <img src="/images/coin.png" /> with every purchase that you make in any game.
            </Typography>
            <Typography variant="h4" className="howItWorksText rate">
              The standard rate is{' '}
            </Typography>
            <Box sx={styles.earnRate}>
              <Typography variant="h3" sx={styles.earnRateText}>
                $1 USD =
              </Typography>{' '}
              <div className="howItWorksEarnInputInner coin">
                <div className="howItWorksEarnInput coin">
                  <Typography variant="h4" sx={styles.earnValue} className="priceText coin">
                    100
                  </Typography>
                  <img src="/images/coin.png" className="howItWorksEarnInputImage" />
                </div>
              </div>
            </Box>
          </div>
          <div className="howItWorksLimited">
            <Typography variant="h1" sx={styles.limitedTitle}>
              Limited time special offers
            </Typography>
            <TimerInput>43h 25m</TimerInput>
            <Typography variant="h4" className="howItWorksText" sx={styles.limitedText}>
              Limited time special offers give you more <img src="/images/coin.png" /> for every $1
              spent in the related game, plus <img src="/images/pig.png" />
            </Typography>
            <Box sx={styles.limitedFooter}>
              <Typography variant="h3">Each</Typography>
              <Box sx={styles.limitedFooterInputs}>
                <div className="howItWorksEarnInputInner pig">
                  <div className="howItWorksEarnInput pig">
                    <Typography variant="h4" sx={styles.earnValue} className="priceText pig">
                      100
                    </Typography>
                    <img src="/images/pig.png" className="howItWorksEarnInputImage" />
                  </div>
                </div>
                <Typography variant="h3" sx={styles.limitedFooterPlus}>
                  {' + '}
                </Typography>
                <div className="howItWorksEarnInputInner coin">
                  <div className="howItWorksEarnInput coin">
                    <Typography variant="h4" sx={styles.earnValue} className="priceText coin">
                      1,000
                    </Typography>
                    <img src="/images/coin.png" className="howItWorksEarnInputImage" />
                  </div>
                </div>
              </Box>
              <Typography variant="h4" className="howItWorksText">
                is worth $1.00 in gift cards.
              </Typography>
            </Box>
          </div>
          <BottomButton style={styles.bottomButton} handleClick={goBack}>
            Got it!
          </BottomButton>
        </Layout>
      </Container>
    </PageInner>
  )
}

const styles = {
  container: {
    padding: 0,
    paddingTop: '24px',
  },
  title: {
    padding: '0 49px',
  },
  earnInputs: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    margin: '22px 0 44px',
  },
  earnValue: {
    fontWeight: 700,
  },
  earnText: {
    fontWeight: 400,
  },
  earnRate: {
    display: 'flex',
    gap: '3px',
    justifyContent: 'center',
  },
  earnRateText: {
    margin: 'auto 0',
  },
  limitedText: {
    margin: '44px 0',
  },
  limitedTitle: {
    marginBottom: '22px',
  },
  limitedFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  limitedFooterInputs: {
    display: 'flex',
    gap: '3px',
    justifyContent: 'center',
  },
  limitedFooterPlus: {
    margin: 'auto 0',
  },
  bottomButton: {
    marginTop: '94px',
  },
  headStyles: {
    marginBottom: 0,
    padding: 0,
  },
}

export default HowItWorks
