import { Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'

import { BackArrow as ArrowIcon } from '../../../../utils/icons'
import GameCard from '../GameCard'
import { getUserPlatform, sendGA4Event } from '../../../../utils/utils'
import {
  ANALYTICS_EVENT,
  CLICK_CARD_ONBOARDING_STEPS,
  LOCAL_STORAGE,
  USER_PLATFORM,
} from '../../../../constants'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import AuthContext from '../../../../context/AuthContext'
import useCardsSlider from '../../../../hooks/useCardsSlider'

import './index.css'

function OffersRow({ offers, categoryRowNumber, title, observer }) {
  const { user, changeStep } = useContext(AuthContext)

  const navigate = useNavigate()
  const { setItem } = useLocalStorage()
  const swiper = useCardsSlider(offers?.length)

  const handleSwiperClick = async (swiper, e) => {
    const offerString = e.target.closest('.gamesListCard')?.dataset?.offer

    if (offerString) {
      const offer = JSON.parse(offerString)

      sendGA4Event(ANALYTICS_EVENT.OFFER_TILE_CLICK, {
        offerId: offer.id,
        is_ua_offer: offer.isUAOffer,
        offer_tile_view_guid: offer.offerTileViewGUID,
        offer_campaign_id:
          getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
            ? offer.campaignIdAndroid
            : offer.campaignIdIOS,
      })

      sendGA4Event(ANALYTICS_EVENT.SELECT_ITEM.OFFER, {
        item_id: offer.id,
        item_brand: user.platform === 'iOS' ? 'iTunes' : 'Google Play',
        index: offer.index,
        is_activated: !!offer.activatedOffer,
        offer_campaign_id:
          getUserPlatform(user.platform) === USER_PLATFORM.ANDROID
            ? offer.campaignIdAndroid
            : offer.campaignIdIOS,
      })
      if (CLICK_CARD_ONBOARDING_STEPS.indexOf(user.onboardingStep) > -1) {
        setItem(LOCAL_STORAGE.OFFER_REWARDS_EXPLAINER_GAME, offer.gameName)
        await changeStep(true, 1)
      }
      navigate(`/games/${offer.unifiedAppId}?offerId=${offer.id}`)
    }
  }

  const sliderSettings = {
    className: 'gamesListOffersRowSlider',
    freeMode: true,
    modules: [FreeMode, Navigation],
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: true,
    width: 245,
    ref: swiper.positionRef,
    breakpoints: {
      640: {
        width: 298,
      },
    },
    onSwiper: swiper.setSwiper,
    onSlideChange: swiper.changeButtonStates,
    onClick: handleSwiperClick,
  }

  if (!offers?.length) {
    return null
  }

  return (
    <>
      <Typography variant="inherit">{title}</Typography>
      <div ref={swiper.wrapperRef} className="gamesListOffersRowItems">
        <div
          className={`gamesListOffersRowArrow ${!swiper.isPrevEnabled ? 'disabled' : ''} prev`}
          onClick={() => swiper.handleClickArrow(false)}
        >
          <ArrowIcon />
        </div>
        <Swiper {...sliderSettings}>
          {offers?.map((offer, key) => (
            <SwiperSlide key={offer.id + title} className="gamesListOffersRowItem">
              <GameCard
                observer={observer}
                offer={offer}
                index={key}
                category={title}
                categoryRowNumber={categoryRowNumber}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={`gamesListOffersRowArrow ${!swiper.isNextEnabled ? 'disabled' : ''} next`}
          onClick={swiper.handleClickArrow}
        >
          <ArrowIcon />
        </div>
      </div>
    </>
  )
}

export default OffersRow
