import { Typography } from '@mui/material'
import React, { useContext, useState } from 'react'

import AuthContext from '../../../../../context/AuthContext'
import OfferAlert from '../../../../../components/offerAlert'
import { claimReferralReward } from '../../../../../services/referalService'
import { handleHeaderCounter } from '../../../../../utils/animations'
import { createVirtualCurrencyImagesArrays } from '../../../../../utils/utils'
import { CLAIM_SPECIAL_REWARD_REASON } from '../../../../../constants'

const ReferFriendAlert = ({ reward }) => {
  const { updateUserData } = useContext(AuthContext)

  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleClaim = (sourceDiv) => {
    createVirtualCurrencyImagesArrays(
      sourceDiv,
      reward.earnedCoins,
      reward.earnedPiggy,
      setCoinsArray,
      setPigsArray,
      0.5
    )

    handleHeaderCounter(false, reward.earnedCoins)
    handleHeaderCounter(true, reward.earnedPiggy)

    claimReferralReward(reward.id).then(({ updatedUser }) => updateUserData(updatedUser))

    setTimeout(() => {
      setSwiping(true)
      setTimeout(() => setHidden(true), 1000)
    }, 1000)
  }

  const getRewardAlertText = (reason) => {
    const messages = {
      [CLAIM_SPECIAL_REWARD_REASON.SIGN_UP_REFERRER]: `You referred ${reward.connectedUserName}!`,
      [CLAIM_SPECIAL_REWARD_REASON.CLAIM_REWARD_REFERRER]: `${reward.connectedUserName} made a purchase so you get extra rewards`,
      [CLAIM_SPECIAL_REWARD_REASON.SIGN_UP_REFERRED]: `${reward.connectedUserName} referred you`,
      [CLAIM_SPECIAL_REWARD_REASON.CLAIM_REWARD_REFERRED]: `You earned extra rewards with your purchase because ${reward.connectedUserName} referred you`,
    }

    return <>{messages[reason]}</>
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        title={'Referral Bonus Reward!'}
        icon={<img src="/images/referFriendAlert.png" style={{ padding: 1 }} />}
        handleClick={(event) => handleClaim(event.currentTarget)}
        customClassName={`${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}
        coins={reward.earnedCoins}
        piggies={reward.earnedPiggy}
        buttonText="Claim!"
      >
        {getRewardAlertText(reward.reason)}
      </OfferAlert>
    </>
  )
}

export default ReferFriendAlert
