/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

//types
import { AuthContextType } from 'types'

const AuthContext = createContext<AuthContextType>({
  user: null,
  onboardingStep: null,
  updateUserData: () => {},
  login: async () => null,
  logout: async () => {},
  changeStep: async () => {},
})

export default AuthContext
