import React from 'react'
import { Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { ActionArrow, UpdateAction } from '../../../../utils/icons'

import './index.css'

const UserSettingsItems = ({ title, items }) => {
  return (
    <div className="userSettingsItem">
      <Typography variant="h2" sx={styles.general}>
        {title}
      </Typography>

      <Box sx={styles.generalItems}>
        {items.map(
          ({ label, value, switchValue, onClick, customSX, warning, onDelete, hideItem }) => {
            if (hideItem) {
              return null
            }
            const hasValue = value !== undefined
            const hasSwitch = switchValue !== undefined
            const noValueProvided = !hasValue && !hasSwitch

            return (
              <Box
                key={label || value}
                sx={customSX || styles.generalItem}
                className={noValueProvided && onClick ? 'userSettingsItemClickable' : ''}
                onClick={noValueProvided ? onClick : null}
              >
                <Typography variant="h4" sx={styles.generalItemTitle}>
                  {label || value}
                </Typography>
                {hasValue && (
                  <div className="userSettingsGeneralItemActionBox">
                    <Typography variant="h4" sx={styles.generalItemValue(warning)}>
                      {!label ? warning : value}
                    </Typography>
                    {onClick && <UpdateAction onClick={onClick} />}
                    {onDelete && <img src="/images/modalCloseButton.png" onClick={onDelete} />}
                  </div>
                )}
                {hasSwitch && <Switch checked={switchValue} onClick={onClick} />}
                {noValueProvided && <ActionArrow />}
              </Box>
            )
          }
        )}
      </Box>
    </div>
  )
}
const styles = {
  general: {
    margin: '32px 0 20px',
  },
  generalItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  generalItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '12px',
    borderBottom: '1px solid #E4E4E7',
  },
  generalItemTitle: {
    fontWeight: 400,
  },
  generalItemValue: (warning) => ({
    fontWeight: 400,
    ...(warning && { color: '#EF4444' }),
  }),
}

export default UserSettingsItems
