import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'

import PointInput from '../../../../../components/PointInput'
import Button from '../../../../../components/common/BottomButton'
import { Clock } from '../../../../../utils/icons'
import { counter } from '../../../../../utils/animations'
import AuthContext from '../../../../../context/AuthContext'
import useTimer from '../../../../../hooks/useTimer'

const FinishContent = ({ handleClaim, chestReward, disabled }) => {
  const { user } = useContext(AuthContext)
  const inputRef = useRef(null)

  const timer = useTimer(user.chestNextRefreshTime)

  useEffect(() => {
    if (inputRef.current) {
      const value = +inputRef.current.textContent.replace(/,/g, '')
      counter(inputRef.current, value, chestReward.piggyBanks, null, true)
    }
  }, [inputRef.current])

  return (
    <>
      <Typography variant="h1">You won!</Typography>
      <PointInput
        inputRef={inputRef}
        isPig={true}
        points={0}
        customInnerClassName="gamesListPiggyBankChestInputInner"
        customClassName="gamesListPiggyBankChestInput"
        inputVariant="h1"
      />
      <Box className="gamesListPiggyBankChestFinishFoot">
        <Button handleClick={handleClaim} style={styles.claimButton} disabled={disabled}>
          Claim!
        </Button>
        <Typography variant="h4" sx={styles.availableTimer}>
          New chest available in: <Clock /> {timer}
        </Typography>
      </Box>
    </>
  )
}

const styles = {
  claimButton: {
    width: '100%',
    padding: '12px 8px',
  },
  availableTimer: {
    color: '#94A3B8',
    fontWeight: 400,
    svg: {
      path: {
        fill: '#94A3B8',
      },
    },
  },
}

export default FinishContent
