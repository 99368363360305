import React from 'react'
import { Alert, Typography } from '@mui/material'

import {
  ConenctGmailYourData,
  ConnectGmailHowItWorks,
  ConnectGmailWeRespect,
} from '../../utils/icons'
import OnboardingStepper from '../../components/onboarding/Stepper'

const ConnectGmailContent = () => {
  return (
    <div className="connectGmailContent">
      <OnboardingStepper />
      <Typography variant="body1">Automatically earn rewards by connecting your gmail.</Typography>
      <div className="connectGmailTopics">
        {topics.map(({ title, Icon, text }) => (
          <Alert
            key={title}
            sx={styles.topic}
            severity="info"
            icon={
              <div className="connectGmailTopicImage">
                <Icon />
              </div>
            }
          >
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2">{text}</Typography>
          </Alert>
        ))}
      </div>
    </div>
  )
}

const topics = [
  {
    title: 'How it works',
    text: 'We automatically detect when you are sent a receipt from any mobile game and credit your playback account with points that can be spent on gift cards and more.',
    Icon: ConnectGmailHowItWorks,
  },
  {
    title: 'We respect your privacy',
    text: 'Playback will only use mobile game purchase related emails to credit your account with rewards. We do not scan or use any other information within your email.',
    Icon: ConnectGmailWeRespect,
  },
  {
    title: 'Your data is protected',
    text: 'Playback has been through a rigorous and independent validation test and is certified by Google to securely handle your receipt email information.',
    Icon: ConenctGmailYourData,
  },
]

const styles = {
  topic: {
    backgroundColor: 'transparent',
    padding: 0,
    '& .MuiAlert-icon': {
      marginRight: '16px',
      padding: 0,
    },
    '& .MuiAlert-message': {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: 0,
      overflow: 'hidden',
    },
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}

export default ConnectGmailContent
