import { useState } from 'react'
import FullPageLoader from '../components/pageLoader'
import LoaderContext from '../context/LoaderContext'

const LoaderProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false)

  const showLoader = () => setLoading(true)

  const hideLoader = () => setLoading(false)

  return (
    <LoaderContext.Provider value={{ isLoading, showLoader, hideLoader }}>
      <FullPageLoader isVisible={isLoading} />
      {children}
    </LoaderContext.Provider>
  )
}

export default LoaderProvider
