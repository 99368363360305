import { ReactNode, useState } from 'react'

//contexts
import TOSPromptConfigContext from './TOSPromptConfigContext'
//types
import { TOSPromptConfig } from 'types'

interface TOSPromptConfigProviderProps {
  children: ReactNode
}

export const TOSPromptConfigProvider = ({ children }: TOSPromptConfigProviderProps) => {
  const [TOSPromptConfig, setTOSPromptConfig] = useState<TOSPromptConfig | null>(null)

  return (
    <TOSPromptConfigContext.Provider value={{ TOSPromptConfig, setTOSPromptConfig }}>
      {children}
    </TOSPromptConfigContext.Provider>
  )
}
