import { Box, Button, Container, Switch, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import BottomButton from '../../components/common/BottomButton'
import { ANALYTICS_EVENT, APP_USAGE_PROMPT_RESULT, REACT_NATIVE_STATUS } from '../../constants'

import './index.css'

import { sendGA4Event, sendRNMessage } from '../../utils/utils'

const AppUsagePermission = () => {
  const [isToggleActive, setToggleActive] = useState(false)
  const navigate = useNavigate()

  const handleOpenSettingsClick = () => {
    sendGA4Event(ANALYTICS_EVENT.APP_USAGE_PROMPT, {
      result: APP_USAGE_PROMPT_RESULT.CLICKED_TO_PERMISSIONS,
    })
    sendRNMessage({
      status: REACT_NATIVE_STATUS.OPEN_APP_USAGE_PERMISSIONS,
    })
  }

  const handleDoItLaterClick = () => {
    sendGA4Event(ANALYTICS_EVENT.APP_USAGE_PROMPT, {
      result: APP_USAGE_PROMPT_RESULT.CLICKED_SKIP,
    })
    navigate('/games')
  }

  useEffect(() => {
    const interval = setInterval(() => setToggleActive((current) => !current), 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container maxWidth="sm">
      <Box sx={styles.boxContainer}>
        <img className="appUsageHeaderImage" src="/images/playbackLogo.png" />
        <Typography variant="h1">Permit Usage Access</Typography>
        <img src="/images/createAccountSliderImage2.png" className="appUsageAccessImage" />

        <Typography>
          Permit Usage access to the Playback app in settings to start earning maximum rewards!
        </Typography>

        <Typography variant="body1" sx={styles.inSettings}>
          In Settings:
        </Typography>
        <Box className="appUsageBoxWrapper">
          <Box sx={styles.permitUsageBox}>
            <Typography>Permit usage access</Typography>
            <Switch sx={styles.disabledSwitchStyle} checked={isToggleActive} />
            <img src="/images/arrowDown.png" className="appUsageBoxToggle" />
          </Box>
        </Box>
        <BottomButton style={styles.button} handleClick={handleOpenSettingsClick}>
          Permit for max rewards
        </BottomButton>
        <Button
          variant="text"
          onClick={handleDoItLaterClick}
          className="appUsageDoItLaterButton"
          sx={styles.doItLaterButton}
        >
          Earn fewer rewards
        </Button>
      </Box>
    </Container>
  )
}

const styles = {
  container: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  inSettings: {
    marginTop: '50px',
    marginBottom: '5px',
    color: '#A1A1AA',
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    textAlign: 'center',
    padding: '10px',
  },
  disabledSwitchStyle: {
    ml: 10,
    mr: -2,
  },
  permitUsageBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'white',
    px: 1,
    position: 'relative',
  },
  button: {
    background: '#FB923C',
    width: 'max-content',
    padding: '12px 24px',
    color: '#fff',
    fontWeight: 500,
    fontFamily: 'var(--inter-font)',
    marginTop: 'auto',
    '&:hover': {
      background: '#F5A462',
    },
  },
  doItLaterButton: {
    textTransform: 'none',
    typography: 'body2',
    marginBottom: '10px',
    marginTop: '14px',
    color: '#A1A1AA',
    padding: 0,
  },
}
export default AppUsagePermission
