import { Typography } from '@mui/material'

import RewardProgressItem from './Item'

import './index.css'

const OfferRewardProgress = ({ disabled = true, coins, piggies }) => {
  return (
    <div className="gameDetailsSpecialOfferRewardProgress">
      <Typography variant="h3">Reward Progress</Typography>
      <RewardProgressItem isPig={true} disabled={disabled} {...piggies} />
      <RewardProgressItem isPig={false} disabled={disabled} {...coins} />
    </div>
  )
}

export default OfferRewardProgress
