import React, { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import AuthContext from '../../../context/AuthContext'
import Video from '../../video'
import Image from '../Image'

import './index.css'

const GameCardSlider = ({ game, isAutoSwiping, storageKey }) => {
  const { user } = useContext(AuthContext)

  const [swiper, setSwiper] = useState(null)
  const [items, setItems] = useState([])
  const [speed, setSpeed] = useState(5000)

  const getScreenshots = (screenshots, array) => {
    screenshots?.map((screenshot) => {
      if (array.length > 2) {
        return
      }
      array.push({
        src: screenshot,
      })
    })
  }

  useEffect(() => {
    if (game) {
      const items = []

      items.push({
        src: game.featuredGraphic,
      })

      if (game.videoUrl) {
        items.push({
          src: game.videoUrl,
          isVideo: true,
        })
      }

      const availableScreenshots = []

      if (game.platformScreenshots?.length) {
        game.platformScreenshots.map(({ os, screenshotUrls }) => {
          if (os === user.platform) {
            getScreenshots(screenshotUrls, availableScreenshots)
          }
        })

        if (!availableScreenshots.length) {
          getScreenshots(game.platformScreenshots[0]?.screenshotUrls, availableScreenshots)
        }
      }

      setItems([...items, ...availableScreenshots])
    }
  }, [game])

  const handleVideoError = () => {
    items.map(({ isVideo }, key) => {
      if (isVideo) {
        items.splice(key, 1)
      }
    })

    setItems(items)
  }

  const handleAfterChange = (swiper) => setSpeed(swiper.activeIndex === 1 ? 10000 : 5000)

  const sliderSettings = {
    onSlideChange: handleAfterChange,
    autoplay: {
      delay: speed,
      disableOnInteraction: false,
    },
    pagination: {
      bulletActiveClass: 'active',
      bulletClass: 'offerCardSliderBullet',
    },
    modules: [Autoplay, Pagination],
    className: 'offerCardSlider',
    onSwiper: setSwiper,
  }

  useEffect(() => {
    const handleChangeSliderAuto = () => {
      if (!swiper) {
        return setTimeout(handleChangeSliderAuto, 300)
      }

      if (isAutoSwiping) {
        return swiper.autoplay?.start()
      }

      swiper.autoplay?.stop()
    }

    handleChangeSliderAuto()
  }, [isAutoSwiping, swiper])

  if (!items.length) {
    return null
  }

  return (
    <Swiper {...sliderSettings}>
      {items.map(({ src, isVideo }, index) => (
        <SwiperSlide key={src}>
          {isVideo ? (
            <Video
              src={src}
              height={175}
              isButtonVisible={false}
              handleError={handleVideoError}
              imageSrc={game.featuredGraphic}
              storageKey={storageKey}
            />
          ) : (
            <div className="offerCardSliderImage">
              <Image featuredGraphic={src} isSmall={false} isFirst={index === 0} />
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default GameCardSlider
