export const generateCountDownString = (timestamp, timeFormat, isFullTimeName = false) => {
  let expirationTime = new Date()

  switch (timeFormat) {
    case 'ZULU':
      expirationTime = new Date(timestamp)
      break
    case 'FIRESTORE':
      expirationTime = new Date(timestamp._seconds * 1000)
      break
    default:
      expirationTime = new Date(timestamp)
      break
  }

  const getTimeName = (shortName) => {
    const timeNames = {
      d: isFullTimeName ? ' days' : 'd',
      h: isFullTimeName ? ' hours' : 'h',
      m: isFullTimeName ? ' minutes' : 'm',
      s: isFullTimeName ? ' seconds' : 's',
    }

    return timeNames[shortName] || ''
  }

  const timeRemaining = expirationTime - Date.now()

  const secondsInDay = 24 * 60 * 60 * 1000
  const secondsInHour = 60 * 60 * 1000
  const secondsInMinute = 60 * 1000

  const days = Math.floor(timeRemaining / secondsInDay)
  const hours = Math.floor((timeRemaining % secondsInDay) / secondsInHour)
  const minutes = Math.floor((timeRemaining % secondsInHour) / secondsInMinute)
  const seconds = Math.floor((timeRemaining % secondsInMinute) / 1000)

  if (days >= 1) {
    return `${days}${getTimeName('d')} ${hours}${getTimeName('h')}`
  } else if (days < 1 && hours >= 1) {
    return `${days * 24 + hours}${getTimeName('h')} ${minutes}${getTimeName('m')}`
  } else if (hours < 1 && minutes >= 1) {
    return `${minutes}${getTimeName('m')} ${seconds}${getTimeName('s')}`
  } else if (seconds <= 0) {
    return 'expired'
  } else {
    return `${seconds}${getTimeName('s')}`
  }
}
