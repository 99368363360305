import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FAQ_ITEMS, SEARCH_PARAMS } from '../../constants'
import PageInner from '../../components/layout/PageInner'
import Layout from '../../components/layout/Details'

import './index.css'
const FAQ = () => {
  const [searchParams] = useSearchParams()
  const [shown, setShown] = useState()

  useEffect(() => {
    const shown = searchParams.get(SEARCH_PARAMS.FAQ_SHOWN)

    if (shown !== null) {
      setShown(+shown)
      document?.getElementsByClassName('MuiAccordion-root')[shown]?.scrollIntoView()
    }
  }, [])

  const handleChange = (index) => (event, newExpanded) => setShown(newExpanded ? index : false)

  return (
    <PageInner>
      <Layout title="Frequently Asked Questions">
        <Container maxWidth="sm" sx={styles.container}>
          <div className="faqContent">
            {FAQ_ITEMS.map(({ title, text }, key) => (
              <Accordion
                key={title}
                sx={styles.accordion}
                onChange={handleChange(key)}
                expanded={shown === key}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="p" sx={styles.topicTitle}>
                    <strong>{title}</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'left' }}>{text}</AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Container>
      </Layout>
    </PageInner>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  accordion: {
    '& .MuiAccordionSummary-content': {
      margin: '10px 0',
    },
  },
  topicTitle: {
    textAlign: 'left',
  },
}

export default FAQ
