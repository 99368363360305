import { createContext } from 'react'

const HistoryContext = createContext({
  history: [],
  push: () => {},
  previousPage: '',
  currentPage: '',
})

export default HistoryContext
