import React, { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'

import { Pause, Play } from '../../utils/icons'
import { fetchToBase64, getYoutubeVideoId } from '../../utils/utils'
import { ERRORS } from '../../constants'

import './index.css'

const YOUTUBE_LINK = 'youtube.com'
const PBR_REGEX = /https:\/\/r\.pbr\.gg\/video\/play_app\?appid=[a-f0-9]+/

const videoCacheMap = new Map()

const Video = ({ height, src, handleError, isButtonVisible = true, imageSrc, storageKey }) => {
  const [buttonVisible, setButtonVisible] = useState(false)
  const [paused, setPaused] = useState(false)
  const [isVertical, setIsVertical] = useState(false)
  const [videoId, setVideoId] = useState('')
  const [videoUrl, setVideoUrl] = useState(videoCacheMap.get(storageKey))

  const videoRef = useRef(null)
  const containerRef = useRef(null)

  const getVideo = async () => {
    const cachedVideoData = videoCacheMap.get(storageKey)

    if (src?.includes(YOUTUBE_LINK)) {
      return setVideoId(getYoutubeVideoId(src))
    }

    if (PBR_REGEX.test(src)) {
      return setVideoUrl(src)
    }

    if (cachedVideoData) {
      return setVideoUrl(cachedVideoData)
    }

    const onFetchSuccess = (dataUrl) => {
      setVideoUrl(dataUrl)

      videoCacheMap.set(storageKey, dataUrl)
    }

    const onFetchError = () => {
      setVideoUrl(src)
    }

    fetchToBase64(src, onFetchSuccess, ERRORS.VIDEO_CACHE, onFetchError)
  }

  useEffect(() => {
    getVideo()
  }, [src])

  const handlePlayOrPause = () => {
    setButtonVisible(true)
    setPaused(videoRef.current.paused)
    setTimeout(() => setButtonVisible(false), 1000)

    if (videoRef.current.paused) {
      return videoRef.current.play()
    }

    videoRef.current.pause()
  }

  const YOUTUBE_OPTS = {
    playerVars: {
      autoplay: 1,
      loop: 1,
      mute: 1,
      controls: 0,
      vq: 'medium',
    },
  }

  return (
    <div
      className="videoInner"
      style={{ height }}
      onClick={!videoId && isButtonVisible ? handlePlayOrPause : null}
      ref={containerRef}
    >
      {videoId ? (
        <YouTube
          videoId={videoId}
          onError={handleError}
          iframeClassName={`video ${isButtonVisible ? 'buttons-visible' : ''}`}
          opts={YOUTUBE_OPTS}
          onEnd={(e) => e.target?.playVideo()}
        />
      ) : (
        <>
          {isButtonVisible && videoUrl ? (
            <div className={`videoFilter ${!buttonVisible ? 'hidden' : ''}`}>
              {paused ? <Play /> : <Pause />}
            </div>
          ) : null}
          <video
            muted
            autoPlay
            loop
            playsInline
            className={`video ${isVertical ? 'vertical' : ''}`}
            poster={imageSrc}
            ref={videoRef}
            onPlay={(e) => setIsVertical(e.target?.videoHeight > e.target?.videoWidth)}
            src={videoUrl}
            type="video/mp4"
            onError={handleError}
          />
        </>
      )}
    </div>
  )
}

export default Video
