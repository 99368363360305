import _ from 'lodash'

import { captureEvent } from '@sentry/react'
import { axiosBackendInstance } from '../axios'
import { getUserPlatform, handleError } from '../utils/utils'
import { ERRORS, MMP_REPLACE_CONSTS } from '../constants'
import { MMP_PROVIDERS, USER_PLATFORM } from '../constants'
import { getDeviceData } from './userService'

export const generateMMPLink = async (clickId, user, game, ip) => {
  const encodedUserAgent = encodeURIComponent(window.navigator.userAgent)

  const deviceData = await getDeviceData(user.id)
  const adId = _.get(deviceData, 'aaid', '')

  const offerLink = game.specialOfferDetails.mmpLink

  const campaignId = game.specialOfferDetails.offerCampaignId

  const mmpClickDetails = {
    ip,
    userAgent: encodedUserAgent,
    clickId: clickId,
    userId: user.id,
    adId: adId,
    mmpProvider: game.specialOfferDetails.mmpProvider,
    mmpLink: offerLink,
    isUAOffer: game.specialOfferDetails.isUAOffer,
    campaignName: game.specialOfferDetails.publisherCampaignName,
    campaignId: campaignId.substring(0, 24),
    offerId: game.specialOfferDetails.id,
  }

  if (campaignId.length > 24) {
    captureEvent({
      message: ERRORS.MMP_LINK,
      level: 'error',
      extra: {
        err: 'The campaignId is > 24 characters and being truncated',
        offerLink,
        campaignId,
      },
    })
  }

  const clickableOfferLink = generateOfferLink(mmpClickDetails)

  if (
    Object.values(MMP_PROVIDERS).includes(mmpClickDetails.mmpProvider) &&
    mmpClickDetails.mmpProvider !== MMP_PROVIDERS.NONE &&
    clickableOfferLink === mmpClickDetails.mmpLink
  ) {
    captureEvent({
      message: ERRORS.MMP_LINK,
      level: 'error',
      extra: { err: 'The MMP link does not substitute any parameters', offerLink },
    })
  }

  await saveClickId({
    clickId,
    userId: user.id,
    campaignId,
    name: game.gameDetails.name,
    unifiedAppId: game.gameDetails.unified_app_id,
    clickedLink: clickableOfferLink,
    userAgent: window.navigator.userAgent,
    adid: deviceData?.aaid || null,
    offerId: game.specialOfferDetails.id,
    mmpProvider: game.specialOfferDetails.mmpProvider,
    offerCampaignName: game.specialOfferDetails.publisherCampaignName,
    linkPlatform: game.specialOfferDetails.platform,
    devicePlatform: getUserPlatform(USER_PLATFORM.DESKTOP),
    isOfferActivated: !!game.activatedOffer,
  })

  return clickableOfferLink
}

export const saveClickId = async (clickDetails) => {
  return axiosBackendInstance.post(`/api/v1/mmp/click`, clickDetails).catch(handleError)
}

const generateOfferLink = (mmpLinkDetails) => {
  switch (mmpLinkDetails.mmpProvider) {
    case MMP_PROVIDERS.NONE:
      return mmpLinkDetails.mmpLink
    case MMP_PROVIDERS.APPSFLYER:
      return generateAppsFlyerMMPURL(mmpLinkDetails)
    case MMP_PROVIDERS.MOTIVE:
      return generateMotiveMMPURL(mmpLinkDetails)
    case MMP_PROVIDERS.SINGULAR:
      return generateSingularLink(mmpLinkDetails)
    case MMP_PROVIDERS.ADJUST:
      return generateAdjustLink(mmpLinkDetails)
    case MMP_PROVIDERS.BITLAB:
      return generateBitlabMMPURL(mmpLinkDetails)
    case MMP_PROVIDERS.HANG_MY_ADS:
    case MMP_PROVIDERS.AD_ACTION:
    case MMP_PROVIDERS.FEEDMOB:
    default: {
      return generateDefaultMMPURL(mmpLinkDetails)
    }
  }
}

const generateAppsFlyerMMPURL = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  const subSiteId = getSubSiteId(mmpLinkDetails.isUAOffer)

  let mmpLink = mmpLinkDetails.mmpLink
    // for this one we include the = because the made the ip identifier the same
    .replace(/(=ip)/g, `=${mmpLinkDetails.ip}`)
    .replace('pbrid', mmpLinkDetails.userId)
    .replace('user_agent', encodeURIComponent(mmpLinkDetails.userAgent))
    .replace('click_id', mmpLinkDetails.clickId)
    .replace('adid', mmpLinkDetails.adId)
    .replace('sub_site_id', subSiteId)
    .replace('site_id', MMP_REPLACE_CONSTS.PUBLISHER_ID)
    .replace('campaign_name', encodedCampaignName)
    .replace('campaign_id', mmpLinkDetails.campaignId)
  return mmpLink
}

const generateMotiveMMPURL = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  let mmpLink = mmpLinkDetails.mmpLink
    .replace('click_id', mmpLinkDetails.clickId)
    .replace('adid', mmpLinkDetails.adId)
    .replace('campaign_name', encodedCampaignName)
  return mmpLink
}

const generateBitlabMMPURL = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  let mmpLink = mmpLinkDetails.mmpLink.replaceAll('{campaign_name}', encodedCampaignName)

  mmpLink += `${mmpLinkDetails.userId}&s3=${mmpLinkDetails.clickId}`
  return mmpLink
}

const generateDefaultMMPURL = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  let mmpLink = mmpLinkDetails.mmpLink
    .replaceAll('{click_id}', mmpLinkDetails.clickId)
    .replaceAll('{google_adid}', mmpLinkDetails.adId)
    .replaceAll('{ios_adid}', '') // In the future if we've got iOS ad ID's, set here
    .replaceAll('{user_id}', mmpLinkDetails.userId)
    .replaceAll('{campaign_name}', encodedCampaignName)
    .replaceAll('{campaign_id}', mmpLinkDetails.campaignId)
    .replaceAll('{user_agent}', encodeURIComponent(mmpLinkDetails.userAgent))
    .replaceAll('{ip}', mmpLinkDetails.ip)
    .replaceAll('{click_time_iso}', new Date().toISOString())

  return mmpLink
}

const generateSingularLink = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  const subSiteId = getSubSiteId(mmpLinkDetails.isUAOffer)

  let mmpLink = mmpLinkDetails.mmpLink
    .replace('{click_id}', mmpLinkDetails.clickId)
    .replace('{publisher_id}', MMP_REPLACE_CONSTS.PUBLISHER_ID)
    .replace('{user_agent}', encodeURIComponent(mmpLinkDetails.userAgent))
    .replace('{sub_site_id}', subSiteId)
    .replace('{user_id}', mmpLinkDetails.userId)
    .replace('{adid}', mmpLinkDetails.adId)
    .replace('{ip}', mmpLinkDetails.ip)
    .replace('{campaign_name}', encodedCampaignName)
    .replace('{campaign_id}', mmpLinkDetails.campaignId)

  return mmpLink
}

const generateAdjustLink = (mmpLinkDetails) => {
  const encodedCampaignName = encodeURIComponent(mmpLinkDetails.campaignName)
  const subSiteId = getSubSiteId(mmpLinkDetails.isUAOffer)

  let mmpLink = mmpLinkDetails.mmpLink
    .replace('{campaign_name}', encodedCampaignName)
    .replaceAll('{site_id}', MMP_REPLACE_CONSTS.PUBLISHER_ID)
    .replaceAll('{click_id}', mmpLinkDetails.clickId)
    .replace('{ip}', mmpLinkDetails.ip)
    .replace('{sub_site_id}', subSiteId)
    .replaceAll('{pbrid}', mmpLinkDetails.userId)
    .replace('{gaid}', mmpLinkDetails.adId)

  return mmpLink
}

const getSubSiteId = (isUAOffer) => {
  return isUAOffer
    ? MMP_REPLACE_CONSTS.UA_OFFER_SUB_SITE_ID
    : MMP_REPLACE_CONSTS.NON_UA_OFFER_SUB_SITE_ID
}
