import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import MessageLayout from '../components/layout/Message'

const CameraNotAllowed = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBackArrowClick = () => navigate(location.key !== 'default' ? -1 : '/verification')

  return (
    <MessageLayout title="Camera Access Required" handleBackArrowClick={handleBackArrowClick}>
      To complete verification, you must allow access to your camera for Stripe.
    </MessageLayout>
  )
}

export default CameraNotAllowed
