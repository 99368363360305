import { AxiosResponse } from 'axios'

import axiosWebInstance from '../axiosWebInstance'
import { TOSPromptConfigResponse, UpdatedUserResponse } from 'types'

export const getTOSPromptConfig = async (): Promise<
  AxiosResponse<TOSPromptConfigResponse, Error>
> => {
  return axiosWebInstance.get('/api/v1/users/tos')
}

export const acceptToS = async (): Promise<AxiosResponse<UpdatedUserResponse, Error>> => {
  return axiosWebInstance.patch('/api/v1/users/accept-tos')
}
