import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { TIMER_EXPIRED } from '../constants'
import { generateCountDownString } from '../utils/timer'

const useTimer = (
  timestamp,
  invalidateQueryKeys = [],
  isExpirable = false,
  expiredFunction = null
) => {
  const [text, setText] = useState('')
  const [isExpired, setExpired] = useState(false)
  const queryClient = useQueryClient()

  const handleExpiresContent = () => {
    const timerString = generateCountDownString(timestamp, 'FIRESTORE', false)

    const isExpired = timerString === TIMER_EXPIRED

    if (isExpired) {
      isExpirable && setText(timerString)

      if (invalidateQueryKeys.length) {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: invalidateQueryKeys,
          })
        }, 1000)
      }

      expiredFunction && expiredFunction()

      setExpired(isExpired)

      return
    }
    setText(timerString)
  }

  useEffect(() => {
    if (timestamp && timestamp._seconds >= new Date().getTime() / 1000 && !isExpired) {
      handleExpiresContent(timestamp)

      const interval = setInterval(() => {
        handleExpiresContent(timestamp)
      }, 1000)

      return () => clearInterval(interval)
    }

    isExpirable && setText(TIMER_EXPIRED)
  }, [timestamp, isExpired])

  return text
}

export default useTimer
