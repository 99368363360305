import { useEffect } from 'react'

const useSwipe = (positionRef, wrapperRef) => {
  const handleMouseDown = (e) => {
    positionRef.clientX = e.clientX
  }

  const handleClick = (e) => {
    if (positionRef.clientX !== e.clientX) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (wrapperRef.current && positionRef.current) {
      const slider = wrapperRef.current

      slider.addEventListener('mousedown', handleMouseDown)
      slider.addEventListener('click', handleClick)

      return () => {
        slider.removeEventListener('mousedown', handleMouseDown)
        slider.removeEventListener('click', handleClick)
      }
    }
  }, [wrapperRef.current, positionRef.current])
}

export default useSwipe
