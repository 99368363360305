import React from 'react'
import { Container } from '@mui/system'
import { Typography } from '@mui/material'

import EarnGiftCards from '../../components/EarnGiftCards'
import GameCard from './components/GameCard'

const Content = ({ gameLibrary }) => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h1">Favorite Games</Typography>
      <Typography variant="h4" sx={styles.text}>
        Explore all of the games that you’ve earned rewards for!
      </Typography>
      <div>
        {!gameLibrary?.length && (
          <div className="gameLibraryDescription">
            <Typography variant="inherit">No rewards earned yet...</Typography>
            <EarnGiftCards />
          </div>
        )}
        <div className="gameLibraryItems">
          {gameLibrary?.map(
            (game) => game.gameDetails && <GameCard key={game.unifiedAppId} game={game} />
          )}
        </div>
      </div>
    </Container>
  )
}

const styles = {
  text: {
    fontWeight: 400,
    marginTop: '8px',
  },
  button: {
    padding: '16px',
    marginTop: '20px',
  },
}

export default Content
