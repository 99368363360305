import { axiosBackendInstance } from '../axios'
import { LOCAL_STORAGE } from '../constants'

export const useRefreshToken = () => {
  const refresh = async () => {
    const response = await axiosBackendInstance
      .get('/auth/refresh', {
        withCredentials: true,
      })
      .catch((err) => {
        throw new Error(err)
      })
    // TODO: Need to get this out of local storage
    localStorage.setItem(LOCAL_STORAGE.JWT_TOKEN, response.accessToken)
    return response.accessToken
  }

  return refresh
}
