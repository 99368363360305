import { useEffect, useMemo, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { TaskType } from '@playback-rewards/shared-libraries'

//assets
import GamePad from 'assets/offers/gamepad.png'
import Cart from 'assets/offers/cart.png'
import Timer from 'assets/offers/timer.png'
//components
import Task from 'components/Task'
//types
import { GenericMMPPurchaseTaskProps } from 'types'
//utils
import {
  formatTaskTimeLeft,
  getTimeDifference,
  getTimeValue,
  isExpiredGenericTask,
  isGenericTaskNotStarted,
  isHiddenNotStartedGenericTask,
  transformTimestampToDate,
} from 'utils/genericOffer'

const GenericMMPPurchaseTask = ({
  isInstalled,
  allMMPTasks,
  mmpTask,
  buttonText,
  onButtonClick,
  onRefresh,
}: GenericMMPPurchaseTaskProps) => {
  const [time, setTime] = useState<Duration>()
  const isPurchaseTask = useMemo(
    () => [TaskType.PURCHASE, TaskType.PURCHASE_AMOUNT].includes(mmpTask.taskType),
    [mmpTask.taskType]
  )
  const hasPreviousRequirements = useMemo(() => {
    return mmpTask.previousTaskRequirements
      .map((requirement) =>
        allMMPTasks.find((event) => event.taskId === requirement && !event.completed)
      )
      .filter((event) => event !== undefined)
  }, [mmpTask.previousTaskRequirements])
  const isTaskUnstarted = useMemo(() => isGenericTaskNotStarted(mmpTask), [isGenericTaskNotStarted])
  const isLocked = useMemo(
    () => hasPreviousRequirements.length > 0 || isTaskUnstarted,
    [hasPreviousRequirements, isTaskUnstarted]
  )
  const progress = useMemo(() => {
    if (!isExpiredGenericTask(mmpTask) && mmpTask.taskType === TaskType.PURCHASE_AMOUNT) {
      return (mmpTask.current / mmpTask.amount) * 100
    }
  }, [mmpTask.taskType, mmpTask.current, mmpTask.amount])
  const shouldShowExpiredTask = useMemo(
    () => !mmpTask.hideAfterExpire && isExpiredGenericTask(mmpTask),
    [mmpTask.hideAfterExpire, isExpiredGenericTask]
  )

  useEffect(() => {
    const { startTimestamp, endTimestamp } = mmpTask

    if ((!startTimestamp && !endTimestamp) || isExpiredGenericTask(mmpTask)) {
      return
    }

    const mmpTaskStartTime = startTimestamp ? transformTimestampToDate(startTimestamp) : null
    const mmpTaskEndTime = endTimestamp ? transformTimestampToDate(endTimestamp) : null

    const luxonStartTime = mmpTaskStartTime ? DateTime.fromJSDate(mmpTaskStartTime) : null
    const luxonEndTime = mmpTaskEndTime ? DateTime.fromJSDate(mmpTaskEndTime) : null
    const luxonCurrentTime = DateTime.now()

    if ((isLocked && !luxonStartTime) || (!isLocked && !luxonEndTime)) {
      return
    }

    const diff = getTimeDifference(!isLocked ? luxonEndTime! : luxonStartTime!, luxonCurrentTime)
    setTime(diff)

    const interval = setInterval(() => {
      const luxonCurrentTime = DateTime.now()
      const diff = getTimeDifference(!isLocked ? luxonEndTime! : luxonStartTime!, luxonCurrentTime)
      if (diff.valueOf() <= 0) {
        clearInterval(interval)
        return onRefresh()
      }
      setTime(diff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isLocked, mmpTask])

  const subTitle = useMemo(() => {
    if (shouldShowExpiredTask) {
      return <i>Time Limit expired</i>
    } else if (!isInstalled) {
      return 'Unlocks after install'
    } else if (isTaskUnstarted && mmpTask.showUnlockTimer) {
      return (
        <span>
          Unlocks in: <img src={Timer} /> {getTimeValue(time)}
        </span>
      )
    } else if (mmpTask.taskType === TaskType.PURCHASE_AMOUNT) {
      return <span>Spend ${(mmpTask.amount - mmpTask.current).toFixed(2)} more!</span>
    } else if (hasPreviousRequirements) {
      return hasPreviousRequirements.map((requirement, index) => (
        <p key={`requirement ${requirement?.taskId}-${index}`}>
          Unlocks after {requirement?.taskDescription}
        </p>
      ))
    }
  }, [
    isInstalled,
    hasPreviousRequirements,
    time,
    mmpTask.taskType,
    mmpTask.current,
    mmpTask.amount,
  ])

  if (
    isHiddenNotStartedGenericTask(mmpTask, isInstalled) ||
    (isExpiredGenericTask(mmpTask) && mmpTask.hideAfterExpire)
  ) {
    return <></>
  }

  return (
    <Task
      icon={isPurchaseTask ? <img style={{ padding: 2 }} src={Cart} /> : <img src={GamePad} />}
      title={mmpTask.taskDescription}
      subTitle={subTitle}
      piggies={mmpTask.rewards.piggyBanks}
      coins={mmpTask.rewards.coins}
      progress={progress}
      isCompleted={isInstalled && mmpTask.completed}
      isLocked={!isInstalled || isLocked}
      shouldShowExpiredTask={shouldShowExpiredTask}
      time={formatTaskTimeLeft(time)}
      showTime={isInstalled && !isLocked && mmpTask.showExpireTimer}
      buttonText={buttonText}
      onButtonClick={onButtonClick}
    />
  )
}

export default GenericMMPPurchaseTask
