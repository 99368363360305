import { useEffect, useRef, useState } from 'react'
import useSwipe from './useSwipe'

const useCardsSlider = (length) => {
  const [swiper, setSwiper] = useState(null)

  const [isPrevEnabled, setPrevEnabled] = useState(false)
  const [isNextEnabled, setNextEnabled] = useState(false)

  const changeButtonStates = (swiper) => {
    if (swiper) {
      setPrevEnabled(swiper.activeIndex > 0)
      setNextEnabled(swiper.activeIndex < length - 1)
    }
  }

  const handleClickArrow = (isNext = true) => {
    const prevButton = swiper?.navigation?.prevEl
    const nextButton = swiper?.navigation?.nextEl

    isNext ? nextButton?.click() : prevButton?.click()
  }

  const wrapperRef = useRef(null)
  const positionRef = useRef(null)

  useEffect(() => {
    changeButtonStates(swiper)
  }, [swiper])

  useSwipe(positionRef, wrapperRef)

  return {
    setSwiper,
    isPrevEnabled,
    isNextEnabled,
    positionRef,
    wrapperRef,
    handleClickArrow,
    changeButtonStates,
  }
}

export default useCardsSlider
