import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import MessageLayout from '../components/layout/Message'
import { GMAIL_CONNECTION_FAILURE_REASONS } from '../constants'

const ErrorGmailConnection = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const reason = searchParams.get('reason')
  const failureBody =
    GMAIL_CONNECTION_FAILURE_REASONS.find(({ type }) => type === reason) ||
    GMAIL_CONNECTION_FAILURE_REASONS.find(({ type }) => type === 'unexpected_error')

  const handleClick = () => navigate('/connect/gmail')

  return (
    <MessageLayout
      title={failureBody.title}
      button={{
        handleClick,
        text: failureBody.buttonText,
      }}
    >
      <Typography variant="body2" sx={styles.text}>
        {failureBody.message}
      </Typography>
    </MessageLayout>
  )
}

const styles = {
  text: {
    a: {
      color: '#27272A',
    },
  },
}

export default ErrorGmailConnection
