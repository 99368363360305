import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'

const AgeErrorPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBackArrowClick = () => navigate(location.key !== 'default' ? -1 : '')

  return (
    <MessageLayout
      title="Unfortunately, you must be 18 years or older in order to use Playback."
      handleBackArrowClick={handleBackArrowClick}
    />
  )
}

export default AgeErrorPage
