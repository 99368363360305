import { getDatabase } from 'firebase/database'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_REALTIME_API_KEY,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

if (process.env.REACT_APP_RECAPTCHA_DEBUG_MODE == 'ON') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_RECAPTCHA_DEBUG_TOKEN
}

// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_SITE_KEY),
//   isTokenAutoRefreshEnabled: true,
// })

export const db = getDatabase(app)
