import { axiosBackendInstance } from '../axios'

export const getCurrentReferralOffer = async () => {
  return axiosBackendInstance.get('/api/v1/referral')
}

export const getReferralTrackedRecord = async () => {
  return axiosBackendInstance.get('/api/v1/referral/user')
}

export const getUserReferralRewards = async (filter) => {
  return axiosBackendInstance.get(`/api/v1/referral/rewards?filter=${filter}`)
}

export const claimReferralReward = async (referralRewardId) => {
  return axiosBackendInstance.post(`/api/v1/referral/claim/${referralRewardId}`)
}

export const getUserReferralId = async () => {
  return axiosBackendInstance.get('/api/v1/referral/code')
}

export const getUserByReferralId = async (referralId) => {
  return axiosBackendInstance.get(`/api/v1/referral/user/${referralId}`)
}
