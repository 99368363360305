import React from 'react'
import { Typography } from '@mui/material'

import { GoldenCoin } from '../utils/icons'
import ErrorLayout from '../components/layout/Error'

const NotFound = () => {
  return (
    <ErrorLayout
      title={
        <>
          <Typography variant="h1" sx={styles.title} className="priceText pig">
            4
          </Typography>
          <GoldenCoin />
          <Typography variant="h1" sx={styles.title} className="priceText pig">
            4
          </Typography>
        </>
      }
      subtitle="Sorry!"
      text="The page you're looking for was not found..."
    />
  )
}

const styles = {
  title: {
    fontSize: '84px',
    lineHeight: '98px',
    fontWeight: 700,
    svg: {
      width: 70,
      height: 70,
      margin: 'auto',
    },
    '@media (max-width: 640px)': {
      fontSize: '80px',
      lineHeight: '68px',
      svg: {
        width: 66,
        height: 66,
      },
    },
    '@media (max-width: 380px)': {
      fontSize: '52px',
      lineHeight: '60px',
      svg: {
        width: 46,
        height: 46,
      },
    },
  },
}

export default NotFound
