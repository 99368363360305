import { axiosBackendInstance } from '../axios'

export const getUserQuests = async () => {
  return axiosBackendInstance.get('/api/v1/quests')
}

export const getQuestsForGamesPage = async () => {
  return axiosBackendInstance.get('/api/v1/quests/unclaimed')
}

export const claimQuestReward = async (questId) => {
  return axiosBackendInstance.put(`/api/v1/quests/claim/${questId}`)
}

export const refreshUserQuests = async (user, skipCondition = false) => {
  const questsCanBeRefreshed = Object.values(user.questNextRefreshTime).some(
    (refreshTime) => refreshTime._seconds < new Date().getTime() / 1000
  )

  if (questsCanBeRefreshed || skipCondition) {
    return axiosBackendInstance.post(`/api/v1/quests/${user.id}/refresh`)
  }
}

export const updateQuestPlayTimeProgress = async (unifiedAppId) => {
  return axiosBackendInstance.patch(`/api/v1/quests/progress/playtime/${unifiedAppId}`)
}
