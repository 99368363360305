import { Typography } from '@mui/material'

//assets
import Timer from 'assets/offers/timer.png'
import Tick from 'assets/offers/tick.png'
//components
import RewardPoints from '../RewardPoints'
import BottomButton from 'components/common/BottomButton'
//styles
import './index.css'
//types
import { TaskProps } from 'types'

const Task = ({
  customClassName,
  icon,
  title,
  subTitle,
  piggies,
  coins,
  progress = 0,
  isCompleted,
  isLocked,
  shouldShowExpiredTask,
  time,
  showTime,
  buttonText,
  onButtonClick,
}: TaskProps) => {
  const getIcon = () => {
    if (isLocked || shouldShowExpiredTask) {
      return (
        <div className="lock-container">
          <img src={`/images/lock.png`} />
        </div>
      )
    } else if (isCompleted) {
      return (
        <div className="completed-container">
          <img src={'/images/orangeCheck.png'} />
        </div>
      )
    }
    return icon
  }

  return (
    <div className="gameDetailsOfferParentContainer">
      {showTime && time && !shouldShowExpiredTask && (
        <div className="offer-limited-time">
          <img src={Timer} />
          Limited time: <strong>{time}</strong>
        </div>
      )}
      <div
        className={`gameDetailsOffer ${customClassName} ${
          time && showTime && !shouldShowExpiredTask && 'showTime'
        }`}
      >
        {!isLocked && <div className="progress" style={{ width: `${progress}%` }} />}
        <div className={`container ${isLocked || shouldShowExpiredTask ? 'locked' : ''}`}>
          {getIcon()}
          <div className="content">
            <Typography variant="h4" className="title">
              {title}
            </Typography>
            {subTitle && (
              <Typography variant="body2" className="sub-title">
                {subTitle}
              </Typography>
            )}
            {isCompleted && (
              <div className="completed">
                <img src={Tick} />
                <Typography variant="body2" color="#039F76">
                  Completed
                </Typography>
              </div>
            )}
          </div>
          <div className="rewards">
            {piggies > 0 && (
              <RewardPoints
                value={piggies}
                isPig={true}
                isLocked={isLocked || shouldShowExpiredTask}
              />
            )}
            {coins > 0 && (
              <RewardPoints
                value={coins}
                isPig={false}
                isLocked={isLocked || shouldShowExpiredTask}
              />
            )}
          </div>
          {!isLocked && Boolean(buttonText) && (
            <BottomButton className="actionButton" handleClick={onButtonClick}>
              {buttonText}
            </BottomButton>
          )}
        </div>
        {(isLocked || shouldShowExpiredTask) && <div className="overlay" />}
      </div>
    </div>
  )
}

export default Task
