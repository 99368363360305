import { Container } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthContext from '../../../../context/AuthContext'
import { GamesHeaderUserIcon } from '../../../../utils/icons'

import './index.css'
import PointInput from '../../../PointInput'

const Header = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const redirectToBalance = () => navigate('/profile/balance')

  const redirectToSettings = () => navigate('/profile/settings')

  const redirectToGames = () => navigate('/games')

  return (
    <header className="gamesHeader">
      <Container maxWidth="sm" sx={styles.container}>
        <img src="/images/playbackLogo.png" className="gamesHeaderLogo" onClick={redirectToGames} />
        <div className="gamesHeaderActions">
          <div className="gamesHeaderInputs">
            <PointInput
              onClick={redirectToBalance}
              isPig={true}
              points={user?.piggyBanks}
              customInnerClassName="gamesHeaderInputInner"
              customClassName="gamesHeaderInput"
            />
            <PointInput
              onClick={redirectToBalance}
              isPig={false}
              points={user?.points}
              customInnerClassName="gamesHeaderInputInner"
              customClassName="gamesHeaderInput"
            />
          </div>
          <div className="gamesHeaderUserIcon" onClick={redirectToSettings}>
            <GamesHeaderUserIcon />
          </div>
        </div>
      </Container>
    </header>
  )
}

const styles = {
  value: {
    fontWeight: 700,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
  },
}

export default Header
