import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSearchParams } from 'react-router-dom'

import LoaderContext from '../context/LoaderContext'
import { UserAttributionService } from '../services/userAttributionService'
import { getSegmentConfig } from '../services/segmentService'
import { handleError } from '../utils/utils'

const useSegmentConfig = (finalFunction = null) => {
  const [signupAttr, setSignupAttr] = useState('')
  const [segmentConfig, setSegmentConfig] = useState({})
  const { showLoader, hideLoader, isLoading } = useContext(LoaderContext)

  const [searchParams] = useSearchParams()
  const utmSegment = searchParams.get('utm_segment')

  useEffect(() => {
    const utmParameters = new URLSearchParams(window.location.search)

    utmParameters.set('userAgent', navigator.userAgent)
    const params = utmParameters.size > 0 ? Object.fromEntries([...utmParameters]) : null

    if (params) {
      if (!params.transaction_id) {
        params.transaction_id = uuidv4()
      }
      const userAttr = new UserAttributionService()
      userAttr.saveUserAttribution(params)

      setSignupAttr(params?.transaction_id)

      showLoader()
      getSegmentConfig(utmSegment)
        .then((data) => setSegmentConfig(data))
        .catch(handleError)
        .finally(async () => {
          if (finalFunction) {
            await finalFunction()
          }
          hideLoader()
        })
    }
  }, [])

  return { segmentConfig, isLoading, signupAttr }
}

export default useSegmentConfig
