import React, { useEffect, useRef } from 'react'
import { FormGroup, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import OnboardingStepper from '../../components/onboarding/Stepper'
import { AGE_MINIMUM_DATE, PLATFORMS_ARRAY } from '../../constants'
import Select from '../../components/select'

const EligibilityGateContent = ({ handleSubmit, onSubmit, control }) => {
  const dateInputRef = useRef(null)

  useEffect(() => dateInputRef.current.setAttribute('inputmode', 'tel'), [])

  return (
    <div className="eligibilityGateContent">
      <OnboardingStepper />
      <Typography variant="body1">
        Before you get started we need a little more info to give you the best experience.
      </Typography>
      <form className="eligibilityGateForm" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            name="birthDate"
            control={control}
            rules={{
              required: {
                value: true,
              },
              validate: (value) => {
                if (value < new Date(AGE_MINIMUM_DATE)) {
                  return 'Birth Date is not valid'
                }
              },
            }}
            render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disableFuture
                  inputRef={dateInputRef}
                  slotProps={{
                    textField: {
                      error: !!error,
                      inputMode: 'numeric',
                      type: 'tel',
                      placeholder: 'Date of Birth',
                    },
                  }}
                  onChange={onChange}
                  {...restField}
                />
              </LocalizationProvider>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            control={control}
            name="platform"
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select currentItem={value} options={PLATFORMS_ARRAY} onChange={onChange} />
            )}
          />
        </FormGroup>
      </form>
    </div>
  )
}

export default EligibilityGateContent
