import { v4 as uuidv4 } from 'uuid'

import { getUserPlatform } from '../utils/utils'
import { generateBaseAuthLink } from '../services/authService'
import { UserAttributionService } from '../services/userAttributionService'
import { getGeoData } from '../services/geoDataService'

const GrabInformation = () => {
  const utmParameters = new URLSearchParams(window.location.search)
  const params = utmParameters.size > 0 ? Object.fromEntries([...utmParameters]) : null

  if (params) {
    if (!params.transaction_id) {
      params.transaction_id = uuidv4()
    }
    const userAttr = new UserAttributionService()
    userAttr.saveUserAttribution(params)
  }
  const signupAttr = params?.transaction_id

  const redirectToAuth = async () => {
    const platform = getUserPlatform()
    const ipGeolocationResponse = await getGeoData()

    const userGeoInfo = {
      ip: ipGeolocationResponse.data.ip,
      country: ipGeolocationResponse.data.country_code2,
    }

    generateBaseAuthLink(
      {
        country: userGeoInfo.country,
        platform: platform,
        signUpIPCountry: userGeoInfo.country,
        IPAddress: userGeoInfo.ip,
        referralId: null,
      },
      signupAttr
    ).then((authLink) => (window.location.href = authLink))
  }
  redirectToAuth()
}

export default GrabInformation
