import { Typography } from '@mui/material'

import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '../constants'

const AuthorizationTerms = () => {
  return (
    <Typography variant="body2" sx={styles.content}>
      By signing up, you confirm that you agree to our{' '}
      <a href={TERMS_OF_SERVICE_LINK}>Terms of Service</a> and{' '}
      <a href={PRIVACY_POLICY_LINK}>Privacy Policy</a>. Playback Rewards is only available to people
      ages 18 and older. You authorize and direct us to utilize your Linked Accounts to deliver the
      Playback Rewards Services to you.
    </Typography>
  )
}

const styles = {
  content: {
    padding: '8px 7px 8px 12px',
    borderRadius: '16px',
    gap: '10px',
    background: '#fff7ed',
    textAlign: 'left',
    color: '#3f3f46',

    a: {
      textDecoration: 'underline',
      color: '#fb923c',
    },
  },
}

export default AuthorizationTerms
