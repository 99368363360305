import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@mui/material'

import OfferCard from '../../../components/OfferCard'
import AuthContext from '../../../context/AuthContext'
import {
  ONBOARDING_STEP,
  ANALYTICS_EVENT,
  ANALYTICS_FEATURED_CARD_TRACKING_TYPE,
  ANALYTICS_EMPTY_VALUE,
} from '../../../constants'
import { sendGA4Event } from '../../../utils/utils'

const ReferFriend = ({ referralOffer }) => {
  const { user } = useContext(AuthContext)

  const navigate = useNavigate()

  const REFER_FRIEND_OFFER = {
    featuredGraphic: '/images/referFriendCard.png',
    gameName: 'Refer-A-Friend',
    offerQuality: referralOffer?.offerQuality,
    endTimestamp: referralOffer?.endTime,
    activatedOffer: true,
    valueStatement: referralOffer?.valueStatement,
    showExpiration: referralOffer?.showExpiration,
    isReferral: true,
  }

  if (!referralOffer && user.onboardingStep !== ONBOARDING_STEP.COMPLETE) {
    return <></>
  }

  const handleClick = () => {
    sendGA4Event(ANALYTICS_EVENT.FEATURED_CARD_TRACKING, {
      event_type: 'view',
      featured_card_type: ANALYTICS_FEATURED_CARD_TRACKING_TYPE.REFERRAL_OFFER,
      offer_id: referralOffer.id,
      unified_app_id: ANALYTICS_EMPTY_VALUE,
      placement_number: 1,
    })

    navigate('/refer-a-friend')
  }

  return (
    <div className="gamesListFeaturedGameCardInner">
      <Card sx={styles.card} onClick={handleClick}>
        <OfferCard game={REFER_FRIEND_OFFER} specialOffer={true} />
      </Card>
    </div>
  )
}

const styles = {
  card: {
    borderRadius: '12px',
    cursor: 'pointer',
    height: '175px',
    width: 'unset',
    position: 'relative',
    boxShadow: '0px 12px 16px -4px #0014311F',
  },
}

export default ReferFriend
