import { DEVICE_INFO } from './deviceInfo'
import { GEO_DATA } from './geoData'
import { GIFT_CARD } from './giftCard'
import { GIFT_CARD_ORDERS } from './giftCardOrders'

const QueryKeys = {
  ...DEVICE_INFO,
  ...GEO_DATA,
  ...GIFT_CARD,
  ...GIFT_CARD_ORDERS,
}

export default QueryKeys
