import React, { useCallback, useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/system'
import { Button, Container, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

//hooks
import useQueryGeoData from 'hooks/useQueryGeoData'
import useQueryIsIPAddressBanned from '../../hooks/useQueryIsIPAddressBanned'
import useSegmentConfig from '../../hooks/useSegmentConfig'
//others
import BottomButton from '../../components/common/BottomButton'
import { getCurrentReferralOffer, getUserByReferralId } from '../../services/referalService'
import { generateAuthLink } from '../../services/authService'
import { formatNumber, handleError, handleRNLogin } from '../../utils/utils'
import { REFERRAL_LANDING_THEME } from '../../utils/muiThemes'
import {
  LOCAL_STORAGE,
  PRIVACY_POLICY_LINK,
  SEARCH_PARAMS,
  SUPPORT_MAIL,
  TERMS_OF_SERVICE_LINK,
} from '../../constants'
import AuthorizationTerms from '../../components/AuthorizationTerms'
import { NotFound } from '../index'

import './index.css'

const ReferralLanding = () => {
  const { isBanned } = useQueryIsIPAddressBanned()

  const [size, setSize] = useState([{ width: 0, height: 0 }])
  const [referralOffer, setReferralOffer] = useState({})
  const [referrerUser, setReferrerUser] = useState({})
  const [isInvalidData, setInvalidData] = useState(false)

  const navigate = useNavigate()

  const updateSize = () => setSize({ width: window.innerWidth, height: window.innerHeight })

  let { referralId } = useParams()

  const getOfferData = async () => {
    const utmParameters = new URLSearchParams(window.location.search)

    referralId = referralId || utmParameters.get('referrer_id')
    sessionStorage.setItem(LOCAL_STORAGE.REFERRAL_ID, referralId)

    updateSize()

    await Promise.all([getCurrentReferralOffer(), getUserByReferralId(referralId)])
      .then(([referralOffer, referrerUser]) => {
        setReferralOffer(referralOffer)
        setReferrerUser(referrerUser)
      })
      .catch(() => setInvalidData(true))
  }

  const { signupAttr, isLoading } = useSegmentConfig(getOfferData)

  //Query hooks
  const { geoData } = useQueryGeoData()

  useEffect(() => {
    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const formattedCoins = formatNumber(referralOffer?.referredSignupCoins || 0)
  const formattedPiggy = formatNumber(referralOffer?.referredBonusPiggyBanks || 0)

  const handleSignUp = useCallback(async () => {
    if (isBanned) {
      navigate('/blocked')
      return
    }

    if (window.ReactNativeWebView) {
      return handleRNLogin(geoData)
    }

    const authLink = await generateAuthLink('auth/user', [], signupAttr, null).catch(handleError)
    window.location.href = authLink
  }, [geoData, isBanned])

  const navigateToAuthPage = (dontHave = false) =>
    navigate(`/auth${dontHave && '?' + SEARCH_PARAMS.DONT_HAVE + '=1'}`)

  const HOW_IT_WORKS_ITEMS = [
    {
      title: 'Automatically share purchases',
      text: 'Playback instantly rewards you for your purchases each time a new receipt is detected in your email.',
    },
    {
      title: 'Earn points with every purchase',
      text: 'You earn points with every apple app store or google play store purchase made in a mobile game. No exceptions!',
    },
    {
      title: 'Turn points into gift cards',
      text: 'Making a purchase or two in a new game is enough to redeem your points for gift cards to Amazon, Apple App Store, and more.',
    },
  ]

  const FOOTER_ITEMS = [
    {
      title: 'Login',
      handleClick: () => navigate('/auth'),
    },
    {
      title: 'Terms of Service',
      onClick: () => {
        window.location.href = TERMS_OF_SERVICE_LINK
      },
    },
    {
      title: 'Privacy Policy',
      onClick: () => {
        window.location.href = PRIVACY_POLICY_LINK
      },
    },
    {
      title: 'FAQ',
      onClick: () => navigate('/faq'),
    },
    {
      value: <a href={`mailto:${SUPPORT_MAIL}`}>Contact Us</a>,
    },
  ]

  if (!isLoading && (isInvalidData || !referralOffer || !referrerUser)) {
    return <NotFound />
  }

  return (
    <ThemeProvider theme={REFERRAL_LANDING_THEME}>
      <div className={`referralLandingInner ${isLoading ? 'blured' : ''}`}>
        <div className="referralLandingHeader">
          <img src="/images/playbackLogo.png" />
        </div>
        <div className="referralLandingEarn">
          <Container maxWidth="sm" sx={styles.earnContainer}>
            <Typography variant="title" sx={styles.earnTitle}>
              Earn up to <strong>${referralOffer?.usdEquivalent}</strong> bonus today!
            </Typography>
            <Typography variant="body1" sx={styles.earnText}>
              {referrerUser?.firstName} has invited you to enjoy the perks of Playback Rewards!
            </Typography>
          </Container>
        </div>
        <div className="referralLandingJoin">
          <Container maxWidth="sm" sx={styles.joinContainer}>
            <Typography variant="title" sx={styles.joinTitle}>
              Join Today
            </Typography>
            <div>
              <img src="/images/continueWithGoogle.png" onClick={handleSignUp} />
              <Button sx={styles.joinDontHaveAccount}>
                <Typography
                  variant="body2"
                  sx={styles.joinDontHaveAccountText}
                  component="span"
                  onClick={() => navigateToAuthPage(true)}
                >
                  don't have a gmail account?
                </Typography>
              </Button>
            </div>
            <AuthorizationTerms />
          </Container>
        </div>
        <div
          className="referralLandingHow"
          style={{
            backgroundImage: 'url(/images/landingHowBackground.png)',
          }}
        >
          <Container maxWidth="lg" sx={styles.howContainer}>
            <Typography variant="title">How it works</Typography>
            <div className="referralLandingHowItems">
              {HOW_IT_WORKS_ITEMS.map(({ title, text }, key) => (
                <div key={title} className="referralLandingHowItem">
                  <div className="referralLandingHowItemNumber">
                    <Typography variant="body1" sx={styles.howItemNumber}>
                      {key + 1}
                    </Typography>
                  </div>
                  <div className="referralLandingHowItemText">
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body2">{text}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </div>
        <div className="referralLandingBonuses">
          <Container maxWidth="sm" sx={styles.bonusesContainer}>
            <Typography variant="title">Referral Bonuses</Typography>
            <Typography variant="body2" sx={styles.bonusesText}>
              Since <strong>{referrerUser?.firstName}</strong> referred you to Playback Rewards, you
              can earn bonuses that go towards gift cards.
            </Typography>
            <div className="referralLandingBonusesFormula">
              <div className="referralLandingBonusesInputInner">
                <div className="referralLandingBonusesInputWrap coin">
                  <div className="referralLandingBonusesInput coin">
                    <Typography variant="title" sx={styles.value} className="priceText coin">
                      {formattedCoins}
                    </Typography>
                    <img src="/images/coin.png" className="referralLandingBonusesInputImage" />
                  </div>
                </div>
                <Typography variant="body2">
                  Earn <strong>{formattedCoins} coins</strong> for just signing up
                </Typography>
              </div>
              <Typography variant="h3" sx={styles.bonusesPlus}>
                +
              </Typography>
              <div className="referralLandingBonusesInputInner">
                <div className="referralLandingBonusesInputWrap pig">
                  <div className="referralLandingBonusesInput pig">
                    <Typography variant="title" sx={styles.value} className="priceText pig">
                      {formattedPiggy}
                    </Typography>
                    <img src="/images/pig.png" className="referralLandingBonusesInputImage" />
                  </div>
                </div>
                <Typography variant="body2">
                  Earn <strong>2X</strong> Piggy Banks (max of <strong>{formattedPiggy}</strong>)
                  with each purchase.
                </Typography>
              </div>
            </div>
            <Typography variant="h3" sx={styles.bonusesEquals(size.width <= 640)}>
              =
            </Typography>
            <Typography variant="title" sx={styles.bonusesResult}>
              ${referralOffer?.usdEquivalent} USD Value
            </Typography>
            <BottomButton style={styles.bonusesButton} handleClick={navigateToAuthPage}>
              Get Started - It’s Free
            </BottomButton>
          </Container>
        </div>
        <div className="referralLandingFooter">
          <img src="/images/playbackLogo.png" />
          <div className="referralLandingFooterLinks">
            {FOOTER_ITEMS.map(({ title, onClick, value }, key) => (
              <Typography
                variant="footer"
                sx={styles.footerLink}
                key={title + key}
                onClick={onClick}
              >
                {value || title}
              </Typography>
            ))}
          </div>
          <Typography variant="footer">Playback Rewards Inc © 2023</Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}

const styles = {
  earnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    paddingLeft: '50px',
    paddingRight: '50px',
    '@media(max-width: 640px)': {
      gap: '45px',
    },
    '@media(min-width: 600px)': {
      paddingLeft: '50px',
      paddingRight: '50px',
      maxWidth: '587px',
    },
  },
  earnTitle: {
    textTransform: 'uppercase',
    color: '#fff',
  },
  earnText: {
    color: '#fff',
  },
  joinTitle: {
    color: '#FFA703',
  },
  joinContainer: {
    maxWidth: '382px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingRight: '32px',
    paddingLeft: '32px',
    img: {
      maxWidth: '331px',
      width: '100%',
      margin: 'auto',
      cursor: 'pointer',
      '@media(max-width: 640px)': {
        padding: '0 18px',
      },
    },
    '@media (min-width: 600px)': {
      maxWidth: '382px',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  joinDontHaveAccount: {
    padding: '0',
    borderRadius: '100px',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    textDecorationColor: '#000000AB',
    maxWidth: '331px',
    width: '100%',
    margin: 'auto',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#000000AB',
    },
  },
  joinDontHaveAccountText: {
    color: '#000000AB',
    textTransform: 'lowercase',
  },
  howContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '71px',
    '@media (max-width: 640px)': {
      gap: '29px',
    },
  },
  howItemNumber: {
    margin: 'auto',
    color: '#fff',
    fontWeight: 700,
    height: '100%',
    '@media (max-width: 640px)': {
      lineHeight: '32px',
    },
  },
  bonusesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '19px',
  },
  bonusesText: {
    maxWidth: '296px',
    margin: 'auto',
  },
  bonusesPlus: {
    margin: '10px -30px 0 0',
    width: '39px',
    '@media (max-width: 640px)': {
      margin: 'auto 0',
    },
  },
  bonusesEquals: (visible) => ({
    margin: 'auto 0',
    ...(!visible && { display: 'none' }),
  }),
  bonusesResult: {
    background: 'linear-gradient(180deg, #FFC52D 0%, #FF6492 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginTop: '20px',
    '@media (max-width: 640px)': {
      marginTop: 0,
    },
  },
  bonusesButton: {
    padding: '14px 57px',
    width: 'max-content',
    margin: 'auto',
    textTransform: 'uppercase',
    marginTop: '29px',
    boxShadow: 'unset',
    background: '#FF8210',
  },
  footerLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    a: {
      color: '#fff',
      fontSize: '16px',
    },
  },
  value: {
    fontWeight: 700,
  },
}

export default ReferralLanding
