import React, { useEffect, useRef, useState } from 'react'

import { getCoordinates, moveToHead } from '../../utils/animations'

import './index.css'

const COIN_ANIMATION_DELAY = 50

const MovingImage = ({
  div,
  src,
  index,
  headerDiv,
  footerDiv,
  startCoordinates = null,
  endCoordinates,
  endHeight,
  animationDuration,
}) => {
  const movingImageRef = useRef(null)
  const [coordinates, setCoordinates] = useState(startCoordinates || { x: 0, y: 0 })

  useEffect(() => {
    setTimeout(() => {
      let coordinates = startCoordinates

      if (!coordinates) {
        if (footerDiv) {
          const footerCoordinates = getCoordinates(footerDiv)
          const headerCoordinates = getCoordinates(headerDiv)

          coordinates = {
            y: footerCoordinates.y,
            x: headerCoordinates.x,
          }

          setCoordinates(coordinates)
        } else {
          coordinates = getCoordinates(div, setCoordinates)
        }
      }

      moveToHead(
        movingImageRef,
        headerDiv,
        coordinates,
        endCoordinates,
        endHeight,
        animationDuration
      )
    }, index * COIN_ANIMATION_DELAY)
  }, [])

  return (
    <img
      src={src}
      className="movingImage"
      ref={movingImageRef}
      style={{ top: coordinates.y, left: coordinates.x }}
    />
  )
}

MovingImage.propTypes = {}

export default MovingImage
