import React from 'react'

import './index.css'
import { Typography } from '@mui/material'

const NoItems = () => {
  return (
    <div className="questsListNoItemsInner">
      <div className="questsListNoItems">
        <Typography variant="h4" sx={styles.text}>
          All Quests Complete!
        </Typography>
        <img src="/images/activateOfferClaim.png" />
        <Typography variant="h4" sx={styles.text}>
          You’re a gaming god! While you wait for more Quests, pick up a new game to keep earning!
        </Typography>
      </div>
    </div>
  )
}

const styles = {
  text: {
    fontWeight: 700,
  },
}

export default NoItems
