import { DialogContent, Typography } from '@mui/material'
import React from 'react'

/**
 * A component for displaying "Unlocking Rewards" modal content
 *
 * @component
 * @returns {ReactNode} A React element that renders the information about and of blocking
 */
const UnlockingRewardsModalContent = () => {
  return (
    <DialogContent sx={styles.inner} className="gameDetailsSpecialOfferModal">
      <Typography variant="h4" sx={styles.text}>
        Some offers require validation from our game partners to unlock rewards.
      </Typography>
      <Typography variant="h4" sx={styles.text}>
        To unlock rewards, you must:
        <ul>
          <li>
            <span>Install the game by tapping the “Try Game” button</span>
          </li>
          <li>
            <span>Have never played the game before</span>
          </li>
          <li>
            <span>Open the game on your mobile device</span>
          </li>
          <li>
            <span>On iOS, choose “Allow Tracking” when prompted within the game</span>
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" sx={styles.text}>
        If you have already installed and booted the game and your rewards are not unlocked, then
        the game partner has determined that you are not qualified to earn rewards for the game.
        It’s ok, just try a different game instead!
      </Typography>
    </DialogContent>
  )
}

const styles = {
  inner: {
    padding: '0px 20px',
  },
  text: {
    fontWeight: 500,
    fontFamily: 'var(--poppins-font)',
    color: '#0F172A',
    li: {
      fontWeight: 500,
      span: {
        position: 'relative',
        left: '-5px',
      },
    },
    ul: {
      margin: 0,
      padding: '0 25px',
    },
  },
}

export default UnlockingRewardsModalContent
