import React from 'react'
import { DialogContent, Typography } from '@mui/material'

const DownloadConfirmationModalContent = ({ isPlaytime, minutes }) => {
  return (
    <DialogContent>
      <Typography variant="body2" align="center">
        {isPlaytime ? (
          <>
            Install & <strong>play the game for {minutes} minutes.</strong>
            <br />
            <br />
            <strong>Check back in after playing to claim your rewards!</strong>
          </>
        ) : (
          <>
            <strong>Play the game at least 5 minutes.</strong>
            <br />
            <br />
            <strong>You’ll get an email</strong> when your rewards are ready to be claimed!
          </>
        )}
      </Typography>
    </DialogContent>
  )
}

export default DownloadConfirmationModalContent
