/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

//constants
import QueryKeys from 'constants/queryKeys'
//contexts
import AuthContext from 'context/AuthContext'
//services
import { getDeviceInfo } from 'services/deviceInfo'
//types
import { DeviceInfo } from 'types'

const useQueryDeviceInfo = () => {
  const { user } = useContext(AuthContext)
  const userId = (user as any)?.id
  const { data } = useQuery<AxiosResponse<DeviceInfo, Error>>(
    [QueryKeys.GET_DEVICE_INFO, userId],
    () => getDeviceInfo(userId)
  )
  return { deviceInfo: data?.data }
}

export default useQueryDeviceInfo
