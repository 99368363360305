import { gsap } from 'gsap'
import { formatNumber, getHeaderImageDiv } from './utils'

const DIVIDERS = { false: 600, true: 100 }
const TEXT_FLY_PIXELS = 60

export const textFade = (ref) => {
  gsap.to(ref.current, {
    duration: 0.5,
    y: -TEXT_FLY_PIXELS,
    zIndex: 1001,
  })
}

export const counter = async (
  tag,
  minValue,
  maxValue,
  increment = null,
  isPig = false,
  func = null,
  textRef = null
) => {
  const incFracValue = (maxValue - minValue) / DIVIDERS[isPig]

  const incValue = increment ? increment : incFracValue

  const value = minValue + incValue

  if (textRef && !increment) {
    textFade(textRef, (maxValue - minValue) / (incValue * 1000))
  }

  if (
    !increment &&
    tag.parentElement.className.includes('gamesHeaderInput') &&
    !tag.parentElement.className.includes('counting')
  ) {
    tag.parentElement.className += ' counting'
  }

  if (value < maxValue) {
    tag.innerHTML = formatNumber(Math.floor(value))
    await setTimeout(() => counter(tag, value, maxValue, incValue, isPig, func), 1)

    return
  }

  tag.innerHTML = formatNumber(maxValue)
  func && func()
  tag.parentElement.className = tag.parentElement.className.replace(' counting', '')
}

export const moveToHead = (
  ref,
  headerTag,
  coordinates,
  endCoordinates = null,
  endHeight = 0,
  duration = 1
) => {
  const headerImage = headerTag?.getElementsByTagName('img')[0]
  const headerImageRect = headerImage?.getBoundingClientRect()

  gsap.to(ref.current, {
    duration,
    x: (endCoordinates?.x || headerImageRect?.left || 0) - coordinates.x,
    y: (endCoordinates?.y || headerImageRect?.top || 0) - coordinates.y + scrollY,
    height: endHeight || headerImageRect.height,
    zIndex: 9999,
    onComplete() {
      gsap.to(this.targets(), {
        opacity: 0,
        ...(headerTag && {
          onComplete() {
            headerImage.className += ' grow'
            setTimeout(() => {
              headerImage.className = headerImage.className.replace(' grow', '')
            }, 25)
          },
        }),
      })
    },
  })
}

export const getCoordinates = (ref, setFunction = null) => {
  const coordinates = ref.current?.getBoundingClientRect() || ref.getBoundingClientRect()

  const result = {
    x: coordinates?.left,
    y: coordinates?.top + window.scrollY,
  }

  setFunction && setFunction(result)

  return result
}

export const handleHeaderCounter = (isPig = false, addedValue = 0) => {
  const headerDiv = getHeaderImageDiv(isPig)
  if (headerDiv) {
    const text = headerDiv.getElementsByTagName('h4')[0]
    const value = +text.textContent.replace(/,/g, '')
    counter(text, value, value + addedValue, null, isPig)
  }
}

const setCount = (isPig = false, resetValue = 0) => {
  const headerDiv = getHeaderImageDiv(isPig)
  if (headerDiv) {
    const text = headerDiv.getElementsByTagName('h4')[0]
    text.textContent = formatNumber(resetValue)
  }
}

export const setHeaderCount = (piggyBanks, coins) => {
  setTimeout(() => {
    setCount(true, piggyBanks)
    setCount(false, coins)
  }, 100)
}
