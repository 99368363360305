import { useState } from 'react'
import { Slide } from '@mui/material'
import { AUTHORIZATION_STATE } from '../../constants'
import useSegmentConfig from '../../hooks/useSegmentConfig'
import { DontHaveAccountForm } from './components/DontHaveAccountForm'
import Content from './Content'

import './index.css'

const Authorization = () => {
  const [state, setState] = useState(AUTHORIZATION_STATE.LOADED)

  const { isLoading, signupAttr, segmentConfig } = useSegmentConfig()

  if (state === AUTHORIZATION_STATE.DONT_HAVE) {
    return <DontHaveAccountForm state={state} setState={setState} />
  }

  const content = (
    <div className={isLoading ? 'blured' : ''}>
      <Content signUpParams={signupAttr} setState={setState} segmentConfig={segmentConfig} />
    </div>
  )

  return (
    <>
      {state !== AUTHORIZATION_STATE.LOADED ? (
        <Slide
          direction="right"
          in={state === AUTHORIZATION_STATE.CREATE_ACCOUNT}
          mountOnEnter
          unmountOnExit
        >
          {content}
        </Slide>
      ) : (
        content
      )}
    </>
  )
}

export default Authorization
