import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'

import AuthContext from '../../context/AuthContext'
import Layout from '../../components/layout/Games'
import { getGameLibraryByUserId } from '../../services/offersService'
import LoaderContext from '../../context/LoaderContext'
import HistoryContext from '../../context/HistoryContext'
import { handleError } from '../../utils/utils'
import SlideLayout from '../../components/layout/Games/Slide'
import Content from './Content'

import './index.css'

export const PREV_ROUTES = ['/games', '/rewards', '/quests']

const GameLibrary = () => {
  const { user } = useContext(AuthContext)
  const { previousPage } = useContext(HistoryContext)

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { data: gameLibrary, isLoading: loadingGameLibrary } = useQuery(
    'gameLibrary',
    () => getGameLibraryByUserId(user.id),
    { onError: handleError }
  )

  useEffect(() => {
    loadingGameLibrary ? showLoader() : hideLoader()
  }, [loadingGameLibrary])

  return (
    <>
      <Layout isLoading={isLoading} user={user}>
        <SlideLayout
          prevRoutes={PREV_ROUTES}
          direction={previousPage === PREV_ROUTES[1] ? 'right' : 'left'}
          swipeIn={!loadingGameLibrary}
        >
          <Content gameLibrary={gameLibrary} />
        </SlideLayout>
      </Layout>
    </>
  )
}

export default GameLibrary
