import React, { useEffect, useState } from 'react'

import { checkVisible } from '../../../utils/utils'
import { GAME_CARD_OFFER_TYPE } from '../../../constants'
import ReferFriend from './ReferFriend'
import GameCard from './GameCard'

const FeaturedRow = ({ observer, offers }) => {
  const [autoSwipeOfferKey, setAutoSwipeOfferKey] = useState(0)

  const handleScroll = () => {
    let autoSwipeOfferKey = 0

    Array.from(document.getElementsByClassName('offerCardInner featured')).some((div, index) => {
      if (checkVisible(div)) {
        autoSwipeOfferKey = index
        return true
      }

      return false
    })

    setAutoSwipeOfferKey(autoSwipeOfferKey)
  }

  useEffect(() => {
    if (offers.length) {
      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [offers])
  return (
    <>
      {offers?.map((offer, key) =>
        offer.isFeatured ? (
          <ReferFriend key={offer.id} referralOffer={offer} />
        ) : (
          <GameCard
            key={offer.id}
            offer={offer}
            index={key}
            isAutoSwiping={autoSwipeOfferKey == key}
            type={GAME_CARD_OFFER_TYPE.FEATURED}
            observer={observer}
          />
        )
      )}
    </>
  )
}

export default FeaturedRow
