import {
  AgeErrorPage,
  ErrorGmailConnection,
  ConnectGmail,
  CreateAccount,
  EligibilityGate,
  GameLibrary,
  GamesList,
  NeedVerification,
  OrderSuccess,
  GiftCardsDetails,
  GiftCardsList,
  UserSettings,
  VerificationResult,
  GameDetails,
  AccountBalance,
  FAQ,
  BlockedAccount,
  HowItWorks,
  Authorization,
  ReferFriend,
  NotAvailable,
  SecondEmailConnect,
  ReferralLanding,
  CreateAccountSingleStep,
  GrabInformation,
  AuthErrorPage,
  SelectCountry,
  QuestsList,
  CountryErrorPage,
  CameraNotAllowed,
  AppUsagePermission,
  Admin,
  Campaigns,
  OfferTools,
  AcceptTOSPrompt,
} from './pages'
import LogoutPage from './pages/LogoutPage/LogoutPage'
import GmailOauth from './pages/Oauth/Oauth'
import OauthTwoStepCreate from './pages/Oauth/OauthTwoStepCreate'

export const NOT_AUTHORIZED_ROUTES = [
  { path: '/initiate-auth', Component: GrabInformation },
  { path: '/auth/v2', Component: CreateAccountSingleStep },
  { path: '/auth', Component: CreateAccount },
  { path: '/oauth', Component: GmailOauth },
  { path: '/two-step-create-account', Component: OauthTwoStepCreate },
  { path: '/sign-up', Component: Authorization },
  { path: '/age', Component: EligibilityGate },
  { path: '/age-error', Component: AgeErrorPage },
  { path: '/auth-error', Component: AuthErrorPage },
  { path: '/country-error', Component: CountryErrorPage },
  { path: '/connect/gmail', Component: ConnectGmail },
  { path: '/connect/error', Component: ErrorGmailConnection },
  { path: '/referral/:referralId', Component: ReferralLanding },
  { path: '/referral', Component: ReferralLanding },
  { path: '/blocked', Component: BlockedAccount },
]

export const PUBLIC_ROUTES = [
  { path: '/faq', Component: FAQ },
  { path: '/not-available', Component: NotAvailable },
  { path: '/logout', Component: LogoutPage },
]

export const PROTECTED_ROUTES = [
  { path: '/rewards', Component: GiftCardsList },
  { path: '/select-country', Component: SelectCountry },
  { path: '/rewards/:giftCardId/:denomination', Component: GiftCardsDetails },
  { path: '/games', Component: GamesList },
  { path: '/games/:unifiedAppId', Component: GameDetails },
  { path: '/quests', Component: QuestsList },
  { path: '/library', Component: GameLibrary },
  { path: '/verification', Component: NeedVerification },
  { path: '/verification/result', Component: VerificationResult },
  { path: '/verification/camera-access', Component: CameraNotAllowed },
  { path: '/order/:orderId/success', Component: OrderSuccess },
  { path: '/profile/settings', Component: UserSettings },
  { path: '/profile/balance', Component: AccountBalance },
  { path: '/how-it-works', Component: HowItWorks },
  { path: '/refer-a-friend', Component: ReferFriend },
  { path: '/second-email/:dataId', Component: SecondEmailConnect },
  { path: '/app-usage-permission', Component: AppUsagePermission },
  { path: '/accept-tos', Component: AcceptTOSPrompt },
]

export const ADMIN_ROUTES = [
  { path: '/admin', Component: Admin },
  { path: '/admin/campaigns', Component: Campaigns },
  { path: '/admin/offer-tools', Component: OfferTools },
]
