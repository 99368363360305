import React from 'react'

import './index.css'

const FullPageLoader = ({ isVisible }) => {
  return (
    <div className={`loaderContainer ${!isVisible ? 'hidden' : ''}`}>
      <div className="loaderInner">
        <div
          className="loaderSpinner"
          style={{ backgroundImage: 'url("/images/coinLoader.png")' }}
        />
      </div>
    </div>
  )
}

export default FullPageLoader
