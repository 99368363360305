import React from 'react'
import { Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import PageInner from '../PageInner'
import BottomButton from '../../common/BottomButton'

import './index.css'

const ErrorLayout = ({ title, subtitle, text }) => {
  const navigate = useNavigate()
  const goHome = () => navigate('/')
  return (
    <PageInner>
      <Container sx={styles.container} maxWidth="sm">
        <div className="errorLayoutInner">
          <img src="/images/playbackLogo.png" className="errorLayoutLogo" />
          <div className="errorLayoutContent">
            <div className="errorLayoutContentTitle">{title}</div>
            <Typography variant="h1">{subtitle}</Typography>
            <Typography variant="inherit" sx={styles.text}>
              {text}
            </Typography>
          </div>
          <BottomButton handleClick={goHome}>Back to Home</BottomButton>
        </div>
      </Container>
    </PageInner>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  text: {
    fontWeight: 400,
  },
}

export default ErrorLayout
