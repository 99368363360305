import { useQueryClient } from 'react-query'
import { useEffect } from 'react'

import { INSTALL_VALIDATING_MINUTES, MILLISECONDS_IN_MINUTE } from '../constants'

/**
 * Hook for invalidating query on validation finished
 *
 * @param {boolean} isValidating
 * @param {object} offer
 * @param {array} invalidateQueryKeys
 * @param {function} validatedFunction
 * @returns {void}
 */
const useOfferInstallValidation = (
  isValidating,
  offer,
  invalidateQueryKeys,
  validatedFunction = null
) => {
  const queryClient = useQueryClient()

  /**
   * Function for invalidating query on validation finished
   *
   * @param {object} offer
   * @returns {void}
   */
  const handleValidating = (offer) => {
    if (!offer) {
      return
    }
    const { activationTimeStamp, mmpAttributionRequiredForRewards, isInstallAttributed } = offer

    const isValidationComplete = mmpAttributionRequiredForRewards
      ? isInstallAttributed
      : (new Date().getTime() -
          (+process.env.REACT_APP_OFFER_INSTALL_VALIDATING_TIME ||
            INSTALL_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE)) /
          1000 >=
        activationTimeStamp?._seconds

    if (isValidationComplete && isValidating) {
      if (invalidateQueryKeys.length) {
        queryClient.invalidateQueries({
          queryKey: invalidateQueryKeys,
        })
      }

      validatedFunction && validatedFunction()
    }
  }

  useEffect(() => {
    if (isValidating && offer) {
      handleValidating(offer)
      const interval = setInterval(() => handleValidating(offer), 1000)

      return () => clearInterval(interval)
    }
  }, [isValidating, offer])
}

export default useOfferInstallValidation
