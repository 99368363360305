import React, { useContext, useEffect } from 'react'
import { Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'
import { handleStripeIdentity, sendRNMessage } from '../utils/utils'
import {
  MAX_ATTEMPTS_AMOUNT,
  REACT_NATIVE_STATUS,
  TERMS_OF_SERVICE_LINK,
  VERIFICATION_STATUS,
} from '../constants'
import AuthContext from '../context/AuthContext'

const NeedVerification = () => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const { attempts, status } = user.identityVerification

    if (status === VERIFICATION_STATUS.VERIFIED) {
      navigate('/rewards')
    }
    if (
      attempts >= MAX_ATTEMPTS_AMOUNT ||
      status === VERIFICATION_STATUS.PLAYBACK_DUPLICATE_REJECTED
    ) {
      navigate('/verification/result')
    }
  }, [])

  const handleClick = async () => {
    if (window.ReactNativeWebView) {
      return sendRNMessage({ status: REACT_NATIVE_STATUS.CAMERA_PERMISSION })
    }

    handleStripeIdentity(user.id, navigate)
  }

  const handleBackArrowClick = () => {
    navigate('/rewards')
  }

  return (
    <MessageLayout
      title="Verify Your Identity"
      button={{
        text: 'Continue',
        handleClick,
      }}
      handleBackArrowClick={handleBackArrowClick}
    >
      <Typography variant="body2">
        You are required to verify your account with a valid ID in order to redeem rewards. This is
        necessary to protect the Playback system from abuse and to ensure users are compliant with
        our <a href={TERMS_OF_SERVICE_LINK}>Terms of Service</a>.
      </Typography>
      <Typography variant="body2">
        <b>
          We only allow one account per person, and the ID used to verify the account must be that
          of the account holder.
        </b>{' '}
        Playback is only available to those 18 years and older who are based in eligible
        territories.
      </Typography>
      <Typography variant="body2">
        The next couple of screens will require you to take a picture of your ID card and a selfie.
        This information is only used to verify your account and for fraud prevention purposes.
      </Typography>
    </MessageLayout>
  )
}

export default NeedVerification
