/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

//types
import { TOSPromptConfig } from 'types'

const TOSPromptConfigContext = React.createContext<{
  TOSPromptConfig: TOSPromptConfig | null
  setTOSPromptConfig: React.Dispatch<React.SetStateAction<TOSPromptConfig | null>>
}>({
  TOSPromptConfig: null,
  setTOSPromptConfig: () => {},
})

export default TOSPromptConfigContext
