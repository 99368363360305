import { useEffect, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import LoaderContext from '../../context/LoaderContext'
import PageInner from '../../components/layout/PageInner'

const LogoutPage = () => {
  const authContext = useContext(AuthContext)
  const { showLoader, hideLoader } = useContext(LoaderContext)

  useEffect(() => {
    showLoader()
    authContext.logout().finally(hideLoader)
  }, [])

  return <PageInner />
}

export default LogoutPage
