import { Slide } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import GoogleAuthButton from '../../components/auth/GoogleAuthButton'

import AuthLayout from '../../components/layout/Auth'
import LoaderContext from '../../context/LoaderContext'
import { getRegistrationTitle, handleError } from '../../utils/utils'
import { generateAuthLinkPostClientRedirect } from '../../services/authService'
import ConnectGmailContent from './Content'

import './index.css'

const ConnectGmail = () => {
  const [searchParams] = useSearchParams()
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const [user, setUser] = useState()

  const accessToken = searchParams.get('accessToken')
  const signupAttrParam = searchParams.get('signupAttr')

  const setData = async () => setUser(decodeToken(accessToken))

  useEffect(() => {
    showLoader()
    setData().catch(handleError).finally(hideLoader)
  }, [])

  const handleConnectGmail = (userId, link, signupAttrParam) => {
    generateAuthLinkPostClientRedirect(link, ['email'], signupAttrParam, userId)
      .then((authLink) => {
        if (link === 'auth/gmail') {
          localStorage.removeItem('jwtToken')
        }
        window.location.href = authLink
      })
      .catch(handleError)
  }

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={isLoading ? 'blured' : ''}>
          <AuthLayout title={getRegistrationTitle(user)} content={<ConnectGmailContent />}>
            <GoogleAuthButton
              handleClick={() =>
                handleConnectGmail(null, 'two-step-create-account', signupAttrParam)
              }
              text="Connect with Google"
              style={{ marginBottom: '20px' }}
            />
          </AuthLayout>
        </div>
      </Slide>
    </>
  )
}

export default ConnectGmail
