import React, { useContext } from 'react'
import { Typography } from '@mui/material'

import AuthContext from '../context/AuthContext'
import { USER_PLATFORM } from '../constants'
import { RatingStar } from '../utils/icons'

const Rating = ({ offer, isSmall = false }) => {
  const { user } = useContext(AuthContext)

  const getRating = {
    [USER_PLATFORM.IOS]: {
      value: offer.appStoreRating,
      logo: '/images/appStoreLogo.png',
    },
    [USER_PLATFORM.ANDROID]: {
      value: offer.androidRating,
      logo: '/images/googlePlayLogo.png',
    },
  }

  const rating = getRating[user.platform]

  if (rating && rating.value) {
    return (
      <Typography variant={isSmall ? 'small' : 'h3'} sx={styles.rate(isSmall)}>
        <img src={rating.logo} /> {rating.value.toFixed(1)}{' '}
        {isSmall ? (
          <RatingStar />
        ) : (
          <img className="gameDetailsGameInfoRateStar" src="/images/rateStar.png" />
        )}
      </Typography>
    )
  }

  return null
}

const styles = {
  rate: (small) => ({
    margin: 'auto 0',
    fontWeight: 400,
    color: small ? '#FFFFFF' : '#404040',
  }),
}

Rating.propTypes = {}

export default Rating
