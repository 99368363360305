import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormGroup, Slide, Typography } from '@mui/material'
import { Toaster } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import Select from '../../components/select'
import { AVAILABLE_COUNTRIES, LOCAL_STORAGE } from '../../constants'
import AuthLayout from '../../components/layout/Auth'
import BottomButton from '../../components/common/BottomButton'
import LoaderContext from '../../context/LoaderContext'
import { updateUserCountry } from '../../services/authService'
import { handleError } from '../../utils/utils'

import './index.css'

const SelectCountry = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const [user, setUser] = useState()

  const accessToken = localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)
    ? localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)
    : searchParams.get('accessToken')

  const { control, handleSubmit } = useForm({ defaultValues: { country: 'United States' } })

  const setData = async () => setUser(decodeToken(accessToken))

  useEffect(() => {
    showLoader()
    setData().catch(handleError).finally(hideLoader)
  }, [])

  const onSubmit = async (values) => {
    await updateUserCountry({ country: values.country, userId: user.id }).catch(handleError)
    navigate('/games')
  }

  return (
    <>
      <Toaster />
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={isLoading ? 'blured' : ''}>
          <AuthLayout
            title="Select your country"
            content={
              <div className="eligibilityGateContent">
                <Typography variant="body1">
                  We are unable to detect your country. Please choose your country from the
                  dropdown.
                </Typography>
                <form className="eligibilityGateForm" onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="country"
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          currentItem={value}
                          options={Object.keys(AVAILABLE_COUNTRIES)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormGroup>
                </form>
                <div className="noteText">
                  <Typography variant="body2">
                    <strong>NOTE:</strong> To redeem gift cards you will need to verify your
                    identity through Stripe and choosing the wrong country is a violation of Terms
                    of Service.
                  </Typography>
                </div>
              </div>
            }
          >
            <BottomButton handleClick={handleSubmit(onSubmit)} style={{ marginBottom: '20px' }}>
              Continue
            </BottomButton>
          </AuthLayout>
        </div>
      </Slide>
    </>
  )
}

export default SelectCountry
