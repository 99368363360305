import React from 'react'
import { useLocation } from 'react-router-dom'

import './index.css'

export const ONBOARDING_STEPS = ['/age', '/connect/gmail']

const OnboardingStepper = () => {
  const location = useLocation()

  return (
    <div className="onboardingStepper">
      {ONBOARDING_STEPS.map((path) => (
        <div
          key={path}
          className={`onboardingStepperItem ${location.pathname === path ? 'active' : ''}`}
        />
      ))}
    </div>
  )
}

export default OnboardingStepper
