import { axiosBackendInstance } from '../axios'
import axiosWebInstance from '../axiosWebInstance'

export const getUserById = async (userId, isLogin = false) => {
  const requestRandom = Math.random()

  return axiosBackendInstance.get(
    `/api/v1/users/${userId}?requestRandom=${requestRandom}&isLogin=${isLogin}`
  )
}

export const getUserByEmail = async (email) => {
  return axiosBackendInstance.get(`/api/v1/users/email/${email}`)
}

export const removeUserAccount = async () => {
  return axiosBackendInstance.delete('/api/v1/users')
}

export const requestUserData = async () => {
  return axiosBackendInstance.get('/api/v1/users/data')
}

export const updateUser = async (userData) => {
  return axiosBackendInstance.put('/api/v1/users', userData)
}

export const getUserBanEndTime = async () => {
  return axiosBackendInstance.get(`/api/v1/users/ban-time`)
}

export const addSecondEmail = async (connectedId) => {
  return axiosBackendInstance.patch(`/api/v1/users/second-email/${connectedId}`)
}

export const getSecondEmails = async () => {
  return axiosBackendInstance.get('/api/v1/users/second-emails')
}

export const deleteSecondEmail = async (email) => {
  return axiosBackendInstance.delete('/api/v1/users/second-emails', { data: { email } })
}

export const sendDeviceData = async (deviceData, userId) => {
  return axiosBackendInstance.post(`/api/v1/users/${userId}/device-info`, deviceData)
}

export const sendEventAnalytic = async (eventData, token) => {
  return axiosBackendInstance.post('/api/v1/users/event-analytic', eventData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const sendUnauthorizedEventAnalytic = async (eventData) => {
  return await axiosBackendInstance.post('/api/v1/users/analytic', eventData)
}

export const initSignUpReminder = async (deviceData) => {
  return axiosBackendInstance.post('/api/v1/users/sign-up-reminder', deviceData)
}

export const sendAppBootTracking = async (params, userId) => {
  return axiosBackendInstance.post(`/api/v1/users/${userId}/app-boot`, params)
}

export const offSignUpReminder = async (deviceData) => {
  return axiosBackendInstance.patch('/api/v1/users/sign-up-reminder', deviceData)
}

export const getDeviceData = async (userId) => {
  return axiosBackendInstance.get(`/api/v1/users/${userId}/device-info`)
}

export const isIPAddressBlocked = async () => {
  return axiosWebInstance.get('/api/v1/users/ip-check')
}
