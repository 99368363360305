import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

//services
import { isIPAddressBlocked } from '../services/userService'
//types
import { IsIPAddressBlockedResponse } from '../types'
//utils
import { handleError } from '../utils/utils'

const useQueryIsIPAddressBanned = () => {
  const { data } = useQuery<AxiosResponse<IsIPAddressBlockedResponse, Error>>(
    'isIPAddressBlocked',
    () => isIPAddressBlocked(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: handleError,
    }
  )

  return { isBanned: data?.data?.isIPAddressBlocked }
}

export default useQueryIsIPAddressBanned
