import axios from 'axios'
import { IpGeoLocationResponse } from '../types'

export const getGeoData = async () => {
  const url = process.env.REACT_APP_IPGEOLOCATION_API_KEY
    ? `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`
    : `https://api.ipgeolocation.io/ipgeo`

  return axios.get<IpGeoLocationResponse>(url)
}
