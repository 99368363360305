import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import Dialog from '@mui/material/Dialog'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

//components
import Modal from 'components/modal'
import BottomButton from 'components/common/BottomButton'
//constants
import { CURRENCY_SYMBOL, ERROR_TEXT, LOCAL_STORAGE } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//hooks
import useLocalStorage from 'hooks/useLocalStorage'
//services
import { createOrder } from 'services/ordersService'
//types
import { AuthContextType, ConfirmOrderModalProps } from 'types'
//utils
import { handleError, isUserFrozen, isUserOutOfAttempts, isUserVerified } from 'utils/utils'

import './index.css'

/**
 * Dialog window for confirming the purchase of a gift card.
 */
const ConfirmOrderModal = ({
  showModal,
  hideModal,
  showLoader,
  hideLoader,
  giftCard,
  denomination,
  pointsValue,
  piggyBanksValue,
  formattedPointsValue,
  formattedPiggiesValue,
}: ConfirmOrderModalProps) => {
  const { user, updateUserData } = useContext(AuthContext) as AuthContextType
  const navigate = useNavigate()
  const { setItem } = useLocalStorage()

  /**
   * Gift card order confirmation
   * Checks user verification status and sends the request to backend for gift card confirmation according to the results of which it changes the page
   *
   * @function
   * @returns {void}
   */
  const handleConfirm = async () => {
    const isVerified = isUserVerified(user)
    const outOfAttempts = isUserOutOfAttempts(user)
    const isFrozen = isUserFrozen(user)
    hideModal()
    if (isVerified) {
      hideModal()
      showLoader()
      if (isFrozen) {
        hideLoader()
        toast.error(ERROR_TEXT.account_frozen_gift_card, { duration: 10000 })
        return
      }

      return (
        createOrder({ giftCardId: giftCard.id, denomination })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((result: any) => {
            updateUserData(result?.updatedUser)

            result.orderId && navigate(`/order/${result.orderId}/success`)
          })
          .catch(handleError)
          .finally(hideLoader)
      )
    }

    if (outOfAttempts) {
      navigate('/verification/result')
      return
    }

    setItem(LOCAL_STORAGE.GIFT_CARD_ID, `${giftCard.id}`)
    setItem(LOCAL_STORAGE.GIFT_CARD_DENOMINATION, `${denomination}`)
    navigate('/verification')
  }

  if (user && (piggyBanksValue > user.piggyBanks || pointsValue > user.points)) {
    return (
      <Modal
        showModal={showModal}
        hideModal={hideModal}
        text="Sorry, looks like you don’t have the requirements for that Gift Card yet. Keep earning!"
      />
    )
  }

  return (
    <Dialog open={showModal} onClose={hideModal} sx={styles.modal} maxWidth="xs">
      <DialogTitle variant="h1" sx={styles.title}>
        Confirm your order
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className="confirmOrderModalText">
          Are you sure you want to purchase the {CURRENCY_SYMBOL[giftCard?.currency]}
          {denomination} {giftCard?.currency} {giftCard?.name} Gift Card for{' '}
          <span className="priceText pig">{formattedPiggiesValue}</span>{' '}
          <img src="/images/pig.png" />
          {' & '}
          <span className="priceText coin">{formattedPointsValue}</span>{' '}
          <img src="/images/coin.png" /> ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <BottomButton handleClick={hideModal} style={styles.closeButton}>
          Close
        </BottomButton>
        <BottomButton handleClick={handleConfirm}>Confirm</BottomButton>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  modal: { '& .MuiDialog-paper': { borderRadius: '16px', margin: '21px' } },
  title: {
    textAlign: 'center',
  },
}

export default ConfirmOrderModal
