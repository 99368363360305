import { axiosBackendInstance } from '../axios'

export const updatePromptViewed = async (userId, uniqueId, showAppUsagePrompt) => {
  return axiosBackendInstance.patch(`/api/v1/appUsage/updatePromptViewed/${userId}/${uniqueId}`, {
    showAppUsagePrompt,
  })
}

export const updateAppUsageAccess = async (userId, uniqueId, appUsageAccess) => {
  return axiosBackendInstance.patch(`/api/v1/appUsage/updateAppUsageAccess/${userId}/${uniqueId}`, {
    appUsageAccess,
  })
}
