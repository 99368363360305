import React from 'react'
import { Calendar } from '../../utils/icons'
import { formatDate } from '../../utils/utils'

const RewardText = ({ claimedDate }) => {
  return (
    <>
      <Calendar />{' '}
      {formatDate(claimedDate, [
        'en-UK',
        {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        },
      ])}
    </>
  )
}

export default RewardText
