import toast, { ToastBar, Toaster } from 'react-hot-toast'
import { Close } from '@mui/icons-material'

const CustomToaster = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <Close className="toasterCloseButton" onClick={() => toast.dismiss(t.id)} />
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default CustomToaster
