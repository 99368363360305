import React, { useEffect, useState } from 'react'

import './ProgressChest.css'
import { PIGGY_BANK_CHEST_MODAL_STATE as MODAL_STATE } from '../../../../../constants'

const ProgressChest = ({ state = MODAL_STATE.START, isVisible = true }) => {
  const [isShineVisible, setShineVisible] = useState(false)

  useEffect(() => {
    if (state === MODAL_STATE.PROGRESSION) {
      setTimeout(() => {
        setShineVisible(true)
      }, 250)
    }
  }, [state])

  return (
    <div className={`gamesListPiggyBankChestProgress ${!isVisible ? 'hidden' : ''}`}>
      <img
        src="/images/pigChestShine.png"
        className={`gamesListPiggyBankChestProgressShine ${isShineVisible ? 'visible' : ''}`}
      />
      <img
        src="/images/pigChestClosed.png"
        className={`gamesListPiggyBankChestProgressImage ${
          state === MODAL_STATE.PROGRESSION ? 'progress' : ''
        }`}
      />
    </div>
  )
}

ProgressChest.propTypes = {}

export default ProgressChest
