/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { UserGeoInfo } from 'types'

export const UserGeoDataContext = React.createContext<{
  userGeoInfo: UserGeoInfo | null
  setUserGeoInfo: React.Dispatch<React.SetStateAction<UserGeoInfo | null>>
}>({
  userGeoInfo: null,
  setUserGeoInfo: () => {},
})
