import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Box, Typography } from '@mui/material'

import { BackArrow } from '../../../../utils/icons'

import './index.css'

const DetailsHeader = ({
  title,
  handleHide,
  containerStyles = {},
  handleGoBack = null,
  children,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    handleHide()

    setTimeout(
      handleGoBack ? handleGoBack : () => navigate(location.key !== 'default' ? -1 : '/games'),
      150
    )
  }

  return (
    <Container maxWidth="sm" sx={{ ...styles.container, ...containerStyles }}>
      <Box sx={styles.header}>
        <div className="detailsHeaderTitle">
          <BackArrow onClick={goBack} />
          <Typography variant="inherit" sx={styles.text}>
            {title}
          </Typography>
        </div>
        {children}
      </Box>
    </Container>
  )
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    marginBottom: '24px',
  },
  text: {
    color: '#27272A',
  },
}

DetailsHeader.propTypes = {
  title: PropTypes.string,
  handleHide: PropTypes.func.isRequired,
}

export default DetailsHeader
