import React from 'react'
import { Typography } from '@mui/material'

import { OFFER_QUALITY } from '../../../constants'

import './index.css'

const OfferQualityTag = ({ qualityName, customClassName = '', text, images = true }) => {
  let qualityPigs = []
  const quality = Object.values(OFFER_QUALITY).find((offerQuality, key) => {
    if (offerQuality.name === qualityName) {
      if (images) {
        for (let i = 0; i <= key; i++) {
          qualityPigs.push(<img key={i + qualityName} src="/images/pig.png" />)
        }
      }
      return true
    }

    return false
  })

  return (
    <div
      className={`offerQualityTag ${customClassName} ${
        quality === OFFER_QUALITY.FEATURED ? 'featured' : ''
      }`}
      style={{ background: quality?.color }}
    >
      {text && <Typography variant="body2">{text}</Typography>}
      {!text && images && <div className="offerQualityTagPigs">{qualityPigs}</div>}
    </div>
  )
}

export default OfferQualityTag
