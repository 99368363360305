import React, { useMemo } from 'react'
import { Box, Switch, Typography } from '@mui/material'

//constants
import { LOCAL_STORAGE, REACT_NATIVE_STATUS } from 'constants/index'
//hooks
import useLocalStorage from 'hooks/useLocalStorage'
import { sendRNMessage } from 'utils/utils'

const OfferTools = () => {
  const { getItem, setItem } = useLocalStorage()

  const checked = useMemo(() => getItem(LOCAL_STORAGE.DISABLE_GEO_DATA_API) === 'true', [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem(LOCAL_STORAGE.DISABLE_GEO_DATA_API, event.target.checked)
    if (window.ReactNativeWebView) {
      return sendRNMessage({ status: REACT_NATIVE_STATUS.RELOAD_WEB_VIEW })
    }
    window.location.reload()
  }
  return (
    <Box display="flex" flexDirection="row" alignItems="center" p={2} gap={2}>
      <Typography variant="h4">Disable/Fail GeoData api call</Typography>
      <Switch checked={checked} onChange={handleChange} />
    </Box>
  )
}

export default OfferTools
