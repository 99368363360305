import { Typography } from '@mui/material'
import React from 'react'

import { formatNumber } from '../utils/utils'

import './PointInput.css'

const PointInput = ({
  onClick = null,
  points,
  isPig,
  customInnerClassName = '',
  customClassName = '',
  inputVariant = 'h4',
  inputRef = null,
}) => {
  const formattedPoints = formatNumber(points)
  return (
    <div
      className={`pointInputInner ${customInnerClassName} ${isPig ? 'pig' : 'coin'}`}
      onClick={onClick}
    >
      <div className={`pointInput ${customClassName} ${isPig ? 'pig' : 'coin'}`}>
        <Typography
          variant={inputVariant}
          sx={styles.value}
          className={`priceText ${isPig ? 'pig' : 'coin'}`}
          ref={inputRef}
        >
          {formattedPoints}
        </Typography>
        <img src={`/images/${isPig ? 'pig' : 'coin'}.png`} className="pointInputImage" />
      </div>
    </div>
  )
}

const styles = {
  value: {
    fontWeight: 700,
  },
}

export default PointInput
