import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

//assets
import GamePad from '../../assets/offers/gamepad.png'
import Cart from '../../assets/offers/cart.png'
//others
import { CLAIM_SPECIAL_REWARD_REASON, REWARD_HISTORY_TYPE } from '../../constants'
import { selectReferralRewardTitle } from '../../utils/utils'
import OfferTask from '../offerTask'
import RewardText from './RewardText'

import './index.css'

const RewardHistory = ({ rewards, type = REWARD_HISTORY_TYPE.OFFER }) => {
  const getAdditionalProps = (reward) => {
    switch (reward.reason) {
      case CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME:
        return {
          title: 'Played on Day ' + reward.day,
          icon: <img src={GamePad} />,
        }
      case CLAIM_SPECIAL_REWARD_REASON.PLAYTIME:
        return {
          title:
            reward.offerSubId == 0
              ? `Download & Play ${reward.minutes} min`
              : `Play ${reward.minutes} ${reward.minutes > 1 ? 'minutes' : 'minute'}`,
          icon: <img src={GamePad} />,
        }
      case CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME:
        return {
          title: 'Install Game',
          icon: <img src={GamePad} />,
        }
      case CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT:
        return {
          title: reward.mmpEventTaskTitle,
          icon: <img src={GamePad} />,
        }
      case CLAIM_SPECIAL_REWARD_REASON.PURCHASE:
      case CLAIM_SPECIAL_REWARD_REASON.PURCHASE_AMOUNT:
        return {
          title: reward.description,
          icon: <img src={Cart} />,
        }
      default:
        return null
    }
  }

  const getOfferProps = (reward) => {
    return Object.assign(
      {
        title: (
          <>
            Spent ${reward?.price.toFixed(2)}{' '}
            {reward?.isOfferPurchaseAdjustment && '(Offer purchase adjustment)'}
          </>
        ),
        icon: <img src={Cart} />,
        text: (
          <RewardText
            claimedDate={
              reward.reason === CLAIM_SPECIAL_REWARD_REASON.RECEIPT
                ? reward.orderDate
                : reward.claimedDate
            }
          />
        ),
        coins: reward.earnedCoins,
        piggies: reward.earnedPiggy,
      },
      getAdditionalProps(reward)
    )
  }

  const getQuestsProps = (reward) => ({
    title: reward.title,
    text: <RewardText claimedDate={reward.claimedAt} />,
    coins: reward.coinsReward,
    piggies: reward.piggyReward,
  })

  const getReferFriendProps = (reward) => ({
    title: selectReferralRewardTitle(reward),
    text: <RewardText claimedDate={reward.claimedDate} />,
    coins: reward.earnedCoins,
    piggies: reward.earnedPiggy,
  })

  const CONTENT = {
    [REWARD_HISTORY_TYPE.OFFER]: {
      getProps: getOfferProps,
      title: 'Reward History',
    },
    [REWARD_HISTORY_TYPE.QUESTS]: {
      getProps: getQuestsProps,
      title: 'Quest History',
    },
    [REWARD_HISTORY_TYPE.REFER_FRIEND]: {
      getProps: getReferFriendProps,
      title: 'Reward History',
    },
  }

  const groupedRewards = useMemo(
    () =>
      rewards?.reduce((array, item) => {
        const index = array.findIndex((a) => {
          if (a?.batchIndex !== undefined && item?.batchIndex !== undefined) {
            return a.batchIndex === item.batchIndex
          } else {
            return false
          }
        })
        if (index > -1) {
          array[index].minutes += item.minutes
          array[index].earnedCoins += item.earnedCoins
          array[index].earnedPiggy += item.earnedPiggy
        } else {
          array = [...array, item]
        }
        return array
      }, []),
    [rewards]
  )

  if (!CONTENT[type] || !groupedRewards || groupedRewards.length <= 0) {
    return null
  }

  return (
    <Box className={`gameDetailsRewardHistory ${type}`} sx={styles.inner}>
      <div className="gameDetailsRewardHistoryHeader">
        <Typography variant="h3">{CONTENT[type].title}</Typography>
        {Boolean(CONTENT[type].text) && (
          <Typography variant="body2">{CONTENT[type].text}</Typography>
        )}
      </div>
      {groupedRewards?.map((reward) => (
        <OfferTask key={reward.id} earn={true} {...CONTENT[type].getProps(reward)} />
      ))}
    </Box>
  )
}

const styles = {
  inner: {
    '& h1, h2, h3, h4, h5, p, span': {
      fontFamily: 'var(--poppins-font)',
    },
  },
}

export default RewardHistory
