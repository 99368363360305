import React from 'react'

import useProgressiveImage from '../../../hooks/useProgressiveImage'

import './index.css'

const OfferCardImage = ({ isSmall = true, featuredGraphic, isFirst = true }) => {
  const image = !isSmall && isFirst ? featuredGraphic : useProgressiveImage(featuredGraphic)

  return (
    <>
      <div className="offerCardShadow" />
      {!isSmall && (
        <div
          className={`offerCardContainImage ${image ? 'loaded' : ''}`}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      )}
      <div
        className={`offerCardImage ${image ? 'loaded' : ''}`}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
    </>
  )
}

export default OfferCardImage
