import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'
import { SUPPORT_MAIL } from '../constants'

const AuthErrorPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [title, setTitle] = useState(
    `Something went wrong! If this screen appears more than one time please contact ${SUPPORT_MAIL}`
  )

  useEffect(() => {
    const message = searchParams.get('message')

    setSearchParams('')

    if (!message) {
      return
    }

    setTitle(message)
  }, [])

  const handleBackArrowClick = () => {
    window.location.href = `https://playbackrewards.com/`
  }

  return <MessageLayout title={title} handleBackArrowClick={handleBackArrowClick}></MessageLayout>
}

export default AuthErrorPage
