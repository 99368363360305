import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Slide } from '@mui/material'
import { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import { decodeToken } from 'react-jwt'
import { getGeoData } from '../../services/geoDataService'
//hooks

//others
import LoaderContext from '../../context/LoaderContext'
import AuthLayout from '../../components/layout/Auth'
import BottomButton from '../../components/common/BottomButton'
import { getUserProfileFromToken, handleLogin } from '../../services/authService'
import { getUserByEmail } from '../../services/userService'
import {
  dateWithoutTimezone,
  getRegistrationTitle,
  getUserPlatform,
  handleError,
  initializeTagManager,
  isUserAdult,
  sendGA4Event,
} from '../../utils/utils'
import useLocalStorage from '../../hooks/useLocalStorage'
import { ANALYTICS_EVENT, LOCAL_STORAGE } from '../../constants'
import EligibilityGateContent from './Content'

import './index.css'

const EligibilityGate = () => {
  const [searchParams] = useSearchParams()
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const { getItem, removeItem } = useLocalStorage()

  const [user, setUser] = useState()

  const navigate = useNavigate()

  const accessToken = searchParams.get('accessToken')
  const signupAttrParam = searchParams.get('signupAttr')

  useEffect(() => showLoader(), [])

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      birthDate: null,
      country: null,
      signUpIPCountry: null,
      IPAddress: null,
      platform: '',
    },
  })

  const setData = async () => {
    const ipGeolocationResponse = await getGeoData()

    const userGeoInfo = {
      ip: ipGeolocationResponse.data.ip,
      country: ipGeolocationResponse.data.country_code2,
    }

    const userData = await getUserProfileFromToken(accessToken).catch(handleError)
    if (!sessionStorage.getItem('firstName')) {
      sessionStorage.setItem('firstName', userData?.given_name)
    }

    setUser(userData)

    const existedUser = await getUserByEmail(userData.email).catch(handleError)
    if (existedUser) {
      const formattedDate = new Date(existedUser.birthDate).toISOString().split('T')[0]
      setValue('birthDate', dayjs(formattedDate))
      setValue('country', existedUser.country)
      setValue('platform', existedUser.platform)
    } else {
      setValue('platform', getUserPlatform())

      const country = userGeoInfo.country || ''
      const ip = userGeoInfo.ip || ''
      setValue('signUpIPCountry', country)
      setValue('IPAddress', ip)
      setValue('country', country)
    }
  }

  useEffect(() => {
    showLoader()
    setData().finally(hideLoader)
  }, [])

  const onSubmit = async (values) => {
    const isAdult = isUserAdult(values.birthDate)
    const correctBirthDate = dateWithoutTimezone(values.birthDate)

    if (!isAdult) {
      return navigate('/age-error')
    }
    showLoader()

    await handleLogin({
      token: accessToken,
      birthDate: correctBirthDate,
      country: values.country,
      signUpIPCountry: values.signUpIPCountry,
      platform: values.platform,
      IPAddress: values.IPAddress,
      referrerId: sessionStorage.getItem(LOCAL_STORAGE.REFERRAL_ID) || null,
    })
      .then(async (data) => {
        const token = data?.accessToken
        if (token && getItem(LOCAL_STORAGE.TUTORIAL_BEGIN)) {
          const decodedToken = decodeToken(token)
          removeItem(LOCAL_STORAGE.TUTORIAL_BEGIN)
          initializeTagManager(decodedToken.id)

          sendGA4Event(ANALYTICS_EVENT.TUTORIAL_BEGIN, {}, token)
        }
        const queryString = signupAttrParam
          ? `accessToken=${token}&signupAttr=${signupAttrParam}`
          : `accessToken=${token}`

        navigate(`/connect/gmail?${queryString}`)

        sessionStorage.removeItem(LOCAL_STORAGE.REFERRAL_ID)
      })
      .finally(hideLoader)
  }

  return (
    <>
      <Toaster />
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={isLoading ? 'blured' : ''}>
          <AuthLayout
            title={getRegistrationTitle(user)}
            content={
              <EligibilityGateContent
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
              />
            }
          >
            <BottomButton handleClick={handleSubmit(onSubmit)} style={{ marginBottom: '20px' }}>
              Continue
            </BottomButton>
          </AuthLayout>
        </div>
      </Slide>
    </>
  )
}

export default EligibilityGate
