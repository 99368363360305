import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

//constants
import QueryKeys from 'constants/queryKeys'
//services
import { getAllUserOrders } from 'services/ordersService'
//types
import { UserGiftCardRedemptionResponse } from 'types'
//utils
import { handleError } from 'utils/utils'

const useQueryGetAllUserOrders = (userId: string | undefined) => {
  const { data, isLoading } = useQuery<AxiosResponse<Array<UserGiftCardRedemptionResponse>, Error>>(
    [QueryKeys.GET_ALL_USER_GIFT_CARD_ORDERS, userId],
    () => getAllUserOrders(userId),
    {
      enabled: Boolean(userId),
      onError: handleError,
    }
  )

  return { redemptions: data?.data || [], isFetching: isLoading }
}

export default useQueryGetAllUserOrders
