import { Button, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

import useLoader from '../../hooks/useLoader'
import PageInner from '../../components/layout/PageInner'
import RewardHistory from '../../components/rewardHistory'
import TimerInput from '../../components/timerInput'
import { BackArrow, ReferFriendLink, ReferFriendMail, ReferFriendMessage } from '../../utils/icons'
import { objectEncode, handleError, formatNumber, getPlatform } from '../../utils/utils'
import {
  getCurrentReferralOffer,
  getReferralTrackedRecord,
  getUserReferralId,
  getUserReferralRewards,
} from '../../services/referalService'
import { REFERRAL_SUBDOMAIN, REWARD_HISTORY_TYPE, USER_PLATFORM } from '../../constants'
import CopyLink from './components/CopyLink'
import ShareModal from './components/ShareModal'

import './index.css'

const ReferFriend = () => {
  const [copyDisabled, setCopyDisabled] = useState(false)
  const [referralOffer, setReferralOffer] = useState(null)
  const [referralTracked, setReferralTracked] = useState(null)
  const [referralRewards, setReferralRewards] = useState([])
  const [referralLink, setReferralLink] = useState('')
  const [modal, setModal] = useState(false)

  const { loader, showLoader, hideLoader } = useLoader()
  const navigate = useNavigate()
  const location = useLocation()

  const showModal = () => setModal(true)
  const hideModal = () => setModal(false)

  useEffect(() => {
    showLoader()

    Promise.all([
      getCurrentReferralOffer(),
      getReferralTrackedRecord(),
      getUserReferralRewards('claimed'),
      getUserReferralId(),
    ])
      .then(([referralOffer, referralTracked, referralRewards, referralMapping]) => {
        setReferralOffer(referralOffer)
        setReferralTracked(referralTracked)
        setReferralRewards(referralRewards)
        setReferralLink(
          REFERRAL_SUBDOMAIN[process.env.REACT_APP_NODE_ENV](referralMapping.referralId)
        )
      })
      .catch(handleError)
      .finally(hideLoader)
  }, [])

  const getReferralLinkUrl = (textBefore = true) => {
    return `${
      textBefore ? 'Earn cash back on your game purchases with me at Playback! ' : ''
    }http://${referralLink}`
  }

  const getShareLink = (isSMS = true) => {
    const platform = getPlatform()
    const encodedBody = objectEncode({
      body: getReferralLinkUrl(),
    })

    const smsLink = platform === USER_PLATFORM.ANDROID ? 'sms:?' : 'sms:&'

    return (isSMS ? smsLink : 'mailto:?') + encodedBody
  }

  const handleBackArrowClick = () => navigate(location.key !== 'default' ? -1 : '/games')

  const handleCopy = () => {
    navigator.clipboard.writeText(getReferralLinkUrl(false))
    toast.success('Copied to clipboard')
    setCopyDisabled(true)

    setTimeout(() => setCopyDisabled(false), 2000)
  }

  const handleShare = () =>
    navigator.share({
      text: getReferralLinkUrl(true),
    })

  const calculateEligiblePiggyToEarn = () => {
    return formatNumber(
      parseFloat(
        (
          referralTracked?.referredBonusPiggyBanks - referralTracked?.earnedPiggyBanksByReferred
        ).toFixed(2)
      )
    )
  }

  const LINKS = [
    ...(getPlatform() !== 'unknown'
      ? [
          {
            Icon: ReferFriendMessage,
            href: getShareLink(),
          },
        ]
      : []),
    {
      Icon: ReferFriendMail,
      href: getShareLink(false),
    },
    {
      Icon: ReferFriendLink,
      handleClick: navigator.share ? handleShare : showModal,
    },
  ]

  const SPECIAL_OFFER_ITEMS = [
    <>
      Earn{' '}
      <strong className="priceText coin">{formatNumber(referralOffer?.referrerSignupCoins)}</strong>{' '}
      <img src="/images/coin.png" /> for every friend that signs up.
    </>,
    <>
      Earn a <img src="/images/pig.png" /> for each of the first{' '}
      <strong className="priceText pig">
        {formatNumber(referralOffer?.referrerPiggyBankMatch)}
      </strong>{' '}
      <img src="/images/pig.png" /> your friend earns through purchasing.
    </>,
    <>
      Your friend will get{' '}
      <strong className="priceText coin">{formatNumber(referralOffer?.referredSignupCoins)}</strong>{' '}
      <img src="/images/coin.png" /> when they sign up.
    </>,
    <>
      Your friend will earn a bonus <img src="/images/pig.png" /> for the first{' '}
      <strong className="priceText pig">
        {formatNumber(referralOffer?.referredBonusPiggyBanks)}
      </strong>{' '}
      <img src="/images/pig.png" /> that they earn through purchasing.
    </>,
  ]

  return (
    <>
      {loader}
      <ShareModal
        showModal={modal}
        hideModal={hideModal}
        shareLink={getReferralLinkUrl()}
        handleCopy={handleCopy}
        referralLink={referralLink}
        copyDisabled={copyDisabled}
      />
      <PageInner customClassName={loader ? 'blured' : ''}>
        <Container maxWidth="sm" sx={styles.container}>
          <img src="/images/referFriend.png" className="referFriendImage" />
          <div className="referFriendBackArrow" onClick={handleBackArrowClick}>
            <BackArrow />
          </div>
          <div className="referFriendContent">
            <div className="referFriendContentHeader">
              <div className="referFriendContentHeaderTitle">
                <Typography variant="inherit" sx={styles.title}>
                  Earn points each time you refer a friend!
                </Typography>
                <Typography variant="body1" sx={styles.text}>
                  Copy your link, share it with your friends.
                </Typography>
              </div>
              <div className="referFriendLink">
                <Typography variant="body1" sx={{ ...styles.text, ...styles.linkTitle }}>
                  Your personal link
                </Typography>
                <CopyLink
                  referralLink={referralLink}
                  copyDisabled={copyDisabled}
                  handleCopy={handleCopy}
                />
              </div>
              <div className="referFriendContentLinks">
                <Typography variant="h2" sx={styles.text}>
                  OR
                </Typography>
                <div className="referFriendContentLinksItems">
                  {LINKS.map(({ Icon, handleClick, href }, key) => (
                    <Button
                      key={Icon + key}
                      href={href}
                      onClick={handleClick}
                      className="referFriendContentLinksItem"
                    >
                      <Icon />
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            {!referralOffer ? (
              <div className="referFriendNoOffer">
                <Typography variant="body2">
                  No referral rewards available at this time. Please check back later!
                </Typography>
              </div>
            ) : (
              <>
                <div className="referFriendSpecialOffer">
                  <div className="referFriendSpecialOfferHeader">
                    <Typography variant="inherit">Special Offer</Typography>
                    {referralOffer.showExpiration && (
                      <>
                        <Typography variant="body2">Time remaining on this offer:</Typography>
                        <TimerInput expirationTimestamp={referralOffer.endTime} />
                      </>
                    )}
                  </div>
                  <div className="referFriendSpecialOfferContent">
                    {SPECIAL_OFFER_ITEMS.map((item, key) => (
                      <div key={item + key} className="referFriendSpecialOfferItem">
                        <div className="referFriendSpecialOfferItemNumber">
                          <Typography variant="h4" sx={styles.text}>
                            {key + 1}
                          </Typography>
                        </div>
                        <Typography variant="h4" sx={styles.text}>
                          {item}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {referralTracked && (
              <div className="referFriendRemainingRewards">
                <Typography variant="inherit">Remaining Referral Rewards</Typography>
                <Typography variant="h4" sx={styles.text}>
                  Since you were referred by <strong>{referralTracked?.referrerFullName}</strong>{' '}
                  you get a bonus <img src="/images/pig.png" /> on the next{' '}
                  <strong className="priceText pig">{calculateEligiblePiggyToEarn()}</strong> you{' '}
                  <img src="/images/pig.png" /> earn with a purchase
                </Typography>
                <div className="referFriendRemainingRewardsTime">
                  <Typography variant="h4" sx={styles.text}>
                    Time Remaining:
                  </Typography>
                  <TimerInput expirationTimestamp={referralTracked.expirationTimestamp} />
                </div>
              </div>
            )}
          </div>
          {referralRewards.length > 0 && (
            <RewardHistory
              rewards={referralRewards}
              expirationTimestamp={referralTracked?.expirationTimestamp}
              type={REWARD_HISTORY_TYPE.REFER_FRIEND}
            />
          )}
        </Container>
      </PageInner>
    </>
  )
}

const styles = {
  container: {
    padding: 0,
    position: 'relative',
    '@media(min-width: 600px)': {
      padding: 0,
    },
  },
  title: {
    padding: '0 44px',
  },
  text: {
    fontWeight: 400,
    strong: {
      display: 'inline',
    },
  },
  linkTitle: {
    color: '#71717A',
  },
  shareButton: {
    border: '1px solid #e5e7eb',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(25, 118, 210, 0.04)',
    },
  },
}

export default ReferFriend
