import React from 'react'
import { Alert, AlertTitle, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { AlertNoteIcon } from '../../../../utils/icons'
import { DONT_HAVE_ACCOUNT_TEXT, EMAIL_PATTERN } from '../../../../constants'

export const DontHaveAccountContent = ({ control }) => {
  return (
    <>
      <Alert severity="info" sx={styles.alert} icon={false}>
        <AlertTitle>
          <AlertNoteIcon />
          <Typography variant="h2" sx={styles.alertTitle}>
            Note
          </Typography>
        </AlertTitle>
        <Typography variant="body2" sx={styles.alertText}>
          {DONT_HAVE_ACCOUNT_TEXT}
        </Typography>
      </Alert>
      <Controller
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
          },
          pattern: {
            value: EMAIL_PATTERN,
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Email"
            sx={styles.textField}
            fullWidth
            error={error}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </>
  )
}

const styles = {
  alert: {
    marginTop: '32px',
    textAlign: 'left',
    background: '#FFF7ED',
    borderRadius: '16px',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 500,
    padding: '8px 12px',
    '& .MuiAlertTitle-root': {
      display: 'flex',
      gap: '8px',
      marginTop: 0,
      marginBottom: '10px',
    },
    '& .MuiAlert-message': {
      padding: 0,
    },
  },
  alertTitle: {
    fontWeight: 600,
    color: '#3F3F46',
    margin: 'auto 0',
  },
  alertText: {
    overflow: 'hidden',
    fontWeight: 500,
    color: '#3F3F46',
  },
  textField: {
    marginTop: '12px',
    borderRadius: '4px',
    '& .MuiFormLabel-root': {
      fontWeight: 400,
      color: '#A1A1AA',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E4E4E7',
    },
  },
}

export default DontHaveAccountContent
