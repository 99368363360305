import { DialogContent, Typography } from '@mui/material'

const ValidatingModalContent = ({ install = true }) => {
  return (
    <DialogContent sx={styles.inner} className="gameDetailsSpecialOfferModal">
      {install ? (
        <>
          <Typography variant="h4" sx={styles.text}>
            Some actions require validation from our partner games to be successful.{' '}
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            Our partner games integrate with Playback and they send us validation data after users
            complete certain actions in their games.
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            <strong>Try Game:</strong> Validation for this action requires:
            <ul>
              <li>You have never been a player of the game before.</li>
              <li>Use the “Try Game” button from within Playback using your mobile device.</li>
              <li>Download game from the app store.</li>
              <li>Open the game on your mobile device.</li>
            </ul>
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            For any validation to work, you need to enable advertising tracking on your mobile
            device.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={styles.text}>
            Some actions require validation from our partner games to be successful.
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            Our partner games integrate with Playback and they send us validation data after users
            complete certain actions in their games.
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            <strong>Play Game:</strong> Validation for this action requires:
            <ul>
              <li>Tap the “Play Game” button from within Playback using your mobile device.</li>
              <li>Open the game on your mobile device.</li>
              <li>Play Game for at least 3 minutes.</li>
            </ul>
          </Typography>
          <Typography variant="h4" sx={styles.text}>
            For any validation to work, you need to enable advertising tracking on your mobile
            device.
          </Typography>
        </>
      )}
    </DialogContent>
  )
}

const styles = {
  inner: {
    padding: '0px 20px',
  },
  text: {
    fontWeight: 500,
    fontFamily: 'var(--poppins-font)',
    color: '#0F172A',
    strong: {
      fontWeight: 700,
    },
    li: {
      fontWeight: 500,
    },
    ul: {
      margin: 0,
      padding: '0 20px',
    },
  },
}

export default ValidatingModalContent
