import _ from 'lodash'
import { QUEST_PROGRESS_TYPE } from '../constants'

export const getProgressText = (quest) => {
  if (!quest.progressMeter) {
    return
  }

  const progressType =
    quest.progressMeter.type === QUEST_PROGRESS_TYPE.MINUTES_PLAYED &&
    quest.progressMeter.offersNeededToComplete > 1
      ? QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS
      : quest.progressMeter.type

  switch (progressType) {
    case QUEST_PROGRESS_TYPE.DOLLARS_SPEND:
    case QUEST_PROGRESS_TYPE.PLAYBACK_DOLLARS_SPEND:
    case QUEST_PROGRESS_TYPE.REFERRED_DOLLARS_SPEND:
      return {
        progress: (quest.progressMeter.trackedValue * 100) / quest.progressMeter.value,
        text: `$${quest.progressMeter.trackedValue} / $${quest.progressMeter.value} spent`,
      }
    case QUEST_PROGRESS_TYPE.MINUTES_PLAYED: {
      const targetValue = quest.progressMeter.value

      const trackedValues = Object.values(quest.progressMeter.rawTrackedValue)
      let progressTime = trackedValues.length
        ? Math.floor(_.maxBy(trackedValues, (value) => value.value).value / 60)
        : 0

      //Timer playtime tracking
      if (quest.completedAt) {
        const trackedAtTime = quest.completedAt._seconds * 1000
        const minutesDifference = Math.ceil((trackedAtTime - new Date().getTime()) / (60 * 1000))

        progressTime =
          minutesDifference < 0
            ? quest.progressMeter.value
            : quest.progressMeter.value - minutesDifference
      }

      return {
        progress: (progressTime * 100) / targetValue,
        text: `${progressTime} / ${targetValue} min`,
      }
    }
    case QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS: {
      const completedQuestsAmount = Object.values(quest.progressMeter.rawTrackedValue).filter(
        (trackedValue) =>
          trackedValue.completedAt &&
          new Date(trackedValue.completedAt._seconds * 1000) < new Date()
      ).length

      return {
        progress: (completedQuestsAmount * 100) / quest.progressMeter.offersNeededToComplete,
        text: `${completedQuestsAmount} / ${quest.progressMeter.offersNeededToComplete} games`,
      }
    }
  }
}
