import React from 'react'

import './index.css'

const OnboardingSpotlight = ({ spotlightStyle }) => {
  return (
    <>
      <div className="onboardingSpotlight" style={spotlightStyle} />
    </>
  )
}
export default OnboardingSpotlight
