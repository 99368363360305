import React from 'react'
import { Typography } from '@mui/material'

import './index.css'

const ActivatedOfferTag = ({ customClassName = '' }) => {
  return (
    <div className={`activatedOfferTag ${customClassName}`}>
      <Typography variant="body2">Activated</Typography>
    </div>
  )
}

export default ActivatedOfferTag
