import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const PageInner = ({ customClassName = '', children }) => {
  return (
    <div
      className={`page-inner ${customClassName}`}
      style={{ backgroundImage: `url('/images/background.png')` }}
    >
      {children}
    </div>
  )
}

PageInner.propTypes = {
  customClassName: PropTypes.string,
}

export default PageInner
