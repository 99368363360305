import React, { useState } from 'react'

import './index.css'

import Video from '../../../../components/video'

const Media = ({ videoSrc, height, imageSrc, storageKey }) => {
  const [videoVisible, setVideoVisible] = useState(true)

  const handleError = () => setVideoVisible(false)

  return (
    <div className="gameDetailsMediaInner">
      <div className="gameDetailsMediaContent">
        {videoSrc && videoVisible ? (
          <Video
            height={height}
            src={videoSrc}
            handleError={handleError}
            imageSrc={imageSrc}
            storageKey={storageKey}
          />
        ) : (
          <div
            className="gameDetailsMediaImage"
            style={{
              height,
              backgroundImage: `url('${imageSrc}')`,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default Media
