import { Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'
import LoaderContext from '../context/LoaderContext'
import { getOrderById } from '../services/ordersService'
import { handleError } from '../utils/utils'

const OrderSuccess = () => {
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)
  const [order, setOrder] = useState()

  const { orderId } = useParams()

  useEffect(() => {
    showLoader()
    getOrderById(orderId)
      .then((data) => {
        setOrder(data)
      })
      .catch(handleError)
      .finally(hideLoader)
  }, [])

  const navigate = useNavigate()
  const handleClick = () => navigate('/rewards')

  return (
    <MessageLayout
      title="Order Complete"
      imageSrc={order?.giftCard?.image}
      button={{
        text: 'Done',
        handleClick,
      }}
      isLoading={isLoading}
    >
      <Typography variant="body2">
        Your order is being processed! We'll send you an email with details for your gift card in
        the next 24 hours.
      </Typography>
    </MessageLayout>
  )
}

export default OrderSuccess
