import { Dialog, DialogContent, Typography } from '@mui/material'
import React, { useRef } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import Slider from 'react-slick'
import useSwipe from '../../../../hooks/useSwipe'

import CopyLink from '../CopyLink'

import './index.css'

const ShareModal = ({
  showModal,
  hideModal,
  shareLink,
  handleCopy,
  copyDisabled,
  referralLink,
}) => {
  const sliderSettings = {
    className: 'slider variable-width referFriendShareModalSlider',
    infinite: false,
    centerPadding: 16,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }

  const SHARE_BUTTONS = [
    {
      Button: EmailShareButton,
      Icon: EmailIcon,
    },
    {
      Button: WhatsappShareButton,
      Icon: WhatsappIcon,
    },
    {
      Button: FacebookMessengerShareButton,
      Icon: FacebookMessengerIcon,
    },
    {
      Button: TelegramShareButton,
      Icon: TelegramIcon,
    },
    {
      Button: ViberShareButton,
      Icon: ViberIcon,
    },
    {
      Button: LinkedinShareButton,
      Icon: LinkedinIcon,
    },
    {
      Button: TwitterShareButton,
      Icon: TwitterIcon,
    },
  ]

  const wrapperRef = useRef(null)
  const positionRef = useRef(null)

  useSwipe(positionRef, wrapperRef)

  const handleCopyToClipboard = () => {
    handleCopy({ text: shareLink, notificationText: 'Share link copied' })
    hideModal()
  }

  return (
    <Dialog open={showModal} onClose={hideModal} maxWidth="sm" sx={styles.modal}>
      <div className="modalCloseButton">
        <img src="/images/modalCloseButton.png" onClick={hideModal} />
      </div>
      <DialogContent sx={styles.links}>
        <Typography variant="h2" sx={styles.title}>
          Share this link via
        </Typography>
        <div className="referFriendShareModalItems" ref={wrapperRef}>
          <Slider {...sliderSettings} ref={positionRef}>
            {SHARE_BUTTONS.map(({ Button, Icon }) => (
              <Button key={Button} onShareWindowClose={hideModal} url={shareLink}>
                <Icon size={40} round={true} />
              </Button>
            ))}
          </Slider>
        </div>
      </DialogContent>
      <DialogContent sx={styles.copy}>
        <Typography variant="h2" sx={styles.title}>
          Or copy link
        </Typography>
        <CopyLink
          handleCopy={handleCopyToClipboard}
          copyDisabled={copyDisabled}
          referralLink={referralLink}
        />
      </DialogContent>
    </Dialog>
  )
}

const styles = {
  modal: { '& .MuiDialog-paper': { borderRadius: '16px', margin: '21px' } },
  title: {
    fontWeight: 400,
  },
  links: {
    overflow: 'hidden',
    padding: '0 24px',
  },
  copy: {
    padding: '15px 24px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}

export default ShareModal
