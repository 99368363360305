import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageInner from '../components/layout/PageInner'
import LoaderContext from '../context/LoaderContext'
import { addSecondEmail } from '../services/userService'

const SecondEmailConnect = () => {
  const { dataId } = useParams()
  const { showLoader } = useContext(LoaderContext)

  const navigate = useNavigate()

  useEffect(() => {
    showLoader()
    addSecondEmail(dataId)
      .then(({ alert }) =>
        navigate(`/profile/settings${alert ? '?connectSuccess=connected_email' : ''}`)
      )
      .catch(() => navigate('/profile/settings?connectError=connected_email'))
  }, [])
  return <PageInner />
}

export default SecondEmailConnect
