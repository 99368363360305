import { Slide } from '@mui/material'
import { useContext } from 'react'
import HistoryContext from '../../../context/HistoryContext'

const SlideLayout = ({ prevRoutes, swipeIn = true, direction = 'left', children, isSwiping }) => {
  const { previousPage } = useContext(HistoryContext)

  if (prevRoutes.includes(previousPage) || isSwiping) {
    return (
      <Slide direction={direction} in={swipeIn} mountOnEnter unmountOnExit>
        <div>{children}</div>
      </Slide>
    )
  }

  return children
}

export default SlideLayout
