import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()

posthog.init(process.env.REACT_APP_POSTHOG_API_TOKEN, {
  api_host: 'https://us.posthog.com',
  capture_pageview: false,
})

root.render(
  <BrowserRouter>
    <PostHogProvider client={posthog}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <App />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </PostHogProvider>
  </BrowserRouter>
)

reportWebVitals()
