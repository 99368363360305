import React from 'react'
import { Typography } from '@mui/material'

import BottomButton from '../../../../components/common/BottomButton'

import './index.css'

const CopyLink = ({ referralLink, copyDisabled, handleCopy }) => {
  return (
    <div className="referFriendCopyLink">
      <Typography variant="h2" sx={styles.link}>
        {referralLink}
      </Typography>
      <BottomButton style={styles.copyButton} handleClick={handleCopy} disabled={copyDisabled}>
        Copy
      </BottomButton>
    </div>
  )
}

const styles = {
  link: {
    margin: 'auto 0',
    fontWeight: 400,
    overflow: 'hidden',
  },
  copyButton: {
    padding: '8px 20px',
    width: 'max-content',
    boxShadow: 'unset',
  },
}

export default CopyLink
