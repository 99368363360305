import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

//contexts
import TOSPromptConfigContext from 'context/TOSPropmpt/TOSPromptConfigContext'
//services
import { getTOSPromptConfig } from 'services/tosPromptService'
//types
import { TOSPromptConfigResponse } from 'types'

const useTOSPromptConfig = () => {
  const { TOSPromptConfig, setTOSPromptConfig } = useContext(TOSPromptConfigContext)

  const { data, refetch: queryRefetch } = useQuery<AxiosResponse<TOSPromptConfigResponse, Error>>(
    'TOSPromptConfig',
    getTOSPromptConfig,
    {
      enabled: !TOSPromptConfig,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (response) => {
        if (response?.data?.config) {
          setTOSPromptConfig(response.data.config)
        }
      },
    }
  )

  const refetch = async () => {
    const result = await queryRefetch()
    if (result?.data?.data?.config) {
      setTOSPromptConfig(result.data.data.config)
    }
  }

  return { config: TOSPromptConfig || data?.data.config, refetch }
}

export default useTOSPromptConfig
