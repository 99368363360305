import React from 'react'
import { Typography } from '@mui/material'

//components
import UnlockingRewardsModalContent from '../ModalContent/UnlockingRewards'
//styles
import './index.css'
//types
import { InstallToUnlockProps } from 'types'

/**
 * A component for displaying an information block for the user about activation of the offer
 * It is displayed for the user until the offer is activated
 */
const InstallToUnlock = ({ showModal }: InstallToUnlockProps) => {
  /**
   * A function for showing the 'Unlocking Rewards' modal
   */
  const handleShowUnlockingRewardsModal = () =>
    showModal({
      title: 'Unlocking Rewards',
      text: <UnlockingRewardsModalContent />,
      titleVariant: 'title',
      isCustomText: true,
      actionButtons: <></>,
      closeButton: true,
    })

  return (
    <div className="gameDetailsSpecialOfferInstallToUnlock">
      <img src="/images/lock.png" />
      <div className="gameDetailsSpecialOfferInstallToUnlockContent">
        <Typography variant="body2" sx={styles.text}>
          Installs need validation by our game partners before you can earn rewards. New users only.
        </Typography>
        <Typography variant="body2" sx={styles.learnMore} onClick={handleShowUnlockingRewardsModal}>
          Learn more...
        </Typography>
      </div>
    </div>
  )
}

const styles = {
  title: {
    fontFamily: 'var(--poppins-font)',
    padding: '0 20px',
  },
  text: {
    fontWeight: 400,
  },
  learnMore: {
    fontWeight: 700,
    textDecoration: 'underline',
  },
}

export default InstallToUnlock
