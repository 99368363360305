import { DialogContent, Typography } from '@mui/material'

import './index.css'

function IosAccessModalContent() {
  return (
    <DialogContent className="onboardingIosModal">
      <div className="onboardingIosModalContent">
        <img src="/images/complete.png" />
        <Typography variant="body2" sx={styles.text}>
          To activate this game offer and earn rewards,{' '}
          <strong>you must choose “Allow Tracking”</strong> when prompted within the game.
        </Typography>
      </div>
      <img src="/images/iosAllowTracking.png" className="onboardingIosModalImage" />
    </DialogContent>
  )
}

const styles = {
  text: {
    fontFamily: 'var(--poppins-font)',
  },
}

export default IosAccessModalContent
