import { axiosBackendInstance } from '../axios'

export const addEmailToWhitelist = async (form, onSuccess, onError, isSignUp = false) => {
  return axiosBackendInstance
    .post(`/api/v1/whitelist?isSignUp=${isSignUp}`, form)
    .then((data) => {
      if (!data) {
        onError()
        return
      }

      if (!data?.isGmail) {
        onSuccess()
        return
      }
      window.location.href = data.link
    })
    .catch(onError)
}
