import { Typography } from '@mui/material'

//assets
import Cart from '../../../../../../assets/offers/cart.png'
//others
import { formatNumber } from '../../../../../../utils/utils'
import { Info } from '../../../../../../utils/icons'
import OfferTask from '../../../../../../components/offerTask'
import SpendingModalContent from '../ModalContent/Spending'

import './index.css'

const OfferSpendingRewards = ({
  disabled,
  coins,
  piggies,
  eligibleSpendAmount,
  finalCoins,
  finalPiggies,
  showModal,
  active,
}) => {
  const formattedCoins = formatNumber(coins)
  const formattedPiggies = formatNumber(piggies)

  const handleShowModal = () =>
    showModal({
      title: 'Offer Spending Rewards',
      text: (
        <SpendingModalContent
          coins={formattedCoins}
          piggies={formattedPiggies}
          finalCoins={formatNumber(finalCoins)}
          finalPiggies={formatNumber(finalPiggies)}
        />
      ),
      titleVariant: 'title',
      isCustomText: true,
      actionButtons: <></>,
      closeButton: true,
    })

  return (
    <div className="gameDetailsSpecialOfferSpendingRewards">
      <Typography variant="h3" className="gameDetailsSpecialOfferSpendingRewardsHeader">
        <span>Spending Rewards</span> <Info onClick={handleShowModal} />
      </Typography>
      <Typography variant="h4" sx={styles.text}>
        Get up to <b>${(finalPiggies * 0.01).toFixed(2)} </b> with {piggies}% cash back!
      </Typography>
      <OfferTask
        icon={<img src={Cart} />}
        coins={finalCoins}
        piggies={finalPiggies}
        title={<>Spend up to {eligibleSpendAmount}</>}
        disabled={disabled}
        earn={true}
        active={active}
      />
    </div>
  )
}

const styles = {
  text: {
    fontWeight: 400,
    color: '#475467',
  },
}

export default OfferSpendingRewards
