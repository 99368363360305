import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Admin = () => {
  const navigate = useNavigate()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
      <Button variant="contained" color="primary" onClick={() => navigate('/admin/campaigns')}>
        Campaign Editor
      </Button>

      <Button variant="contained" color="primary" onClick={() => navigate('/admin/offer-tools')}>
        Offer Tools
      </Button>
    </Box>
  )
}

export default Admin
