import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { MMPTask, TaskType } from '@playback-rewards/shared-libraries'
import _ from 'lodash'

//components
import GenericPlaytimeMMPTask from './GenericPlaytimeMMPTask'
import GenericPlaytimeTask from './GenericPlaytimeTask'
import GenericMMPPurchaseTask from './GenericMMPPurchaseTask'
//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//types
import { GenericOfferProps } from 'types'
//utils
import {
  checkIfPlaytimeTaskCompleted,
  getActiveIndex,
  isExpiredGenericTask,
  isGenericTaskNotStarted,
} from 'utils/genericOffer'

const GenericOffer = ({
  title,
  offer,
  playtimeBatchInfo,
  isGenericPlaytimeMMPOffer,
  isOfferActivated,
  onButtonClick,
  onRefresh,
}: GenericOfferProps) => {
  const isInstalled = useMemo(() => {
    if (!isOfferActivated) {
      return false
    }
    if (offer && 'mmpAttributionRequiredForRewards' in offer) {
      return offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true
    }
    return true
  }, [offer])
  //Playtime
  const batchPlaytimeConfig = useMemo(
    () => offer?.batchPlaytimeConfig,
    [offer?.batchPlaytimeConfig]
  )
  const batches: number[] = useMemo(
    () => Array.from({ length: batchPlaytimeConfig?.batchCount || 0 }),
    [batchPlaytimeConfig?.batchCount]
  )
  const activeIndex = useMemo(
    () => getActiveIndex(batches, playtimeBatchInfo),
    [batches, playtimeBatchInfo]
  )
  const allPlaytimeTasksCompleted = useMemo(
    () =>
      isInstalled &&
      (activeIndex < 0 ||
        (isGenericPlaytimeMMPOffer &&
          activeIndex === batches.length - 1 &&
          checkIfPlaytimeTaskCompleted(batchPlaytimeConfig, playtimeBatchInfo, activeIndex))),
    [
      isInstalled,
      isGenericPlaytimeMMPOffer,
      batches,
      checkIfPlaytimeTaskCompleted,
      batchPlaytimeConfig,
      playtimeBatchInfo,
      activeIndex,
    ]
  )
  // MMP and Purchase tasks
  const mmpPurchaseTasks = useMemo(
    () =>
      _.chain(offer?.tasks as MMPTask[])
        .filter(
          (task) =>
            [TaskType.MMP_EVENT, TaskType.PURCHASE, TaskType.PURCHASE_AMOUNT].includes(
              task.taskType
            ) && (!isInstalled ? true : !task.completed)
        )
        .sortBy((task) => (isExpiredGenericTask(task) && !task.hideAfterExpire ? 1 : 0))
        .value(),
    [offer?.tasks, isInstalled]
  )

  const firstActionableGenericTaskIndex = useMemo(() => {
    if (!isInstalled) {
      return -1
    }
    return mmpPurchaseTasks.findIndex(
      (task) => !task.completed && !isGenericTaskNotStarted(task) && !isExpiredGenericTask(task)
    )
  }, [mmpPurchaseTasks, isInstalled])

  const sortedMMPPurchaseTasks = useMemo(() => {
    if (firstActionableGenericTaskIndex === -1) {
      return mmpPurchaseTasks
    }
    const mmpPurchaseTasksCopy = mmpPurchaseTasks.slice()
    const [firstActionableTask] = mmpPurchaseTasksCopy.splice(firstActionableGenericTaskIndex, 1)
    return [firstActionableTask, ...mmpPurchaseTasksCopy]
  }, [mmpPurchaseTasks, firstActionableGenericTaskIndex])

  if (allPlaytimeTasksCompleted && sortedMMPPurchaseTasks?.length <= 0) {
    return
  }

  return (
    <div className="gameDetailsSpecialOfferLoginRewards">
      <div className="gameDetailsSpecialOfferPlaytimeRewardsTitle">
        <Typography variant="h3" mb={1} mx={0.5}>
          {title}
        </Typography>
      </div>
      {/* Generic Playtime MMP Combined Tasks */}
      {isGenericPlaytimeMMPOffer ? (
        <GenericPlaytimeMMPTask
          isInstalled={isInstalled}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          batchPlaytimeConfig={batchPlaytimeConfig!}
          activeIndex={activeIndex}
          allPlaytimeTasksCompleted={allPlaytimeTasksCompleted}
          batches={batches}
          mmpEventTasks={mmpPurchaseTasks}
          playtimeBatchInfo={playtimeBatchInfo}
          onButtonClick={onButtonClick}
          onRefresh={onRefresh}
        />
      ) : (
        <>
          {/* Generic Playtime Tasks */}
          {batchPlaytimeConfig &&
            !allPlaytimeTasksCompleted &&
            batches.map((_, index) => {
              return (
                index >= activeIndex &&
                index <= activeIndex + 2 && (
                  <GenericPlaytimeTask
                    key={`playtime-${index}`}
                    index={index}
                    activeIndex={activeIndex}
                    batchPlaytimeConfig={batchPlaytimeConfig}
                    playtimeBatchInfo={playtimeBatchInfo}
                    onRefresh={onRefresh}
                  />
                )
              )
            })}
          {/* Generic MMP and Purchase Tasks */}
          {sortedMMPPurchaseTasks?.map((task, index) => {
            return (
              <GenericMMPPurchaseTask
                key={`mmpevent-${task.taskId}-${index}`}
                isInstalled={isInstalled}
                allMMPTasks={mmpPurchaseTasks}
                mmpTask={task}
                onRefresh={onRefresh}
                buttonText={index === 0 ? 'Play' : undefined}
                onButtonClick={() =>
                  onButtonClick(CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT, task.taskDescription)
                }
              />
            )
          })}
        </>
      )}
    </div>
  )
}

export default GenericOffer
